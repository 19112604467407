import React, { useState, useContext, useEffect, useRef } from "react";

import {
  SUMMARY_VARIABLES_RHINO,
  SUMMARY_VARIABLES_ROUTE,
} from "../common/constants";

import { RhinoQueryContext } from "../map/RhinoQueryContext";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import TableColumnSelector from "./TableColumnSelector";

import MaterialTable from "@material-table/core";

import axios from "axios";

import { API_ROOT } from "../common/constants";

import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";

import SaveAlt from "@material-ui/icons/SaveAlt";

import ExportFileNameDialog from "./ExportFileNameDialog";

// import EditIcon from "@material-ui/icons/EditLocation";
import DeleteIcon from "@material-ui/icons/Delete";
import TimelineIcon from "@material-ui/icons/Timeline";
import MapIcon from "@material-ui/icons/Map";

import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

HC_exporting(Highcharts);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

export default function MonitoredRoadsTable(props) {
  const classes = useStyles();
  const keyField = "label";
  const [query, dispatch] = useContext(RhinoQueryContext);
  const { year, monitoredRoads, monitoredRoadsData } = props;

  const getRoad = (roadLabel) => {
    return monitoredRoads.find((r) => r.id === roadLabel);
  };

  const isRoadSelected = (selectedRows, feature) => {
    return selectedRows.some(function(rowIndex) {
      const road = getRoad(rowIndex);

      return (
        (feature.ria_rte_id === road.ria_rte_id ||
          feature.rte_id === road.ria_rte_id) &&
        feature.frm_dfo >= road.frm_dfo &&
        feature.to_dfo <= road.to_dfo
      );
    });
  };

  function filterHPMSVariables(category) {
    let results = [];
    let SUMMARY_VARIABLES_layer = SUMMARY_VARIABLES_RHINO;

    for (var i = SUMMARY_VARIABLES_layer.length - 1; i >= 0; i--) {
      if (category === SUMMARY_VARIABLES_layer[i].category) {
        results.push(SUMMARY_VARIABLES_layer[i]);
      }
    }

    return results;
  }

  function buildCols() {
    let cols = [];
    let colVars = [];
    // let colVars = SUMMARY_VARIABLES_ROUTE;
    // for (var i = colVars.length - 1; i >= 0; i--) {
    //   cols.push(colVars[i]);
    // }

    colVars = filterHPMSVariables("route");
    for (var ii = colVars.length - 1; ii >= 0; ii--) {
      cols.push({
        id: colVars[ii].variable,
        numeric: true,
        disablePadding: false,
        label: colVars[ii].summaryName,
        tooltip: colVars[ii].tooltip,
        checked: true,
        category: colVars[ii].category,
        normalizedBy:
          typeof colVars[ii].normalizedBy !== "undefined"
            ? colVars[ii].normalizedBy
            : false,
      });
    }

    colVars = filterHPMSVariables("all");
    for (var i = colVars.length - 1; i >= 0; i--) {
      cols.push({
        id: colVars[i].variable,
        numeric: true,
        disablePadding: false,
        label: colVars[i].summaryName,
        tooltip: colVars[i].tooltip,
        checked: false,
        category: colVars[i].category,
      });
    }

    colVars = filterHPMSVariables("truck");
    for (var i = colVars.length - 1; i >= 0; i--) {
      cols.push({
        id: colVars[i].variable,
        numeric: true,
        disablePadding: false,
        label: colVars[i].summaryName,
        tooltip: colVars[i].tooltip,
        checked: true,
        category: colVars[i].category,
      });
    }

    return cols;
  }
  let columns = buildCols();

  function buildColsNew(type) {
    let cols = [];
    let columnsTemp = columns;

    if (type === "detailed_panel") {
      columnsTemp = columnsTemp.filter(
        (headCell) => headCell.id !== "ria_rte_id" //(headCell) => headCell.id !== "ria_rte_id"
      );
    }
    SUMMARY_VARIABLES_ROUTE.map((obj) => {
      cols.push({
        title: obj.label,
        field: obj.id,
        cellStyle: {
          borderTopWidth: 0,
          borderBottomWidth: 1,
          borderLeftWidth: 0,
          borderRightWidth: 2,
          borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
          borderStyle: "solid",
        },
        headerStyle: {
          borderTopWidth: 0,
          borderBottomWidth: 1,
          borderLeftWidth: 0,
          borderRightWidth: 2,
          borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
          borderStyle: "solid",
        },
        tooltip: obj.tooltip,
        tooltipStyle: {
          fontSize: 12,
        },
        render: (rowData) =>
          rowData[obj.id] !== null &&
          typeof rowData[obj.id] !== "undefined" &&
          rowData[obj.id] !== "undefined"
            ? rowData[obj.id].toLocaleString("en-US", obj.formatOptions)
            : "",
      });
    });
    columnsTemp.map(
      (headCell) =>
        query.tableColumns[headCell.id] &&
        cols.push({
          title: headCell.label,
          field: headCell.id,
          cellStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          headerStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          tooltip: headCell.tooltip,
          tooltipStyle: {
            fontSize: 12,
          },
          render: (rowData) =>
            rowData[headCell.id] !== null &&
            typeof rowData[headCell.id] !== "undefined" &&
            rowData[headCell.id] !== "undefined"
              ? rowData[headCell.id].toLocaleString(
                  "en-US",
                  headCell.formatOptions
                )
              : "",
        })
    );

    return cols;
  }

  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [csvFileNameTest, setCsvFileNameTest] = useState("myTable");
  const [exportCols, setExportCols] = useState();
  const [exportData, setExportData] = useState();

  const tableRef = useRef();

  const [RhinochartOptions, setRhinoChartOptions] = useState({
    title: {
      text: "",
    },
    chart: {
      type: "line",
      margin: [60, 240, 70, 70],
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      x: 15,
      y: 30,
    },
    xAxis: {
      categories: ["2017", "2018", "2019", "2020", "2021", "2022", "2023"],
      title: {
        text:
          "Congestion Reporting Year (data is for prior year)" +
          "<br />" +
          "2021 reporting year (2020 data) was impacted by the COVID-19 pandemic and is not reflective of a typical year.",
      },
    },
    credits: {
      enabled: false,
    },
  });

  function setHeadCellsTest() {
    let SUMMARY_VARIABLES_layer = SUMMARY_VARIABLES_RHINO;
    SUMMARY_VARIABLES_layer.map(
      (svar) =>
        // colsTemp.push(
        (svar.status =
          query.tableColumns[svar.variable] === true ? "keep" : "drop")
      // )
    );
    let colsTemp = SUMMARY_VARIABLES_layer.filter(
      (svar) => svar.status === "keep"
    );
    return colsTemp;
  }

  const [selectedRows, setSelectedRows] = useState([]);
  const [summary, setSummary] = useState([]);

  const fetchRoadMetrics = async (year, id, ria_rte_id, frm_dfo, to_dfo) => {
    try {
      const dataUrl = `${API_ROOT}/metrics/findByRouteIdYear?year=${year}&route_id=${ria_rte_id}&min_dfo=${frm_dfo}&max_dfo=${to_dfo}`;

      const response = await axios.get(dataUrl);
      if (response.status === 200) {
        return {
          year,
          response,
        };
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  async function getRoadSummary(data) {
    let years = [2017, 2018, 2019, 2020, 2021, 2022, 2023];
    let temp = {};
    for (var i = 0; i < years.length; i++) {
      setIsLoading({
        current: i + 1,
        total: years.length,
        progress: ((i + 1) / years.length) * 100,
      });

      let responses = await fetchRoadMetrics(
        years[i],
        data.id,
        data.ria_rte_id,
        data.frm_dfo,
        data.to_dfo
      );

      let response = responses.response;
      if (response) {
        let label = 1;
        let id = data.id;
        let fields = SUMMARY_VARIABLES_RHINO.map((v) => {
          return v.variable;
        });

        temp[keyField] = label;
        temp.id = data.id;
        if (responses.year === year) {
          for (var j = 0; j < fields.length; j++) {
            temp[fields[j]] = response.data[fields[j]];
          }
        } else {
          for (var j = 0; j < fields.length; j++) {
            temp[fields[j].concat(responses.year)] = response.data[fields[j]];
          }
        }
        ++label;
      }
    }
    setIsLoading({
      current: 0,
      total: years.length,
      progress: 0,
    });

    return temp;
  }

  const [id, setId] = useState({ id: -1 });
  useEffect(() => {
    if (id.id !== -1) {
      const fetchData = async () => {
        const result = await getRoadSummary(id);

        let summaryTemp = summary;
        summaryTemp.push(result);
        setSummary(summaryTemp);
      };

      fetchData();
    }
  }, [id]);

  const materialTableDetailPanel = (data) => {
    let row = {};

    row = summary.filter((sline) => sline.id === data.id);
    let headCells = setHeadCellsTest();

    // catch for data not ready for chart
    try {
      headCells.map((v) => {
        return {
          name: v.summaryName,
          data: [
            row[0][v.variable + (year === 2017 ? "" : "2017")],
            row[0][v.variable + (year === 2018 ? "" : "2018")],
            row[0][v.variable + (year === 2019 ? "" : "2019")],
            row[0][v.variable + (year === 2020 ? "" : "2020")],
            row[0][v.variable + (year === 2021 ? "" : "2021")],
            row[0][v.variable + (year === 2022 ? "" : "2022")],
            row[0][v.variable + (year === 2023 ? "" : "2023")],
          ],
        };
      });
    } catch (e) {
      console.log(e);
      return false;
    }

    let chartOptions = Object.assign({}, RhinochartOptions, {
      series: headCells.map((v) => {
        return {
          name: v.summaryName,
          data: [
            row[0][v.variable + (year === 2017 ? "" : "2017")],
            row[0][v.variable + (year === 2018 ? "" : "2018")],
            row[0][v.variable + (year === 2019 ? "" : "2019")],
            row[0][v.variable + (year === 2020 ? "" : "2020")],
            row[0][v.variable + (year === 2021 ? "" : "2021")],
            row[0][v.variable + (year === 2022 ? "" : "2022")],
            row[0][v.variable + (year === 2023 ? "" : "2023")],
          ],
        };
      }),
      title: {
        text: data.ria_rte_id,
      },
      subtitle: {
        text: data.frm_dfo + " to " + data.to_dfo,
      },
      legend: {
        enabled: true,
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
        itemMarginTop: 2,
        itemMarginBottom: 2,
        symbolWidth: 40,
        itemStyle: {
          fontSize: "10px",
        },
      },
      exporting: {
        sourceWidth: 1000,
        sourceHeight: 400,
        scale: 1,
      },
    });

    return (
      <div
        style={{
          fontSize: 14,
          textAlign: "center",
          color: "white",
          backgroundColor: "#FFFFFF",
          marginLeft: 10,
          marginBottom: 10,
          marginTop: 0,
          borderTopWidth: 0,
        }}
      >
        <HighchartsReact
          containerProps={{
            style: {
              width: "90%",
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    );
  };

  function updateSelectedRows(rows) {
    return dispatch({
      type: "setHighlightQuery",
      query: isRoadSelected.bind(null, rows),
    });
  }

  useEffect(() => {
    updateSelectedRows(selectedRows);
  }, [selectedRows]);

  const handleClick = (data) => {
    setSelectedRows(data);

    return;
  };

  function setMaterialTableData() {
    // if (tableRef.current && monitoredRoadsData.length) {
    if (selectedRows.length > 0) {
      const { detailPanel } = tableRef.current.props;

      return monitoredRoadsData.map((row) =>
        selectedRows.find((selected) => selected.id === row.id)
          ? {
              ...row,
              tableData: {
                checked: true,
                // showDetailPanel:
                //   typeof detailPanel === "function"
                //     ? detailPanel
                //     : detailPanel[0].render,
                // showDetailPanel: detailPanel[0].render,
              },
            }
          : row
      );
    } else return monitoredRoadsData;
  }

  const [isLoading, setIsLoading] = useState({
    current: 0,
    total: 0,
    progress: 0,
  });

  function setMonitoredRoadSegments(road) {
    let roadSegments = query.features
      .filter(
        (obj) =>
          obj.ria_rte_id === road.ria_rte_id &&
          obj.frm_dfo >= road.frm_dfo &&
          obj.to_dfo <= road.to_dfo
      )
      .sort((a, b) => (a.frm_dfo > b.frm_dfo ? 1 : -1));
    return roadSegments;
  }

  return (
    <div className={classes.root}>
      {isLoading.current > 0 && (
        <Box
          sx={{
            width: "90%",
            // marginTop: "43vh",
            marginLeft: "5%",
          }}
          className={classes.boxBackground}
        >
          Loading:
          <LinearProgress variant="determinate" value={isLoading.progress} />
        </Box>
      )}

      <Paper className={classes.paper + " table-null"}>
        <Typography variant="subtitle1">
          <strong>Note:</strong> 2021 reporting year (2020 data) was impacted by
          the COVID-19 pandemic and is not reflective of a typical year.
        </Typography>
      </Paper>

      {monitoredRoadsData.length > 0 && (
        <Paper className={classes.paper + " table-wrap " + "monitored-roads"}>
          <MaterialTable
            tableRef={tableRef}
            title=""
            columns={buildColsNew("full_table")}
            data={monitoredRoadsData}
            localization={{
              header: {
                actions: "",
              },
            }}
            options={{
              exportButton: true,
              // selection: true,
              exportAllData: true,
              exportMenu: [
                {
                  label: "Export Table",
                  exportFunc: (cols, data) => {
                    setOpenExportDialog(true);
                    setExportCols(cols);
                    setExportData(data);
                  },
                },
              ],
              // toolbarButtonAlignment:
              //   selectedRows.length > 0 ? "left" : "right", // here is the option to change toolbar buttons' alignment
            }}
            icons={{
              Export: () => (
                <SaveAlt fontSize="medium" className={classes.exportButton} />
              ),
              Timeline: () => <TimelineIcon style={{ color: "orange" }} />,
              Delete: () => <DeleteIcon style={{ color: "red" }} />,
            }}
            actions={[
              {
                tooltip: "",
                icon: () => {
                  return (
                    <div>
                      <TableColumnSelector columns={columns} />
                    </div>
                  );
                },
                onClick: () => {},
                isFreeAction: true,
              },
              (rowData) => {
                let index = selectedRows.indexOf(rowData.id);

                return {
                  icon:
                    index !== -1
                      ? () => (
                          <div style={{ lineHeight: 0 }}>
                            <p style={{ fontSize: 12 }}>Hide</p>
                            <MapIcon style={{ color: "green" }} />
                          </div>
                        )
                      : () => (
                          <div style={{ lineHeight: 0 }}>
                            <p style={{ fontSize: 12 }}>Show</p>
                            <MapIcon style={{ color: "red" }} />
                          </div>
                        ),
                  tooltip: index !== -1 ? "Hide Map" : "Show Map",
                  onClick: (event, rowData) => {
                    let index = selectedRows.indexOf(rowData.id);

                    if (index === -1) {
                      setSelectedRows([...selectedRows, rowData.id]);
                    } else {
                      let tempSelectedRows = selectedRows.filter(
                        (item) => item !== rowData.id
                      );
                      setSelectedRows(tempSelectedRows);
                    }
                  },
                };
              },
            ]}
            onSelectionChange={(rows) => {
              // console.log("rows-check", rows);
              handleClick(rows);
              // rows.map((rowData) =>
              //   tableRef.current.onToggleDetailPanel(
              //     [
              //       tableRef.current.dataManager.sortedData.findIndex(
              //         (item) => item.id === rowData.id
              //       ),
              //     ],
              //     tableRef.current.props.detailPanel[0].render
              //   )
              // );
            }}
            onRowClick={(event, rowData) => {
              // console.log("rowData-Check", rowData);
              // tableRef.current.onToggleDetailPanel(
              //   [
              //     tableRef.current.dataManager.sortedData.findIndex(
              //       (item) => item.id === rowData.id
              //     ),
              //   ],
              //   tableRef.current.props.detailPanel[0].render
              // );
            }}
            detailPanel={[
              {
                icon: "info",
                tooltip: "Show Segments",
                render: (rowLine) => {
                  let rowData = rowLine.rowData;
                  return (
                    <div
                      style={{
                        // fontSize: 100,
                        // textAlign: "center",
                        // color: "white",
                        backgroundColor: "#E53935",
                        marginLeft: 100,
                      }}
                    >
                      <MaterialTable
                        title="Road Segments"
                        columns={buildColsNew("full_table")}
                        data={setMonitoredRoadSegments(rowData)}
                        options={{
                          exportButton: true,
                          // selection: true,
                          exportAllData: true,
                          exportMenu: [
                            {
                              label: "Export Table",
                              exportFunc: (cols, data) => {
                                setOpenExportDialog(true);
                                setExportCols(cols);
                                setExportData(data);
                              },
                            },
                          ],
                        }}
                        icons={{
                          Export: () => (
                            <SaveAlt
                              fontSize="medium"
                              className={classes.exportButton}
                            />
                          ),
                        }}
                      />
                    </div>
                  );
                },
              },

              (rowData) => ({
                icon:
                  rowData.id === id.id ||
                  summary.filter((sline) => sline.id === rowData.id).length !==
                    0
                    ? () => (
                        <div style={{ lineHeight: 0 }}>
                          <p style={{ fontSize: 12 }}>Show</p>
                          <TimelineIcon style={{ color: "green" }} />
                        </div>
                      )
                    : () => (
                        <div style={{ lineHeight: 0 }}>
                          <p style={{ fontSize: 12 }}>Load</p>
                          <TimelineIcon style={{ color: "red" }} />
                        </div>
                      ),

                tooltip:
                  rowData.id === id.id ||
                  summary.filter((sline) => sline.id === rowData.id).length !==
                    0
                    ? "Show Timeline"
                    : "Load TImeline",
                render: (rowLine) => {
                  let rowData = rowLine.rowData;
                  if (
                    rowData.id !== id.id &&
                    summary.filter((sline) => sline.id === rowData.id)
                      .length === 0
                  ) {
                    // setSelectedRows(rowData);
                    setId(rowData);
                    return;
                  }
                  return (
                    <div
                      style={{
                        fontSize: 100,
                        textAlign: "center",
                        color: "white",
                        backgroundColor: "#43A047",
                      }}
                    >
                      {/* <h1>THIS IS A TEST</h1> */}
                      {materialTableDetailPanel(rowData)}
                    </div>
                  );
                },
              }),
            ]}
          />
          <ExportFileNameDialog
            openExportDialog={openExportDialog}
            setOpenExportDialog={setOpenExportDialog}
            csvFileNameTest={csvFileNameTest}
            setCsvFileNameTest={setCsvFileNameTest}
            exportCols={exportCols}
            exportData={exportData}
          />
        </Paper>
      )}
      {monitoredRoadsData.length === 0 && (
        <Paper className={classes.paper + " table-null"}>
          <Typography variant="subtitle1">
            Please select a segment or custom corridor using the map above.
          </Typography>
        </Paper>
      )}
    </div>
  );
}
