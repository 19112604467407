/** see https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react*/
import React, { createContext, useReducer } from "react";
import {
  geographic_options_COUNTY,
  geographic_options_TXDOTdist,
  geographic_options_MPO,
} from "../common/constants";

const initialState = {
  popUpEnabled: true,
  highlightQuery: function(feature) {
    return false;
  },
  userSelection: { ria_rte_id: "", frm_dfo: Number.NaN, to_dfo: Number.NaN },
  userSelectionTX100: {
    top100id: "",
    from_road: String.NaN,
    to_road: "",
    road: "",
  },
  features: [],
  utpMapLayersVisibility: {
    underwayorsoon: false,
    within4years: false,
    fivetotenyears: false,
    tenplusyears: false,
  },
  utpMapLayersCount: {
    underwayorsoon: 0,
    within4years: 0,
    fivetotenyears: 0,
    tenplusyears: 0,
  },
  roadSegmentCategoryIndex: 0,
  roadSegmentCategory: "none",
  selectedBaselayer: "Streets",
  yearSelection: 2023,
  contextLayersVisibility: {
    countyBoundaries: false,
    districtBoundaries: false,
    odessaDistSandMines: false,
    disposalWells: false,
    commercialBorderCrossings: false,
    nonCommercialBorderCrossings: false,
    railBorderCrossings: false,
    portsLayer: false,
    cargoAirports: false,
    airports: false,
    truckParkingLayer: false,
  },
  contextLayersNumbFeatures: {
    odessaDistSandMines: 0,
    disposalWells: 0,
    commercialBorderCrossings: 0,
    nonCommercialBorderCrossings: 0,
    railBorderCrossings: 0,
    portsLayer: 0,
    cargoAirports: 0,
    airports: 0,
    truckParkingLayer: 0,
  },
  tableColumns: {},
  hsysFilters: [
    { label: "Interstate", id: "IH", checked: true },
    { label: "US Highway", id: "US", checked: true },
    { label: "US Spur", id: "UP", checked: true },
    { label: "State Highway", id: "SH", checked: true },
    { label: "State Alt", id: "SA", checked: true },
    { label: "State Loop", id: "SL", checked: true },
    { label: "State Spur", id: "SS", checked: true },
    { label: "Business IH", id: "BI", checked: true },
    { label: "Business US", id: "BU", checked: true },
    { label: "Business State", id: "BS", checked: true },
    { label: "Principal Arterial", id: "PA", checked: true },
    { label: "Ranch to Mkt", id: "RM", checked: true },
    { label: "Ranch Road, ", id: "RR", checked: true },
    { label: "Park Road", id: "PR", checked: true },
    { label: "Rec Road", id: "RE", checked: true },
    { label: "Rec Rd Spur", id: "RP", checked: true },
    { label: "FM Spur", id: "FS", checked: true },
    { label: "RM Spur", id: "RS", checked: true },
    { label: "RR Spur", id: "RU", checked: true },
    { label: "Business FM", id: "BF", checked: true },
    { label: "Farm to Mkt", id: "FM", checked: true },
    { label: "Off-System: Tollroad", id: "TL", checked: true },
    { label: "Off-System: County Road", id: "CR", checked: true },
    { label: "Off-System: Func. Classified St", id: "FC", checked: true },
    { label: "Off-System: Local Street", id: "LS", checked: true },
    { label: "Federal Road", id: "FD", checked: true },
    { label: "US Alternate", id: "UA", checked: true },
  ],
  ruralUrbanFilters: [
    { label: "Unknown", id: "0", checked: true },
    { label: "Rural (<5000)", id: "1", checked: true },
    { label: "Small Urban (5000-49,999)", id: "2", checked: true },
    { label: "Large Urban (50,000-199,999)", id: "3", checked: true },
    { label: "Urbanized (200,000+)", id: "4", checked: true },
  ],

  functionalClassFilters: [
    { label: "Interstate", id: "1", checked: true },
    { label: "Other Freeway and Expressway", id: "2", checked: true },
    { label: "Other Principal Arterial", id: "3", checked: true },
    { label: "Minor Arterial", id: "4", checked: true },
    { label: "Major Collector", id: "5", checked: true },
    { label: "Minor Collector", id: "6", checked: true },
    { label: "Local", id: "7", checked: true },
  ],
  //geographic_options_COUNTY
  countyFilters: geographic_options_COUNTY.map((item) => {
    const updatedItem = {
      ...item,
      label: item.name,
      checked: true,
    };
    return updatedItem;
  }),
  districtFilters: geographic_options_TXDOTdist.map((item) => {
    const updatedItem = {
      ...item,
      label: item.text,
      id: item.mapped,
      checked: true,
    };
    return updatedItem;
  }),
  mpoFilters: geographic_options_MPO.map((item) => {
    const updatedItem = {
      ...item,
      label: item.name,
      checked: true,
    };
    return updatedItem;
  }),
  nhsFilters: [
    { label: "Not on NHS", id: "0", checked: true },
    { label: "Non-connector NHS", id: "1", checked: true },
    { label: "Major Airport", id: "2", checked: true },
    { label: "Major Port Facility", id: "3", checked: true },
    { label: "Major Amtrak Station", id: "4", checked: true },
    { label: "Major Rail/Truck Terminal", id: "5", checked: true },
    { label: "Major Inner City Bus Terminal", id: "6", checked: true },
    {
      label: "Major Public Transportation or Multi-Modal Passenger Terminal",
      id: "7",
      checked: true,
    },
    { label: "Major Pipeline Terminal", id: "8", checked: true },
    { label: "Major Ferry Terminal", id: "9", checked: true },
  ],

  estimatedFilters: [
    { label: "Yes", id: "YES", checked: true },
    { label: "No", id: "NO", checked: true },
  ],
  frghtNtwkTxFilters: [
    { label: "Texas Freight Network", id: 1, checked: true },
    { label: "Not on Texas Freight Network", id: 0, checked: true },
  ],
  frghtNtwkNationFilters: [
    { label: "National Freight Network", id: 1, checked: true },
    { label: "Not on National Freight Network", id: 0, checked: true },
  ],
  TrkTop100ODSelLnkMap: {
    odVisibility: false,
    showLayer: true,
    trank: 0,
    title: "none",
  },
};
// function handleToggleComplete(id) {
//   const newList = list.map((item) => {
//     if (item.id === id) {
//       const updatedItem = {
//         ...item,
//         isComplete: !item.isComplete,
//       };

//       return updatedItem;
//     }

//     return item;
//   });

//   setList(newList);
// }

const RhinoQueryContext = createContext(null);

const queryReducer = (state, action) => {
  switch (action.type) {
    case "enablePopUp":
      return {
        ...state,
        popUpEnabled: true,
      };
    case "disablePopUp":
      return {
        ...state,
        popUpEnabled: false,
      };
    case "setHighlightQuery":
      return {
        ...state,
        highlightQuery: action.query,
      };
    case "setUserSelection":
      return {
        ...state,
        userSelection: action.selection,
      };
    case "setUserSelectionTX100":
      return {
        ...state,
        userSelectionTX100: action.selection,
      };
    case "setFeatures":
      return { ...state, features: action.payload.map((x) => x.properties) };
    case "setutpMapLayersVisibility":
      return {
        ...state,
        utpMapLayersVisibility: action.selection,
      };
    case "setutpMapLayersCount":
      return {
        ...state,
        utpMapLayersCount: action.selection,
      };
    case "setroadSegmentCategoryIndex":
      return {
        ...state,
        roadSegmentCategoryIndex: action.selection,
      };
    case "setroadSegmentCategory":
      return {
        ...state,
        roadSegmentCategory: action.selection,
      };
    case "setselectedBaselayer":
      return {
        ...state,
        selectedBaselayer: action.selection,
      };
    case "setYearSelection":
      return {
        ...state,
        yearSelection: action.selection,
      };
    case "setContextLayersVisibility":
      return {
        ...state,
        contextLayersVisibility: action.selection,
      };
    //  contextLayersNumbFeatures: { odessaDistSandMines: 0, disposalWells: 0 },
    case "setContextLayersNumbFeatures":
      return {
        ...state,
        contextLayersNumbFeatures: action.selection,
      };
    case "setTableColumns":
      return {
        ...state,
        tableColumns: action.selection,
      };
    case "setTrkTop100ODSelLnkMap":
      return {
        ...state,
        TrkTop100ODSelLnkMap: action.selection,
      };

    default:
      throw new Error();
  }
};

const RhinoQueryProvider = (props) => {
  const [state, dispatch] = useReducer(queryReducer, initialState);
  return (
    <RhinoQueryContext.Provider value={[state, dispatch]}>
      {props.children}
    </RhinoQueryContext.Provider>
  );
};

export { RhinoQueryContext, RhinoQueryProvider };
