/**
 * @fileOverview the main UI component
 */

import React, { useMemo, useState, useEffect } from "react";

import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
// import Link from "@material-ui/core/Link";

import { makeTheme, sidebarStyles } from "./ui/styles";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./App.scss";

// import { QueryProvider } from "./map/QueryContext";
// import LeafletMap from "./map/LeafletMap.js";
// import DataTabs from "./table/DataTabs.js";

import {
  geographic_options_TXDOTdist,
  geographic_options_REGIONS,
  geographic_options_COUNTY,
  geographic_options_MPO,
} from "./common/constants";

import Sidebar from "./ui/Sidebar-mui";

import { RhinoQueryProvider } from "./map/RhinoQueryContext";

import TX100LeafletMap from "./map/TX100LeafletMap.js";
import RhinoLeafletMap from "./map/RhinoLeafletMap.js";

import DeckglMap from "./map/DeckglMap.js";

import Grid from "@material-ui/core/Grid";

// import RhinoQueryTabs from "./map/RhinoQueryTabs";
// import Tab from "react-bootstrap/Tabs";

// import RoadSegmentTable from "./table/RoadSegmentTable.js";
// import TX100RoadSegmentTable from "./table/TX100RoadSegmentTable.js";

// import OtherMapLayers from "./map/OtherMapLayers.js";

// import TableWrapper from "./components/table-wrapper";
//import TableControl from "./components/table-control";
// import TableSummary from "./components/table-summary";

// import CustomSummary from "./table/CustomSummary.js";

import DataTabs from "./table/DataTabs.js";
import DataTabsTx100 from "./table/DataTabsTx100";

import { makeStyles } from "@material-ui/core/styles";

import axios from "axios";
import { API_ROOT } from "./common/constants";

import { fetchRoadData, fetchMonitoredRoads } from "./data-loaders/DataLoaders";

import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import LinearProgress from "@mui/material/LinearProgress";

import normalizeRoadSegments from "./utils/normalizeRoadSegments";
import {
  // SUMMARY_VARIABLES_RHINO,
  databaseVariablesHPMS,
  // SUMMARY_VARIABLES_TX100,
  // SUMMARY_VARIABLES_ROUTE_100,
  // SUMMARY_VARIABLES_ROUTE,
  // tx100_plot_vars,
} from "./common/constants";

import TrkTop100ODSelLnkMap from "./map/TrkTop100ODSelLnkMap";
import Utils from "./common/utils.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo1: {
    // padding: theme.spacing(2),
    // textAlign: "left",
    // color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "1.5px",
  },
  logo2: {
    //padding: theme.spacing(2),
    // textAlign: "right",
    // color: theme.palette.text.secondary,
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  title: {
    // padding: theme.spacing(2),
    // textAlign: "left",
    // color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  linearProgress: {
    marginTop: "5px",
  },
}));

function App() {
  // UI Settings
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const mode = prefersDarkMode ? "dark" : "light";
  let theme = makeTheme(mode);
  theme = responsiveFontSizes(theme);
  const classes = sidebarStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const classes2 = useStyles();

  const [statsLayerParam, setStatsLayer] = useState("Top 100 Bottlenecks");

  // var statsLayerDesc;
  // if (statsLayerParam === "Top 100 Bottlenecks") {
  //   statsLayerDesc = "Maryland Top 100 Bottlenecks";
  // } else if (statsLayerParam === "Statewide") {
  //   statsLayerDesc = "Statewide";
  // } else if (statsLayerParam === "DC Metro Area") {
  //   statsLayerDesc = "DC Metro Area";
  // } else if (statsLayerParam === "Baltimore Area") {
  //   statsLayerDesc = "Baltimore Area";
  // } else if (statsLayerParam === "Western") {
  //   statsLayerDesc = "Western MD";
  // } else if (statsLayerParam === "Central") {
  //   statsLayerDesc = "Central MD";
  // } else if (statsLayerParam === "Southern") {
  //   statsLayerDesc = "Southern MD";
  // } else if (statsLayerParam === "Eastern Shore") {
  //   statsLayerDesc = "Eastern Shore";
  // } else {
  //   statsLayerDesc = statsLayerParam;
  // }

  /* Year of the data for map and summary table */
  const [year, setYear] = useState(2023);

  /* Set route for GeoJSON */
  // const [geoRoute, setGeoRoute] = useState("/roads/tx100=monitored/year=2019");
  const [geoRoute, setGeoRoute] = useState(
    "/roads_tx100/findByTrank?min=0&max=100"
  );

  /* Set data table name, selection for road segment lookup */
  const [segmentsTable, setSegmentsTable] = useState("roads");
  const [segmentSelection, setSegmentSelection] = useState("all");

  /* Region boundary geometry in Leaflet's (latitude,longitude) coordinate system */
  // const [bounds] = useState([
  //   [37.99093286, -79.48710834],
  //   [39.72301501, -75.05239796],
  // ]);

  // function RoadSegmentTableSelect(props) {
  //   const layerID = props.layerID;
  //   if (props.layerID === "Statewide") {
  //     return <RoadSegmentTable year={year} layerID={statsLayerParam} />;
  //   }
  // }

  // function MapLayerSelect() {
  //   return (
  //     <LeafletMap
  //       mode={mode}
  //       bounds={bounds}
  //       year={year}
  //       region={region}
  //       county={county}
  //       setYear={setYear}
  //       layerID={statsLayerParam}
  //       geoRoute={geoRoute}
  //       segmentsTable={segmentsTable}
  //       segmentSelection={segmentSelection}
  //       dataRoute="hpms/data"
  //       Zoom={8}
  //       position={[39.045753, -76.641273]}
  //     />
  //   );
  // }

  // const drawer = (
  //   <div className="drawer-wrapper">
  //     <div className={classes.toolbar} />
  //     <Divider />
  //     <div className="current">
  //       <Typography variant="subtitle1">
  //         Current Layer: {statsLayerDesc}
  //       </Typography>
  //     </div>
  //     <Divider />
  //     <List>
  //       {geographic_options.map((item, index) => (
  //         <Link
  //           className={item.active ? "" : "link-disabled"}
  //           key={item.text}
  //           underline="none"
  //           color="inherit"
  //           href="#"
  //           onClick={(e) => {
  //             e.preventDefault();
  //             if (item.active) {
  //               setStatsLayer(item.text);
  //               setRegion(item.text.replace(/\s+/g, "-").toLowerCase());
  //               setGeoRoute(item.geoRoute);
  //               setSegmentsTable(item.segmentsTable);
  //               setSegmentSelection(item.segmentSelection);
  //             }
  //           }}
  //         >
  //           <ListItem button>
  //             <ListItemIcon>
  //               <Icon>{item.icon}</Icon>
  //             </ListItemIcon>
  //             <ListItemText primary={item.text} />
  //           </ListItem>
  //         </Link>
  //       ))}
  //     </List>
  //     <img
  //       className="logo"
  //       src={`logo-${mode}.svg`}
  //       alt="Maryland DOT Logo"
  //       title="Maryland DOT Logo"
  //     />
  //   </div>
  // );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const [statusGuide, setStatusGuide] = useState([
    "TX100",
    "Top100 Truck Segments",
  ]);

  // function MapLayerSelect(props) {
  //   //const layerID = props.layerID;
  //   const { statusGuide } = props;
  //   if (mapType === "deckGL") {
  //     return (
  //       <DeckglMap
  //         year={year}
  //         layerID={statsLayerParam}
  //         //district={districtParam}
  //         route={geoRoute}
  //         //minZoom={6}
  //         //Zoom={6}
  //         // utprawdata={utprawdata}
  //         //position={[31.243333, -97.839333]}
  //         statusGuide={statusGuide}
  //         mode={mode}
  //         mapType={"deckGL"}
  //         deckglMapType={deckglMapType}
  //         setDeckglMapType={setDeckglMapType}
  //         roadGeomFeatures={roadGeomFeatures}
  //         setRoadGeomFeatures={setRoadGeomFeatures}
  //       />
  //     );
  //   }

  //   if (statusGuide[0] !== "TX100") {
  //     //if (layerID === "statewide") {
  //     return (
  //       <RhinoLeafletMap
  //         // regionId={regionId}
  //         //bounds={bounds}
  //         //counties={counties}
  //         //quads={quads}
  //         year={year}
  //         setYear={setYear}
  //         layerID={statsLayerParam}
  //         //district={districtParam}
  //         route={geoRoute}
  //         //minZoom={6}
  //         //Zoom={6}
  //         // utprawdata={utprawdata}
  //         //position={[31.243333, -97.839333]}
  //         statusGuide={statusGuide}
  //         mode={mode}
  //         mapType={"leaflet"}
  //         roadGeomFeatures={roadGeomFeatures}
  //         setRoadGeomFeatures={setRoadGeomFeatures}
  //       />
  //     );
  //   } else {
  //     return (
  //       <TX100LeafletMap
  //         // regionId={regionId}
  //         //bounds={bounds}
  //         //counties={counties}
  //         //quads={quads}
  //         year={year}
  //         layerID={statsLayerParam}
  //         //district={districtParam}
  //         route={geoRoute}
  //         //minZoom={6}
  //         //Zoom={6}
  //         // utprawdata={utprawdata}
  //         //position={[31.243333, -97.839333]}
  //         statusGuide={statusGuide}
  //         mode={mode}
  //         mapType={"leaflet"}
  //         roadGeomFeatures={roadGeomFeatures}
  //         setRoadGeomFeatures={setRoadGeomFeatures}
  //       />
  //     );
  //   }
  // }

  // function renderCustomSummary(props) {
  //   //const renderCustomSummary = () => {
  //   if (statusGuide[0] !== "TX100") {
  //     //if (statsLayerParam === "statewide") {
  //     return (
  //       <Tab
  //         tabClassName="color-red"
  //         eventKey="csummary"
  //         title="Custom summary"
  //         disabled={statsLayerParam === "TX100"}
  //       >
  //         <CustomSummary layerID={statsLayerParam} />
  //       </Tab>
  //     );
  //   }
  // }

  // function RoadSegmentTableSelect(props) {
  //   //const layerID = props.layerID;
  //   if (statusGuide[0] !== "TX100") {
  //     //if (props.layerID === "statewide") {
  //     return (
  //       <RoadSegmentTable
  //         year={year}
  //         layerID={statsLayerParam}
  //         statusGuide={statusGuide}
  //       />
  //     );
  //   } else {
  //     return (
  //       <TX100RoadSegmentTable
  //         year={year}
  //         layerID={statsLayerParam}
  //         statusGuide={statusGuide}
  //       />
  //     );
  //   }
  // }

  // function RoadSegmentTableSelect(props) {
  //   //const layerID = props.layerID;
  //   if (statusGuide[0] !== "TX100") {
  //     //if (props.layerID === "statewide") {
  //     return (
  //       <RoadSegmentTable
  //         year={year}
  //         layerID={statsLayerParam}
  //         statusGuide={statusGuide}
  //       />
  //     );
  //   } else {
  //     return (
  //       <TX100RoadSegmentTable
  //         year={year}
  //         layerID={statsLayerParam}
  //         statusGuide={statusGuide}
  //       />
  //     );
  //   }
  // }

  const [mapType, setMapType] = useState("leaflet");
  //const [mapType, setMapType] = useState("deckGL-lines");
  // const [deckglMapType, setDeckglMapType] = useState("deckGL-lines");
  function setRoadData() {
    fetchRoadData({
      type: "tx100",
      route: "/roads_tx100",
      year: 2021,
    })
      .then((res) => {
        // console.log("succesfull stuff to be done here", res.data);
        return res.data;
      })
      .catch((err) => console.error(err)); // promise
  }

  const [roadGeomFeatures, setRoadGeomFeatures] = useState(
    // {
    //   features: setRoadData("tx100", "/roads_tx100", 2020),
    //   numFeatures: 1,
    //   type: "tx100",
    //   minValue: 0,
    //   maxValue: 0,
    // }

    // fetchRoadData({
    //   type: "tx100",
    //   route: "/roads_tx100",
    //   year: 2020,
    // }).then((response) => {
    //   let fc = response.data.features;

    //   return {
    //     features: fc,
    //     //  features: roadGeomFeatures.features,
    //     numFeatures: fc.length,
    //     type: "tx100",
    //     minValue: 0,
    //     maxValue: 0,
    //   };
    // })

    {
      features: [],
      numFeatures: 0,
      type: null,
      minValue: 0,
      maxValue: 1000000000,
    }
  );
  // setRoadGeomFeatures({
  //   features: setRoadData("tx100", "/roads_tx100", 2020),
  //   //  features: roadGeomFeatures.features,
  //   numFeatures: 1,
  //   type: "tx100",
  //   minValue: roadGeomFeatures.minValue,
  //   maxValue: roadGeomFeatures.maxValue,
  // });

  const [isLoading, setIsLoading] = useState(false);

  const [monitoredRoads, setMonitoredRoads] = useState(null);
  // const [monitoredRoadsSegments, setMonitoredRoadsSegments] = useState(null);
  const [monitoredRoadsData, setMonitoredRoadsData] = useState([]);
  // const [monitoredRoadsDataTrends, setMonitoredRoadsDataTrends] = useState([]);
  // const [monitoredRoadsSummary, setmonitoredRoadsSummary] = useState([]);
  const keyField = "label";

  const [loadingProgress, setLoadingProgress] = useState(0);
  useEffect(() => {
    setIsLoading(true);
    let cntyCounter = 0;

    let geoRouteTemp = geoRoute;
    if (statusGuide[0] === "MPO" && year === 2021) {
      geoRouteTemp = geographic_options_MPO.filter(
        (itm) => itm.geoRoute === geoRoute
      )[0].geoRoute2021;
    }
    if (
      statusGuide[0] === "TxDOT District" ||
      statusGuide[0] === "Regions" ||
      statusGuide[0] === "Networks"
    ) {
      const fetchCountyPromise = async (dataUrl, index) => {
        const countyPromise = await axios.get(dataUrl);
        // setLoadingProgress(((index + 1) / countyIds.length) * 100);
        cntyCounter = cntyCounter + 1;
        setLoadingProgress((cntyCounter / countyIds.length) * 100);
        return countyPromise;
      };
      const fetchDistrictPromise = async (dataUrl) => {
        const countyPromise = await axios.get(dataUrl);
        // setLoadingProgress(((index + 1) / countyIds.length) * 100);
        cntyCounter = cntyCounter + 1;
        setLoadingProgress((cntyCounter / 25) * 100);
        return countyPromise;
      };
      let countyIds = [];
      if (statusGuide[0] === "TxDOT District")
        countyIds = geographic_options_TXDOTdist.filter(
          (obj) => obj.statusGuide === statusGuide
        )[0].counties;
      if (statusGuide[0] === "Regions")
        countyIds = geographic_options_REGIONS.filter(
          (obj) => obj.statusGuide === statusGuide
        )[0].counties;
      let promises = [];
      if (statusGuide[0] === "TxDOT District" || statusGuide[0] === "Regions") {
        promises = countyIds.map((r, index) => {
          const dataUrl =
            `${API_ROOT}` + `/roads/findByCounty?county=` + r + "&year=" + year;
          return fetchCountyPromise(dataUrl, index);
        });
      }
      if (statusGuide[0] === "Networks") {
        promises = geographic_options_TXDOTdist.map((r) => {
          const dataUrl =
            `${API_ROOT}` +
            geoRoute +
            `?district=` +
            r.mapped +
            "&year=" +
            year;
          return fetchDistrictPromise(dataUrl);
        });
      }
      const promiseResult = Promise.allSettled(promises);
      let fTemp = [];

      promiseResult.then((responses) => {
        responses.forEach((response, index) => {
          if (
            statusGuide[0] === "TxDOT District" ||
            statusGuide[0] === "Regions"
          )
            fTemp = fTemp.concat(response.value.data);
          if (statusGuide[0] === "Networks")
            fTemp = fTemp.concat(response.value.data);
        });

        fTemp = fTemp.filter((f) => { return Utils.isValidFeature(f)});
        setRoadGeomFeatures({
          features: fTemp,
          numFeatures: fTemp.length,
          type: "rhino",
          minValue: roadGeomFeatures.minValue,
          maxValue: roadGeomFeatures.maxValue,
        });

        if (fTemp.length > 10000) setMapType("deckGL-lines");

        let monitoredRoadsTemp = [];
        // let monitoredRoadsSegmentsTemp = [];
        let monitoredRoadsDataTemp = [];
        // let monitoredRoadsDataTrendsTemp = [];
        fetchMonitoredRoads()
          .then((response) => {
            response.data.map((fMonitored, index) => {
              let fcTemp = fTemp.filter(
                (f) =>
                  f.properties.ria_rte_id === fMonitored.ria_rte_id &&
                  f.properties.frm_dfo >= fMonitored.frm_dfo &&
                  f.properties.to_dfo <= fMonitored.to_dfo
              );
              if (fcTemp.length > 0) {
                let fcTempData = [];
                let frm_dfo_min = 1000000;
                let to_dfo_max = 0;
                fcTemp.map((obj) => {
                  obj[keyField] = fMonitored.id;
                  // monitoredRoadsSegmentsTemp.push(obj);
                  fcTempData.push(obj.properties);

                  if (obj.properties.frm_dfo < frm_dfo_min)
                    frm_dfo_min = obj.properties.frm_dfo;
                  if (obj.properties.to_dfo > to_dfo_max)
                    to_dfo_max = obj.properties.to_dfo;
                });

                let normalizedData = normalizeRoadSegments(
                  databaseVariablesHPMS,
                  fcTempData,
                  fMonitored
                );
                normalizedData["frm_dfo_orig"] = normalizedData["frm_dfo"];
                normalizedData["to_dfo_orig"] = normalizedData["to_dfo"];
                normalizedData["frm_dfo"] = frm_dfo_min;
                normalizedData["to_dfo"] = to_dfo_max;
                normalizedData["tableData"] = { showDetailPanel: "" };

                monitoredRoadsDataTemp.push(normalizedData);

                monitoredRoadsTemp.push(fMonitored);
              }
            });

            setMonitoredRoads(monitoredRoadsTemp);
            setMonitoredRoadsData(monitoredRoadsDataTemp);
          })
          .catch((err) => console.error(err));

        setIsLoading(false);
      });
    } else {
      // console.log("geoRoute-check", geoRoute);
      fetchRoadData({
        type: statusGuide[0],
        // route: geoRoute,
        route: geoRouteTemp,
        year: year,
      })
        .then((response) => {
          // let fc = response.data.features;
          // console.log("response-check", response);
          let fc = [];
          // if (
          //   statusGuide[0] ===statusGuide[0] === "MPO" ||
          //   statusGuide[0] === "County" ||
          //   "Corridors"
          // )
          //   fc = response.data;
          // else fc = response.data.features;
          fc = response.data.filter((f)=>{ return Utils.isValidFeature(f); });

          // if (statusGuide[0] === "MPO") fTemp = fTemp.concat(response.value.data);
          setRoadGeomFeatures({
            features: fc,
            //  features: roadGeomFeatures.features,
            numFeatures: fc.length,
            // type: "tx100",
            type: statusGuide[0] === "TX100" ? "tx100" : "rhino",
            minValue: roadGeomFeatures.minValue,
            maxValue: roadGeomFeatures.maxValue,
          });

          let monitoredRoadsTemp = [];
          // let monitoredRoadsSegmentsTemp = [];
          let monitoredRoadsDataTemp = [];
          // let monitoredRoadsDataTrendsTemp = [];
          fetchMonitoredRoads()
            .then((response) => {
              response.data.map((fMonitored, index) => {
                let fcTemp = fc.filter(
                  (f) =>
                    f.properties.ria_rte_id === fMonitored.ria_rte_id &&
                    f.properties.frm_dfo >= fMonitored.frm_dfo &&
                    f.properties.to_dfo <= fMonitored.to_dfo
                );
                if (fcTemp.length > 0) {
                  let fcTempData = [];
                  let frm_dfo_min = 1000000;
                  let to_dfo_max = 0;
                  fcTemp.map((obj) => {
                    obj[keyField] = fMonitored.id;
                    // monitoredRoadsSegmentsTemp.push(obj);
                    fcTempData.push(obj.properties);

                    if (obj.properties.frm_dfo < frm_dfo_min)
                      frm_dfo_min = obj.properties.frm_dfo;
                    if (obj.properties.to_dfo > to_dfo_max)
                      to_dfo_max = obj.properties.to_dfo;
                  });

                  let normalizedData = normalizeRoadSegments(
                    databaseVariablesHPMS,
                    fcTempData,
                    fMonitored
                  );
                  normalizedData["frm_dfo_orig"] = normalizedData["frm_dfo"];
                  normalizedData["to_dfo_orig"] = normalizedData["to_dfo"];
                  normalizedData["frm_dfo"] = frm_dfo_min;
                  normalizedData["to_dfo"] = to_dfo_max;
                  normalizedData["tableData"] = { showDetailPanel: "" };

                  monitoredRoadsDataTemp.push(normalizedData);

                  monitoredRoadsTemp.push(fMonitored);
                }
              });

              setMonitoredRoads(monitoredRoadsTemp);
              setMonitoredRoadsData(monitoredRoadsDataTemp);
            })
            .catch((err) => console.error(err));
          setIsLoading(false);
        })
        .catch((err) => console.error(err));
    }
  }, [geoRoute, year]);

  const [map, setMap] = useState(
    <TX100LeafletMap
      // regionId={regionId}
      //bounds={bounds}
      //counties={counties}
      //quads={quads}
      year={year}
      layerID={statsLayerParam}
      //district={districtParam}
      route={geoRoute}
      //minZoom={6}
      //Zoom={6}
      // utprawdata={utprawdata}
      //position={[31.243333, -97.839333]}
      statusGuide={statusGuide}
      mode={mode}
      mapType={"leaflet"}
      roadGeomFeatures={null}
      setRoadGeomFeatures={setRoadGeomFeatures}
    />
  );
  useMemo(() => {
    if (roadGeomFeatures.features.length > 0) {
      if (mapType === "leaflet") {
        if (statusGuide[0] !== "TX100") {
          //if (layerID === "statewide") {
          setMap(
            <RhinoLeafletMap
              // regionId={regionId}
              //bounds={bounds}
              //counties={counties}
              //quads={quads}
              year={year}
              setYear={setYear}
              layerID={statsLayerParam}
              //district={districtParam}
              route={geoRoute}
              //minZoom={6}
              //Zoom={6}
              // utprawdata={utprawdata}
              //position={[31.243333, -97.839333]}
              statusGuide={statusGuide}
              mode={mode}
              mapType={mapType}
              roadGeomFeatures={roadGeomFeatures}
              setRoadGeomFeatures={setRoadGeomFeatures}
              // isLoading={isLoading}
              // setIsLoading={setIsLoading}
            />
          );
        } else {
          setMap(
            <TX100LeafletMap
              // regionId={regionId}
              //bounds={bounds}
              //counties={counties}
              //quads={quads}
              year={year}
              layerID={statsLayerParam}
              //district={districtParam}
              route={geoRoute}
              //minZoom={6}
              //Zoom={6}
              // utprawdata={utprawdata}
              //position={[31.243333, -97.839333]}
              statusGuide={statusGuide}
              mode={mode}
              mapType={mapType}
              roadGeomFeatures={roadGeomFeatures}
              setRoadGeomFeatures={setRoadGeomFeatures}
              // isLoading={isLoading}
              // setIsLoading={setIsLoading}
            />
          );
        }
      } else {
        setMap(
          <DeckglMap
            year={year}
            layerID={statsLayerParam}
            //district={districtParam}
            route={geoRoute}
            //minZoom={6}
            //Zoom={6}
            // utprawdata={utprawdata}
            //position={[31.243333, -97.839333]}
            statusGuide={statusGuide}
            mode={mode}
            mapType={mapType}
            // deckglMapType={deckglMapType}
            // setDeckglMapType={setDeckglMapType}
            roadGeomFeatures={roadGeomFeatures}
            setRoadGeomFeatures={setRoadGeomFeatures}
            // isLoading={isLoading}
            // setIsLoading={setIsLoading}
          />
        );
      }
    }
    //}, [year, statusGuide[0], mapType, deckglMapType, statsLayerParam]);
    //}, [year, statusGuide[0], mapType, statsLayerParam]);
  }, [roadGeomFeatures, mapType]);
  // useMemo(() => {roadGeomFeatures
  //   if (
  //     deckglMapType === "deckGL-lines" ||
  //     deckglMapType === "deckGL-polygons"
  //   ) {
  //     setMap(
  //       <DeckglMap
  //         year={year}
  //         layerID={statsLayerParam}
  //         //district={districtParam}
  //         route={geoRoute}
  //         //minZoom={6}
  //         //Zoom={6}
  //         // utprawdata={utprawdata}
  //         //position={[31.243333, -97.839333]}
  //         statusGuide={statusGuide}
  //         mode={mode}
  //         mapType={"deckGL"}
  //         deckglMapType={deckglMapType}
  //         setDeckglMapType={setDeckglMapType}
  //         roadGeomFeatures={roadGeomFeatures}
  //         setRoadGeomFeatures={setRoadGeomFeatures}
  //       />
  //     );
  //   } else {
  //     if (statusGuide[0] !== "TX100") {
  //       //if (layerID === "statewide") {
  //       setMap(
  //         <RhinoLeafletMap
  //           // regionId={regionId}
  //           //bounds={bounds}
  //           //counties={counties}
  //           //quads={quads}
  //           year={year}
  //           setYear={setYear}
  //           layerID={statsLayerParam}
  //           //district={districtParam}
  //           route={geoRoute}
  //           //minZoom={6}
  //           //Zoom={6}
  //           // utprawdata={utprawdata}
  //           //position={[31.243333, -97.839333]}
  //           statusGuide={statusGuide}
  //           mode={mode}
  //           mapType={"leaflet"}
  //           roadGeomFeatures={roadGeomFeatures}
  //           setRoadGeomFeatures={setRoadGeomFeatures}
  //         />
  //       );
  //     } else {
  //       setMap(
  //         <TX100LeafletMap
  //           // regionId={regionId}
  //           //bounds={bounds}
  //           //counties={counties}
  //           //quads={quads}
  //           year={year}
  //           layerID={statsLayerParam}
  //           //district={districtParam}
  //           route={geoRoute}
  //           //minZoom={6}
  //           //Zoom={6}
  //           // utprawdata={utprawdata}
  //           //position={[31.243333, -97.839333]}
  //           statusGuide={statusGuide}
  //           mode={mode}
  //           mapType={"leaflet"}
  //           roadGeomFeatures={roadGeomFeatures}
  //           setRoadGeomFeatures={setRoadGeomFeatures}
  //         />
  //       );
  //     }
  //   }
  //   //}, [year, statusGuide[0], mapType, deckglMapType, statsLayerParam]);
  // }, [year, statusGuide[0], mapType, deckglMapType, statsLayerParam]);

  // console.log(
  //   "monitoredRoadsDataTrends-check",
  //   monitoredRoadsDataTrends,
  //   "app"
  // );

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <Icon>menu</Icon>
            </IconButton>
            {/* <img
              className="logo"
              src={
                process.env.PUBLIC_URL +
                "/Transparent Background Color3lineLOGO_TxDOT_WHITE_300dpi.png"
              }
              alt="TxDOT Logo"
              title="TxDOT Logo"
            />
            <Typography variant="h4" noWrap>
              Truck Congestion Analysis Tool (TCAT)
            </Typography>
            <img
              className="logo2"
              src={process.env.PUBLIC_URL + "/TTI-Color-reverse.png"}
              alt="TTI Logo"
              title="TTI Logo"
            /> */}
            <Grid container spacing={3} align="center" justify="center">
              <Grid item xs={2} className={classes2.logo1}>
                <img
                  className="logo"
                  src={
                    process.env.PUBLIC_URL +
                    "/Transparent Background Color3lineLOGO_TxDOT_WHITE_300dpi.png"
                  }
                  alt="TxDOT Logo"
                  title="TxDOT Logo"
                />
              </Grid>
              <Grid item xs={8} className={classes2.title}>
                <Typography variant="h4" noWrap style={{ paddingTop: "7.5px" }}>
                  Truck Congestion Analysis Tool (TCAT)
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes2.logo2}>
                <img
                  className="logo2"
                  src={process.env.PUBLIC_URL + "/TTI-Color-reverse.png"}
                  alt="TTI Logo"
                  title="TTI Logo"
                />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <Sidebar
                statsLayerDesc={statsLayerParam}
                setStatsLayer={setStatsLayer}
                setGeoRoute={setGeoRoute}
                setSegmentsTable={setSegmentsTable}
                setSegmentSelection={setSegmentSelection}
                mode={mode}
                statusGuide={statusGuide}
                setStatusGuide={setStatusGuide}
              />
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Sidebar
              statsLayerDesc={statsLayerParam}
              setStatsLayer={setStatsLayer}
              geoRoute={geoRoute}
              setGeoRoute={setGeoRoute}
              setSegmentsTable={setSegmentsTable}
              setSegmentSelection={setSegmentSelection}
              mode={mode}
              statusGuide={statusGuide}
              setStatusGuide={setStatusGuide}
            />
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div>
            {/* <QueryProvider>
              <div id={"map"} className={mode}>
                <MapLayerSelect />
              </div>
              <DataTabs theme={theme} layerID={statsLayerParam} />
            </QueryProvider> */}
            <RhinoQueryProvider>
              <LoadingOverlay
                active={isLoading}
                spinner={
                  <div>
                    <BounceLoader />
                    <LinearProgress
                      variant="determinate"
                      value={loadingProgress}
                      className={classes2.linearProgress}
                    />
                  </div>
                }
                text={
                  statsLayerParam
                    ? "Loading stats layer..."
                    : "Please select a layer from the menu on the left..."
                }
                styles={{
                  overlay: (base) => ({
                    ...base,
                    height: "650px",
                  }),
                }}
              >
                <div id="mapContainer" className={mode} style={{ zIndex: 2 }}>
                  {map}
                </div>
              </LoadingOverlay>
              {/* <RhinoQueryTabs defaultActiveKey="user" id="summary-tabs">
                <Tab
                  tabClassName="color-red"
                  eventKey="user"
                  title="Road Segments"
                >
                  <RoadSegmentTableSelect
                    year={year}
                    layerID={statsLayerParam}
                    statusGuide={statusGuide}
                  />
                </Tab>
                <Tab
                  tabClassName="color-red"
                  eventKey="summaries"
                  title="Summary"
                >
                  <TableWrapper>
                    <TableSummary />
                  </TableWrapper>
                </Tab>
                {renderCustomSummary()}
                <Tab
                  tabClassName="color-red"
                  eventKey="otherMapLayers"
                  title="Other Map Layers"
                >
                  <OtherMapLayers />
                </Tab>
              </RhinoQueryTabs> */}
              {statusGuide[0] === "TX100" && (
                <DataTabsTx100
                  theme={theme}
                  layerID={statsLayerParam}
                  year={year}
                  setYear={setYear}
                  statusGuide={statusGuide}
                  // roadSegmentTable={RoadSegmentTableSelect}
                  mapType={mapType}
                  setMapType={setMapType}
                  // deckglMapType={deckglMapType}
                  // setDeckglMapType={setDeckglMapType}
                  roadGeomFeatures={roadGeomFeatures}
                  setRoadGeomFeatures={setRoadGeomFeatures}
                  // monitoredRoads={monitoredRoads}
                  // monitoredRoadsData={monitoredRoadsData}
                />
              )}
              {statusGuide[0] !== "TX100" && (
                <DataTabs
                  theme={theme}
                  layerID={statsLayerParam}
                  year={year}
                  setYear={setYear}
                  statusGuide={statusGuide}
                  // roadSegmentTable={RoadSegmentTableSelect}
                  mapType={mapType}
                  setMapType={setMapType}
                  // deckglMapType={deckglMapType}
                  // setDeckglMapType={setDeckglMapType}
                  roadGeomFeatures={roadGeomFeatures}
                  setRoadGeomFeatures={setRoadGeomFeatures}
                  monitoredRoads={monitoredRoads}
                  monitoredRoadsData={monitoredRoadsData}
                />
              )}
              {/* <RhinoQueryTabs defaultActiveKey="user" id="summary-tabs">
                <Tab
                  tabClassName="color-red"
                  eventKey="user"
                  title="Road Segments"
                >
                  <RoadSegmentTableSelect
                    year={year}
                    layerID={statsLayerParam}
                  />
                </Tab>
                <Tab
                  tabClassName="color-red"
                  eventKey="summaries"
                  title="Summary"
                >
                  <TableWrapper>
                    <TableSummary
                    />
                  </TableWrapper>
                </Tab>
                {renderCustomSummary()}
                <Tab
                  tabClassName="color-red"
                  eventKey="otherMapLayers"
                  title="Other Map Layers"
                >
                  <OtherMapLayers />
                </Tab>
              </RhinoQueryTabs> */}
              <TrkTop100ODSelLnkMap />
            </RhinoQueryProvider>
          </div>
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
