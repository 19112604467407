import React, { useState, useContext, useEffect } from "react";

import {
  SUMMARY_VARIABLES_RHINO,
  SUMMARY_VARIABLES_TX100,
  SUMMARY_VARIABLES_ROUTE,
  SUMMARY_VARIABLES_ROUTE_100,
  SUMMARY_VARIABLES_GROUPING,
} from "../common/constants";
// import { QueryContext } from "../map/QueryContext";
import { RhinoQueryContext } from "../map/RhinoQueryContext";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TableColumnSelector from "./TableColumnSelector";
import MaterialTable from "@material-table/core";
import Button from "@material-ui/core/Button";

import SaveAlt from "@material-ui/icons/SaveAlt";

import ExportFileNameDialog from "./ExportFileNameDialog";

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(5),
  },
}));

export default function SummaryTable(props) {
  const classes = useStyles();
  const [query, dispatch] = useContext(RhinoQueryContext);
  const [grouping, setGrouping] = useState("ria_rte_id");
  const [selectedRows, setSelectedRows] = useState([]);
  const tableRef = React.createRef();
  const { theme, layerID, year, statusGuide, roadSegmentTable } = props;

  // const [roadLayerType] = useState(query.roadLayerType);
  const roadLayerType = statusGuide[0];

  function groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  // if (statusGuide[0] === "TX100") {
  function filterHPMSVariables(category) {
    let results = [];
    let SUMMARY_VARIABLES_layer;
    if (roadLayerType !== "TX100") {
      //    if (roadLayerType === "HPMS") {
      SUMMARY_VARIABLES_layer = SUMMARY_VARIABLES_RHINO;
    } else {
      SUMMARY_VARIABLES_layer = SUMMARY_VARIABLES_TX100;
    }

    for (var i = SUMMARY_VARIABLES_layer.length - 1; i >= 0; i--) {
      if (category === SUMMARY_VARIABLES_layer[i].category) {
        results.push(SUMMARY_VARIABLES_layer[i]);
      }
    }

    return results;
  }

  function buildCols() {
    let cols = [];
    let colVars = [];

    // if (roadLayerType === "TX100") {
    //   colVars = SUMMARY_VARIABLES_ROUTE_100;
    // } else if (grouping === "ria_rte_id") {
    //   colVars = SUMMARY_VARIABLES_ROUTE;
    // } else {
    //   colVars.push(
    //     SUMMARY_VARIABLES_GROUPING.find((value) => {
    //       return value.id === grouping;
    //     })
    //   );
    // }

    // for (var i = colVars.length - 1; i >= 0; i--) {
    //   cols.push(colVars[i]);
    // }

    colVars = filterHPMSVariables("route");
    for (var ii = colVars.length - 1; ii >= 0; ii--) {
      cols.push({
        id: colVars[ii].variable,
        numeric: true,
        disablePadding: false,
        label: colVars[ii].summaryName,
        tooltip: colVars[ii].tooltip,
        checked: true,
        category: colVars[ii].category,
        normalizedBy:
          typeof colVars[ii].normalizedBy !== "undefined"
            ? colVars[ii].normalizedBy
            : false,
      });
    }

    colVars = filterHPMSVariables("all");
    for (var ii = colVars.length - 1; ii >= 0; ii--) {
      cols.push({
        id: colVars[ii].variable,
        numeric: true,
        disablePadding: false,
        label: colVars[ii].summaryName,
        tooltip: colVars[ii].tooltip,
        checked: true,
        category: colVars[ii].category,
        normalizedBy:
          typeof colVars[ii].normalizedBy !== "undefined"
            ? colVars[ii].normalizedBy
            : false,
      });
    }

    colVars = filterHPMSVariables("truck");
    for (var iii = colVars.length - 1; iii >= 0; iii--) {
      cols.push({
        id: colVars[iii].variable,
        numeric: true,
        disablePadding: false,
        label: colVars[iii].summaryName,
        tooltip: colVars[iii].tooltip,
        checked: true,
        category: colVars[iii].category,
        normalizedBy:
          typeof colVars[iii].normalizedBy !== "undefined"
            ? colVars[iii].normalizedBy
            : false,
      });
    }

    return cols;
  }
  let columns = buildCols();

  function buildColsState(cols, category, state) {
    columns
      .filter((item) => item.category === category)
      .map(
        (col, index) =>
          (cols = {
            ...cols,
            [col.id]: state === "keep" ? columnsState[col.id] : state,
          })
      );

    return cols;
  }

  const [columnsState, setColumnsState] = useState(query.tableColumns);
  useEffect(() => {
    let cols = {};

    cols = buildColsState(cols, "route", true);
    cols = buildColsState(cols, "all", false);
    cols = buildColsState(cols, "truck", true);

    // dispatch({
    //   type: "setTableColumns",
    //   selection: cols,
    // });
  }, [roadLayerType, grouping]);

  useEffect(() => {
    setColumnsState(query.tableColumns);
  }, [query.tableColumns, grouping]);

  useEffect(() => {
    setSelectedRows([]);
  }, [props.statusGuide]);

  function buildColsNew(type) {
    let cols = [];
    let columnsTemp = columns;

    if (type === "detailed_panel" || roadLayerType === "TX100") {
      columnsTemp = columnsTemp.filter(
        (headCell) => headCell.id !== "ria_rte_id" //(headCell) => headCell.id !== "ria_rte_id"
      );
    }

    let SUMMARY_VARIABLES_ROUTE_Temp = [];
    if (roadLayerType === "TX100") {
      SUMMARY_VARIABLES_ROUTE_Temp = SUMMARY_VARIABLES_ROUTE_100;
    } else if (grouping === "ria_rte_id") {
      SUMMARY_VARIABLES_ROUTE_Temp = SUMMARY_VARIABLES_ROUTE;
    } else {
      SUMMARY_VARIABLES_ROUTE_Temp.push(
        SUMMARY_VARIABLES_GROUPING.find((value) => {
          return value.id === grouping;
        })
      );
    }
    SUMMARY_VARIABLES_ROUTE_Temp.map((obj) => {
      cols.push({
        title: obj.label,
        field: obj.id,
        cellStyle: {
          borderTopWidth: 0,
          borderBottomWidth: 1,
          borderLeftWidth: 0,
          borderRightWidth: 2,
          borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
          borderStyle: "solid",
        },
        headerStyle: {
          borderTopWidth: 0,
          borderBottomWidth: 1,
          borderLeftWidth: 0,
          borderRightWidth: 2,
          borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
          borderStyle: "solid",
        },
        tooltip: obj.tooltip,
        tooltipStyle: {
          fontSize: 12,
        },
        render: (rowData) =>
          rowData[obj.id] !== null &&
          typeof rowData[obj.id] !== "undefined" &&
          rowData[obj.id] !== "undefined"
            ? rowData[obj.id].toLocaleString("en-US", obj.formatOptions)
            : "",
      });
    });

    columnsTemp.map(
      (headCell) =>
        columnsState[headCell.id] &&
        cols.push({
          title: headCell.label,
          field: headCell.id,
          cellStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          headerStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          tooltip: headCell.tooltip,
          tooltipStyle: {
            fontSize: 12,
          },
          render: (rowData) =>
            rowData[headCell.id] !== null &&
            typeof rowData[headCell.id] !== "undefined" &&
            rowData[headCell.id] !== "undefined"
              ? rowData[headCell.id].toLocaleString(
                  "en-US",
                  headCell.formatOptions
                )
              : "",
        })
    );

    return cols;
  }

  function aggrigateRow(rawRow) {
    let row = {};

    for (const column in columnsState) {
      let colVars = columns.find((obj) => {
        return obj.id === column;
      });

      // check to see if normalization is needed.
      if (
        columnsState[column] &&
        typeof colVars !== "undefined" &&
        typeof colVars.normalizedBy !== "undefined" &&
        colVars.normalizedBy
      ) {
        let sum = 0;
        let normSum = 0;

        for (var i = rawRow.length - 1; i >= 0; i--) {
          sum += rawRow[i][column] * rawRow[i][colVars.normalizedBy];
          normSum += rawRow[i][colVars.normalizedBy];
        }

        row[column] = sum / normSum;
        // standard aggregate.
      } else if (
        columnsState[column] &&
        typeof colVars !== "undefined" &&
        colVars.numeric &&
        column !== "beg_point" &&
        column !== "end_point"
      ) {
        row[column] = rawRow.reduce(function(prev, cur) {
          return prev + cur[column];
        }, 0);
        // Text only.
      } else if (columnsState[column]) {
        let label = false;
        let col = columns.find((c) => column === c.id);

        if (
          typeof col !== "undefined" &&
          typeof col.queryVariable !== "undefined"
        ) {
          label = query[col.queryVariable].find(
            (value) => value.id == rawRow[0][column]
          );
        }

        if (label) {
          row[column] = label.label;
        } else {
          row[column] = rawRow[0][column];
        }
      }
    }

    return row;
  }

  function buildRow(rawRow) {
    let row = {};
    for (const column in columnsState) {
      if (columnsState[column]) {
        if (column === "trkvolper") {
          row[column] = (rawRow["tottrk"] / rawRow["adt_cur"]) * 100;
        } else {
          row[column] = rawRow[column];
        }
      }
    }

    //row.id = rawRow.id;
    if (roadLayerType === "TX100") {
      row.id = rawRow.top100id;
      row.top100id = rawRow.top100id;
    } else {
      row.id = rawRow.ogc_fid_new;
      row.ria_rte_id = rawRow.ria_rte_id;
      row.frm_dfo = rawRow.frm_dfo;
      row.to_dfo = rawRow.to_dfo;
    }
    return row;
  }

  function buildRows() {
    let rows = [];
    if (roadLayerType !== "TX100") {
      if (grouping === "total") {
        let row = aggrigateRow(query.features);
        row.trkvolper = (row.tottrk / row.adt_cur) * 100;
        row[grouping] = "Total";
        rows.push(row);
      } else {
        let rowsGroup = groupBy(query.features, grouping);
        for (const group in rowsGroup) {
          let row = aggrigateRow(rowsGroup[group]);
          row.segments = rowsGroup[group];
          row.id = row.segments[0].ogc_fid_new;
          row.trkvolper = (row.tottrk / row.adt_cur) * 100;
          if (grouping === "ria_rte_id") {
            row.end_point = row.segments[row.segments.length - 1].end_point;
          }
          if (row[grouping] === null) row[grouping] = "None/Other";

          if (grouping === "ria_rte_id") {
            SUMMARY_VARIABLES_ROUTE.map((obj) => {
              let tempData = row.segments[0];
              row[obj.id] = tempData[obj.id];
            });
          } else {
            let tempData = row.segments[0][grouping];

            let filterGrouping = SUMMARY_VARIABLES_GROUPING.find((value) => {
              return value.id === grouping;
            }).queryVariable;

            row[grouping] = query[filterGrouping].find((value) => {
              if (value.id === tempData.toString())
                return value.id === tempData.toString();
              return value.id === tempData;
            }).label;
          }

          let segs = row.segments.sort((a, b) => a.frm_dfo - b.frm_dfo);
          row.frm_dfo = segs[0].frm_dfo;
          row.to_dfo = segs[segs.length - 1].to_dfo;
          rows.push(row);
        }
      }
    } else {
      let rawRows = query.features;

      for (var i = rawRows.length - 1; i >= 0; i--) {
        let row = buildRow(rawRows[i]);
        if (row["road"] === "") row["road"] = "Unknown";
        if (row["from_road"] === "") row["from_road"] = "Unknown";
        if (row["to_road"] === "") row["to_road"] = "Unknown";

        SUMMARY_VARIABLES_ROUTE_100.map((obj) => {
          row[obj.id] = rawRows[i][obj.id];
        });

        rows.push(row);
        // rows.push(buildRow(rawRows[i]));
      }
    }

    return rows;
  }
  let rows = buildRows();

  function handleChange(e) {
    setGrouping(e.target.value);
  }

  const isRoadSelected = (selectedRows, feature) => {
    if (roadLayerType === "TX100") {
      // return selectedRows.includes(feature.properties.top100id);
      return selectedRows.includes(feature.top100id);
    } else {
      return selectedRows.includes(feature.ogc_fid_new);
      // return selectedRows.includes(feature.properties.ogc_fid_new);
    }
  };

  const updateSelectedRows = (srows) => {
    dispatch({
      type: "setHighlightQuery",
      query: isRoadSelected.bind(null, srows),
    });
  };

  const handleClick = (data) => {
    setSelectedRows(data);
    if (data.length > 0) {
      if (roadLayerType === "TX100") {
        const newSelecteds = data.map((n) => n.top100id);
        updateSelectedRows(newSelecteds);
      } else {
        let segmentIds = [];
        for (var d = data.length - 1; d >= 0; d--) {
          for (var ds = data[d].segments.length - 1; ds >= 0; ds--) {
            //segmentIds.push(data[d].segments[ds].id);
            segmentIds.push(data[d].segments[ds].ogc_fid_new);
          }
        }

        updateSelectedRows(segmentIds);
      }

      return;
    }

    updateSelectedRows([]);
  };

  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [csvFileNameTest, setCsvFileNameTest] = useState("myTable");
  const [exportCols, setExportCols] = useState();
  const [exportData, setExportData] = useState();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper + " table-null"}>
        <Typography variant="subtitle1">
          <strong>Note:</strong> 2021 reporting year (2020 data) was impacted by
          the COVID-19 pandemic and is not reflective of a typical year.
        </Typography>
      </Paper>
      {rows.length !== null && (
        <Paper className={classes.paper + " table-wrap"}>
          <MaterialTable
            title=""
            columns={buildColsNew("full_table")}
            //code below is for selectable rows - commented out pending more debugging
            data={rows.map((row) =>
              selectedRows.find((selected) => selected.id === row.id)
                ? { ...row, tableData: { checked: true } }
                : row
            )}
            // data={rows.map((row) => row)}
            tableRef={tableRef}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 100],
              exportButton: true,
              exportAllData: true,
              exportMenu: [
                // {
                //   label: "Export PDF",
                //   exportFunc: (cols, datas) =>
                //     ExportPdf(cols, datas, "myPdfFileName"),
                // },
                // {
                //   label: "Export CSV",
                //   exportFunc: (cols, datas) =>
                //     ExportCsv(cols, datas, "myCsvFileName"),
                // },
                {
                  label: "Export Table",
                  exportFunc: (cols, data) => {
                    setOpenExportDialog(true);
                    setExportCols(cols);
                    setExportData(data);
                  },
                },
              ],
              //code below is for selectable rows - commented out pending more debugging
              selection: grouping === "total" ? false : true,
              // selection: false,
              // exportFileName: () => new Date().toLocaleDateString(),
              exportFileName: "testfile",
              actionsColumnIndex: -1,
              toolbarButtonAlignment:
                selectedRows.length > 0 ? "left" : "right", // here is the option to change toolbar buttons' alignment
            }}
            icons={{
              Export: () => (
                // <div className={classes.exportButtonDiv}>
                <SaveAlt fontSize="medium" className={classes.exportButton} />
                // </div>
              ),
            }}
            actions={[
              {
                tooltip: "Select Grouping",
                hidden: roadLayerType === "TX100",
                icon: () => {
                  return (
                    <FormControl className={classes.formControl}>
                      <InputLabel id="grouping">Group By</InputLabel>
                      <Select
                        labelId="grouping"
                        id="grouping"
                        value={grouping}
                        onChange={handleChange}
                      >
                        <MenuItem value="ria_rte_id">Route ID</MenuItem>
                        {/* <MenuItem value="hsys_new">
                          Highway System Designation
                        </MenuItem> */}
                        <MenuItem value="frght_ntwk_st">
                          Texas Freight Network
                        </MenuItem>
                        <MenuItem value="frght_ntwk_nl">
                          National Freight Network
                        </MenuItem>
                        <MenuItem value="hsys">
                          Highway System Designation
                        </MenuItem>
                        <MenuItem value="ru">Rural/Urban Code</MenuItem>
                        <MenuItem value="f_system">
                          Functional Classification
                        </MenuItem>
                        <MenuItem value="co">County</MenuItem>
                        <MenuItem value="di">TxDOT District</MenuItem>
                        <MenuItem value="mpa">MPO</MenuItem>
                        <MenuItem value="sec_nhs">NHS</MenuItem>
                        <MenuItem value="estimated">Estimated</MenuItem>
                        {/* <MenuItem value="ownership">Ownership</MenuItem>
                        <MenuItem value="f_system">Functional System</MenuItem>
                        <MenuItem value="urban_code">Urban Code</MenuItem>
                        <MenuItem value="nhs">
                          National Highway System (NHS)
                        </MenuItem>
                        <MenuItem value="strahnet_t">
                          Strategic Highway Network (STRAHNET)
                        </MenuItem>
                        <MenuItem value="truck">
                          National Truck Network (NN)
                        </MenuItem>
                        <MenuItem value="county_cod">County</MenuItem> */}
                        <MenuItem value="total">Total</MenuItem>
                      </Select>
                    </FormControl>
                  );
                },
                onClick: () => {},
                isFreeAction: true,
              },
              {
                tooltip: "",
                icon: () => {
                  return (
                    <div>
                      <Button variant="contained" color="secondary">
                        Clear Selection
                      </Button>
                    </div>
                  );
                },
                onClick: () => {
                  updateSelectedRows([]);
                  setSelectedRows([]);
                  if (tableRef.current) {
                    tableRef.current.onAllSelected(false);
                  }
                },
                isFreeAction: grouping === "total" ? true : false,
                disable: grouping === "total" ? true : false,
                hidden: grouping === "total" ? true : false,
              },
              {
                tooltip: "",
                icon: () => {
                  return (
                    <div>
                      <TableColumnSelector columns={columns} />
                    </div>
                  );
                },
                onClick: () => {},
                isFreeAction: true,
              },
            ]}
            onSelectionChange={(srows) => handleClick(srows)}
            // detailPanel={[
            //   {
            //     tooltip: "Show Detailed Information",
            //     render: (rowData) => {
            //       return (
            //         <div
            //           style={{
            //             fontSize: 100,
            //             textAlign: "center",
            //             color: "white",
            //             backgroundColor: "#43A047",
            //           }}
            //         >
            //           {materialTableDetailPanel(rowData)}
            //         </div>
            //       );
            //     },
            //   },
            // ]}
          />
          <ExportFileNameDialog
            openExportDialog={openExportDialog}
            setOpenExportDialog={setOpenExportDialog}
            csvFileNameTest={csvFileNameTest}
            setCsvFileNameTest={setCsvFileNameTest}
            exportCols={exportCols}
            exportData={exportData}
          />
        </Paper>
      )}
    </div>
  );
}
