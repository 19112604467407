import React, { useState, useContext, useEffect } from "react";

import { lighten, withStyles, makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
//import Icon from '@material-ui/core/Icon';
import Paper from "@material-ui/core/Paper";

//import Image from 'material-ui-image';

import { RhinoQueryContext } from "../map/RhinoQueryContext";

// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
// } from "@material-ui/core";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

import Tooltip from "@material-ui/core/Tooltip";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";

import InfoIcon from "@material-ui/icons/Info";

import { Grid } from "@material-ui/core/";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  card: {
    borderRadius: 0,
    // backgroundColor: theme.palette.primary.light,
    // color: theme.palette.primary.contrastText,
    boxShadow: "none",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  cardcontent: {
    paddingTop: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  cardActions: {
    color: "#5945cc",
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
    marginTop: 5,
  },
}));

export default function OtherMapLayers(props) {
  const classes = useStyles();
  const [query, dispatch] = useContext(RhinoQueryContext);
  //const [queryOtherLayers, dispatchOtherLayers] = useContext(OtherLayersQueryContext);

  // const [utpLayersChecked, setutpLayersChecked] = useState({
  //   underwayorsoon: false,
  //   within4years: false,
  //   fivetotenyears: false,
  //   tenplusyears: false,
  // });
  const {
    utpLayersChecked,
    setutpLayersChecked,
    contextLayersChecked,
    setContextLayersChecked,
  } = props;

  const handleCheckChange = (event) => {
    setutpLayersChecked({
      ...utpLayersChecked,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    dispatch({
      type: "setutpMapLayersVisibility",
      selection: {
        underwayorsoon: utpLayersChecked.underwayorsoon,
        within4years: utpLayersChecked.within4years,
        fivetotenyears: utpLayersChecked.fivetotenyears,
        tenplusyears: utpLayersChecked.tenplusyears,
      }, //fs //tableDataUTPvalues.concat(fs) //fs
    });
  }, [utpLayersChecked]);

  // const [contextLayersChecked, setContextLayersChecked] = useState({
  //   countyBoundaries: false,
  //   districtBoundaries: false,
  //   odessaDistSandMines: false,
  //   disposalWells: false,
  //   commercialBorderCrossings: false,
  //   nonCommercialBorderCrossings: false,
  //   railBorderCrossings: false,
  //   portsLayer: false,
  //   cargoAirports: false,
  //   airports: false,
  // });

  const handleContextLayersCheckChange = (event) => {
    setContextLayersChecked({
      ...contextLayersChecked,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    dispatch({
      type: "setContextLayersVisibility",
      selection: {
        countyBoundaries: contextLayersChecked.countyBoundaries,
        districtBoundaries: contextLayersChecked.districtBoundaries,
        odessaDistSandMines: contextLayersChecked.odessaDistSandMines,
        disposalWells: contextLayersChecked.disposalWells,
        commercialBorderCrossings:
          contextLayersChecked.commercialBorderCrossings,
        nonCommercialBorderCrossings:
          contextLayersChecked.nonCommercialBorderCrossings,
        railBorderCrossings: contextLayersChecked.railBorderCrossings,
        portsLayer: contextLayersChecked.portsLayer,
        cargoAirports: contextLayersChecked.cargoAirports,
        airports: contextLayersChecked.airports,
        truckParkingLayer: contextLayersChecked.truckParkingLayer,
      }, //fs //tableDataUTPvalues.concat(fs) //fs
    });
  }, [contextLayersChecked]);

  // const HtmlTooltip = withStyles((theme) => ({
  //   tooltip: {
  //     //backgroundColor: '#f5f5f9',
  //     //color: 'rgba(0, 0, 0, 0.87)',
  //     maxWidth: 300,
  //     fontSize: theme.typography.pxToRem(14),
  //     border: "1px solid #dadde9",
  //   },
  // }))(Tooltip);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper + " table-wrap"}>
        <Grid
          container
          spacing={1}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          borderColor="red"
        >
          <Grid item xs={2} key={"other-map-layers-geos"}>
            <Typography variant="h5" component="h6" className={classes.title}>
              Geographies
            </Typography>
            <Card classes={{ root: classes.card }}>
              <CardHeader
                title=" "
                subheader=" "
                style={{ marginBottom: "none" }}
              />
              <CardContent className={classes.cardcontent}>
                {/* <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Geographies
              </Typography> */}
                <FormGroup column="true">
                  <FormControlLabel
                    style={{ width: "auto" }}
                    control={
                      <Switch
                        //checked={contextLayersChecked.countyBoundaries}
                        //onChange={handleContextLayersCheckChange}
                        name="roadSegments"
                        inputProps={{
                          title: "Show Road Segments",
                          "aria-label": "toggle roadSegments data",
                        }}
                      />
                    }
                    label={"Road Segments"}
                    disabled
                  />
                  <FormControlLabel
                    style={{ width: "auto" }}
                    control={
                      <Switch
                        checked={contextLayersChecked.countyBoundaries}
                        onChange={handleContextLayersCheckChange}
                        name="countyBoundaries"
                        inputProps={{
                          title: "Show County Boundaries",
                          "aria-label": "toggle countyBoundaries data",
                        }}
                      />
                    }
                    label={"County Boundaries"}
                  />
                  <FormControlLabel
                    style={{ width: "auto" }}
                    control={
                      <Switch
                        checked={contextLayersChecked.districtBoundaries}
                        onChange={handleContextLayersCheckChange}
                        name="districtBoundaries"
                        inputProps={{
                          title: "Show TxDOT Districts",
                          "aria-label": "toggle districtBoundaries data",
                        }}
                      />
                    }
                    label={"TxDOT Districts"}
                  />
                  {/* <CardActions>
                    <Button
                      size="small"
                      href="https://gis-txdot.opendata.arcgis.com/datasets/txdot-districts/geoservice?geometry=-141.493%2C24.386%2C-57.865%2C37.526"
                      target="_blank"
                      color="primary"
                      className={classes.cardActions}
                    >
                      <InfoIcon />
                      More Information - TxDOT Open Data Portal
                    </Button>
                  </CardActions> */}
                </FormGroup>
              </CardContent>
            </Card>
            <Button
              size="small"
              href="https://gis-txdot.opendata.arcgis.com/datasets/txdot-districts/geoservice?geometry=-141.493%2C24.386%2C-57.865%2C37.526"
              target="_blank"
              color="primary"
              className={classes.cardActions}
            >
              <InfoIcon />
              More Information - TxDOT Open Data Portal
            </Button>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            style={{
              // marginTop: "10px",
              marginLeft: "-10px",
              marginRight: "0px",
            }}
          />
          <Grid item xs={3} key={"other-map-layers-utp-projects"}>
            <Typography variant="h5" className={classes.title}>
              UTP Projects
            </Typography>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={6} key={"other-map-layers-utp-projects1"}>
                <Card classes={{ root: classes.card }}>
                  <CardHeader title=" " subheader="Near Term" />
                  <CardContent className={classes.cardcontent}>
                    <FormGroup column="true">
                      <FormControlLabel
                        style={{ width: "auto", marginBottom: "25px" }}
                        control={
                          <Switch
                            checked={utpLayersChecked.underwayorsoon}
                            onChange={handleCheckChange}
                            name="underwayorsoon"
                            inputProps={{
                              title:
                                "Show Construction underway or begins soon",
                              "aria-label": "toggle within4years data",
                            }}
                          />
                        }
                        label={"Construction underway or begins soon"}
                        //labelPlacement="start"
                      />
                      <FormControlLabel
                        style={{ width: "auto" }}
                        control={
                          <Switch
                            checked={utpLayersChecked.within4years}
                            onChange={handleCheckChange}
                            name="within4years"
                            inputProps={{
                              title: "Show Construction begins within 4 years",
                              "aria-label": "toggle within4years data",
                            }}
                          />
                        }
                        label={"Construction begins within 4 years"}
                        //labelPlacement="start"
                      />
                    </FormGroup>
                  </CardContent>
                </Card>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                style={{
                  marginTop: "10px",
                  marginLeft: "-10px",
                  marginRight: "0px",
                }}
              />
              <Grid item xs={6} key={"other-map-layers-utp-projects2"}>
                <Card classes={{ root: classes.card }}>
                  <CardHeader title=" " subheader="Long Term" />
                  <CardContent className={classes.cardcontent}>
                    <FormGroup column="true">
                      <FormControlLabel
                        style={{ width: "auto" }}
                        control={
                          <Switch
                            checked={utpLayersChecked.fivetotenyears}
                            onChange={handleCheckChange}
                            name="fivetotenyears"
                            inputProps={{
                              title:
                                "Show Construction begins in 5 to 10 years",
                              "aria-label": "toggle fivetotenyears data",
                            }}
                          />
                        }
                        label={"Construction begins in 5 to 10 years"}
                        //labelPlacement="start"
                      />
                      <FormControlLabel
                        style={{ width: "auto" }}
                        control={
                          <Switch
                            checked={utpLayersChecked.tenplusyears}
                            onChange={handleCheckChange}
                            name="tenplusyears"
                            inputProps={{
                              title:
                                "Show Corridor Studies, construction in 10+ years",
                              "aria-label": "toggle tenplusyears data",
                            }}
                          />
                        }
                        label={"Corridor Studies, construction in 10+ years"}
                        //labelPlacement="start"
                      />
                      {/* <CardActions>
                        <Button
                          size="small"
                          href="https://gis-txdot.opendata.arcgis.com/datasets/txdot-projects"
                          target="_blank"
                          color="primary"
                          className={classes.cardActions}
                        >
                          <InfoIcon />
                          More Information - TxDOT Open Data Portal
                        </Button>
                      </CardActions> */}
                    </FormGroup>
                  </CardContent>
                </Card>{" "}
              </Grid>
              <Button
                size="small"
                href="https://gis-txdot.opendata.arcgis.com/datasets/txdot-projects"
                target="_blank"
                color="primary"
                className={classes.cardActions}
              >
                <InfoIcon />
                More Information - TxDOT Open Data Portal
              </Button>
            </Grid>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            style={{
              // marginTop: "10px",
              marginLeft: "-10px",
              marginRight: "0px",
            }}
          />
          <Grid item xs={3} key={"other-map-layers-truck-trip-generators"}>
            <Typography variant="h5" component="h6" className={classes.title}>
              Truck Trip Generators
            </Typography>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={6} key={"other-map-layers-truck-trip-generators1"}>
                <Card classes={{ root: classes.card }}>
                  <CardHeader title=" " subheader="Energy Sector" />
                  <CardContent className={classes.cardcontent}>
                    <FormGroup column="true">
                      <FormControlLabel
                        style={{ width: "auto" }}
                        control={
                          <Switch
                            checked={contextLayersChecked.odessaDistSandMines}
                            onChange={handleContextLayersCheckChange}
                            name="odessaDistSandMines"
                            inputProps={{
                              title: "Show Odessa District Sand Mines",
                              "aria-label": "toggle odessaDistSandMines data",
                            }}
                          />
                        }
                        label={"Odessa District Sand Mines"}
                        //labelPlacement="start"
                      />
                      <FormControlLabel
                        style={{ width: "auto" }}
                        control={
                          <Switch
                            checked={contextLayersChecked.disposalWells}
                            onChange={handleContextLayersCheckChange}
                            name="disposalWells"
                            inputProps={{
                              title: "Show Disposal Wells",
                              "aria-label": "toggle disposalWells data",
                            }}
                          />
                        }
                        label={"Disposal Wells"}
                        //labelPlacement="start"
                      />
                    </FormGroup>
                  </CardContent>
                </Card>
                <Divider
                  // orientation="vertical"
                  // flexItem
                  style={{
                    // marginTop: "10px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                />
                <Card classes={{ root: classes.card }}>
                  <CardHeader title=" " subheader="Truck Parking" />
                  <CardContent className={classes.cardcontent}>
                    <FormGroup column="true">
                      <FormControlLabel
                        style={{ width: "auto" }}
                        control={
                          <Switch
                            checked={contextLayersChecked.truckParkingLayer}
                            onChange={handleContextLayersCheckChange}
                            name="truckParkingLayer"
                            inputProps={{
                              title: "Show Truck Parking",
                              "aria-label": "toggle truckParking data",
                            }}
                          />
                        }
                        label={"Truck Parking"}
                        //labelPlacement="start"
                      />
                    </FormGroup>
                  </CardContent>
                </Card>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                style={{
                  marginTop: "10px",
                  marginLeft: "-10px",
                  marginRight: "0px",
                }}
              />
              <Grid item xs={6} key={"other-map-layers-truck-trip-generators2"}>
                <Card classes={{ root: classes.card }}>
                  <CardHeader title=" " subheader="Border Crossings" />
                  <CardContent>
                    <FormGroup column="true">
                      <FormControlLabel
                        style={{ width: "auto" }}
                        control={
                          <Switch
                            checked={
                              contextLayersChecked.commercialBorderCrossings
                            }
                            onChange={handleContextLayersCheckChange}
                            name="commercialBorderCrossings"
                            inputProps={{
                              title: "Show Border Crossings - Commercial",
                              "aria-label":
                                "toggle commercialBorderCrossings data",
                            }}
                          />
                        }
                        // label={"Border Crossings - Commercial Travel"}
                        label={"Commercial Travel"}
                      />
                      <FormControlLabel
                        style={{ width: "auto" }}
                        control={
                          <Switch
                            checked={
                              contextLayersChecked.nonCommercialBorderCrossings
                            }
                            onChange={handleContextLayersCheckChange}
                            name="nonCommercialBorderCrossings"
                            inputProps={{
                              title: "Show Border Crossings - No Commercial",
                              "aria-label":
                                "toggle nonCommercialBorderCrossings data",
                            }}
                          />
                        }
                        // label={"Border Crossings - No Commercial Travel"}
                        label={"No Commercial Travel"}
                      />
                      <FormControlLabel
                        style={{ width: "auto" }}
                        control={
                          <Switch
                            checked={contextLayersChecked.railBorderCrossings}
                            onChange={handleContextLayersCheckChange}
                            name="railBorderCrossings"
                            inputProps={{
                              title: "Show Border Crossings - Rail Only",
                              "aria-label": "toggle railBorderCrossings data",
                            }}
                          />
                        }
                        // label={"Border Crossings - Rail Only"}
                        label={"Rail Only"}
                      />
                    </FormGroup>
                    <CardActions>
                      <Button
                        size="small"
                        href="https://gis-txdot.opendata.arcgis.com/datasets/TXDOT::texas-border-crossings/about"
                        target="_blank"
                        color="primary"
                        className={classes.cardActions}
                      >
                        <InfoIcon />
                        More Information - TxDOT Open Data Portal
                      </Button>
                    </CardActions>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            style={{
              // marginTop: "10px",
              marginLeft: "-10px",
              marginRight: "0px",
            }}
          />
          <Grid
            item
            xs={4}
            key={"other-map-layers-truck-freight-infrastructure"}
          >
            <Typography variant="h5" component="h6" className={classes.title}>
              Freight Infrastructure
            </Typography>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid
                item
                xs={6}
                key={"other-map-layers-truck-freight-infrastructure1"}
              >
                <Card classes={{ root: classes.card }}>
                  <CardHeader
                    title=" "
                    subheader="Maritime Ports, Inland ports, International Ports of Entry"
                  />
                  <CardContent className={classes.cardcontent}>
                    <FormGroup column="true">
                      <FormControlLabel
                        style={{ width: "auto" }}
                        control={
                          <Switch
                            checked={contextLayersChecked.portsLayer}
                            onChange={handleContextLayersCheckChange}
                            name="portsLayer"
                            inputProps={{
                              title: "Show Ports",
                              "aria-label": "toggle portsLayer data",
                            }}
                          />
                        }
                        label={"Ports"}
                        //labelPlacement="start"
                      />
                    </FormGroup>
                    <CardActions>
                      <Button
                        size="small"
                        href="https://gis-txdot.opendata.arcgis.com/datasets/txdot-seaports"
                        target="_blank"
                        color="primary"
                        className={classes.cardActions}
                      >
                        <InfoIcon />
                        More Information - TxDOT Open Data Portal
                      </Button>
                    </CardActions>{" "}
                  </CardContent>
                </Card>
                <Divider
                  // orientation="vertical"
                  // flexItem
                  style={{
                    // marginTop: "10px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                />
                <Card classes={{ root: classes.card }}>
                  <CardHeader title=" " subheader="Intermodal Facilities" />
                  <CardContent className={classes.cardcontent}>
                    <FormGroup column="true">
                      <FormControlLabel
                        style={{ width: "auto" }}
                        control={
                          <Switch
                            //   checked={contextLayersChecked.odessaDistSandMines}
                            //   onChange={handleContextLayersCheckChange}
                            name="intermodalFacilities"
                            inputProps={{
                              title: "Show Intermodal Facilities",
                              "aria-label": "toggle intermodalFacilities data",
                            }}
                          />
                        }
                        label={"Intermodal Facilities"}
                        //labelPlacement="start"
                        disabled
                      />
                    </FormGroup>
                  </CardContent>
                </Card>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                style={{
                  marginTop: "10px",
                  marginLeft: "-10px",
                  marginRight: "0px",
                }}
              />
              <Grid
                item
                xs={6}
                key={"other-map-layers-truck-freight-infrastructure2"}
              >
                <Card classes={{ root: classes.card }}>
                  <CardHeader title=" " subheader="Airports" />
                  <CardContent>
                    <FormGroup column="true">
                      <FormControlLabel
                        style={{ width: "auto" }}
                        control={
                          <Switch
                            checked={contextLayersChecked.cargoAirports}
                            onChange={handleContextLayersCheckChange}
                            name="cargoAirports"
                            inputProps={{
                              title: "Show Cargo Airports",
                              "aria-label": "toggle cargoAirports data",
                            }}
                          />
                        }
                        label={"Cargo Airports"}
                        //labelPlacement="start"
                        //disabled
                      />
                      <FormControlLabel
                        style={{ width: "auto" }}
                        control={
                          <Switch
                            checked={contextLayersChecked.airports}
                            onChange={handleContextLayersCheckChange}
                            name="airports"
                            inputProps={{
                              title: "Show Major Airports",
                              "aria-label": "toggle ajorAirports data",
                            }}
                          />
                        }
                        label={"Other Major Airports"}
                        //labelPlacement="start"
                        //disabled
                      />
                    </FormGroup>
                    <CardActions>
                      <Button
                        size="small"
                        href="https://data-usdot.opendata.arcgis.com/datasets/usdot::airports-1/about"
                        target="_blank"
                        color="primary"
                        className={classes.cardActions}
                      >
                        <InfoIcon />
                        More Information - Bureau of Transportation Statistics
                        (BTS) Open Data Catalog
                      </Button>
                    </CardActions>{" "}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>{" "}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
