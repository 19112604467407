import React, { useState, useContext, useEffect } from "react";

import { lighten, withStyles, makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
//import Icon from '@material-ui/core/Icon';
import Paper from "@material-ui/core/Paper";

//import Image from 'material-ui-image';

import { RhinoQueryContext } from "../map/RhinoQueryContext";

// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
// } from "@material-ui/core";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

import Tooltip from "@material-ui/core/Tooltip";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";

import InfoIcon from "@material-ui/icons/Info";

import { Grid } from "@material-ui/core/";
import Divider from "@material-ui/core/Divider";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";

import YearSelection from "../map/rhinoYearSelection";

import RoadSegmentHue from "../map/roadSegmentHue";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  card: {
    borderRadius: 0,
    // backgroundColor: theme.palette.primary.light,
    // color: theme.palette.primary.contrastText,
    boxShadow: "none",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  cardcontent: {
    paddingTop: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  cardActions: {
    color: "#5945cc",
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
    marginTop: 5,
    marginLeft: 20,
  },
  formLabel2: {
    paddingLeft: "25px",
  },
  note: {
    textAlign: "left",
    // marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function OtherMapLayers(props) {
  const classes = useStyles();
  const [query, dispatch] = useContext(RhinoQueryContext);
  const {
    mapType,
    setMapType,
    // deckglMapType,
    // setDeckglMapType,
    roadGeomFeatures,
    year,
    setYear,
  } = props;
  const [
    deckGlMapTypeOptionsEnabled,
    setDeckGlMapTypeOptionsEnabled,
  ] = useState(true);

  const handleChangeMapType = (event) => {
    setMapType(event.target.value);
    if (event.target.value === "leaflet") setDeckGlMapTypeOptionsEnabled(true);
    else setDeckGlMapTypeOptionsEnabled(false);
  };
  const handleChangeDeckglMapType = (event) => {
    // setDeckglMapType(event.target.value);
    setMapType(event.target.value);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper + " table-wrap"}>
        <Grid
          container
          spacing={1}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          borderColor="red"
        >
          <Grid item xs={3} key={"settings2-map-type"}>
            {/* <Typography variant="h5" component="h6" className={classes.title}>
              Map Type
            </Typography> */}
            <Card classes={{ root: classes.card }}>
              <CardHeader
                title="Map Type"
                subheader="(Layers with > 10,000 features default to Deck.GL.)"
                style={{ marginBottom: "none" }}
              />
              <CardContent className={classes.cardcontent}>
                <FormGroup className={classes.formGroup}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="controlled-radio-buttons-group-map-type"
                      value={mapType}
                      onChange={handleChangeMapType}
                      className={classes.formLabel}
                    >
                      <FormControlLabel
                        // value="mapbox://styles/mapbox/streets-v11"
                        value={"leaflet"}
                        control={<Radio size="small" />}
                        label={"Leaflet Map (2-D)"}
                        key={`map-type-leaflet`}
                        className={classes.formLabel}
                      />
                      {/* <FormControlLabel
                        // value="mapbox://styles/mapbox/streets-v11"
                        value={"deckGL-lines"}
                        control={<Radio size="small" />}
                        label={"Deck.GL (3-D)"}
                        key={`map-type-deckGL`}
                        className={classes.formLabel}
                      /> */}
                      {/* <FormLabel component="legend">Deck.GL (3-D)</FormLabel> */}
                      <FormLabel>Deck.GL (3-D)</FormLabel>
                      <FormControlLabel
                        // value="mapbox://styles/mapbox/streets-v11"
                        value={"deckGL-lines"}
                        control={<Radio size="small" />}
                        label={"Roadways as Lines"}
                        key={`map-type-deckGL-lines`}
                        className={classes.formLabel2}
                        // disabled={deckGlMapTypeOptionsEnabled}
                      />
                      <FormControlLabel
                        // value="mapbox://styles/mapbox/streets-v11"
                        value={"deckGL-polygons"}
                        control={<Radio size="small" />}
                        label={"Roads as polygons"}
                        key={`map-type-deckGL-polygons`}
                        className={classes.formLabel2}
                        // disabled={deckGlMapTypeOptionsEnabled}
                      />
                    </RadioGroup>
                    {/* <RadioGroup
                      aria-label="gender"
                      name="controlled-radio-buttons-group-deckgl-type"
                      value={mapType}
                      onChange={handleChangeDeckglMapType}
                      className={classes.formLabel}
                    >
                      <FormControlLabel
                        // value="mapbox://styles/mapbox/streets-v11"
                        value={"deckGL-lines"}
                        control={<Radio size="small" />}
                        label={"Roadways as Lines"}
                        key={`map-type-deckGL-lines`}
                        className={classes.formLabel2}
                        disabled={deckGlMapTypeOptionsEnabled}
                      />
                      <FormControlLabel
                        // value="mapbox://styles/mapbox/streets-v11"
                        value={"deckGL-polygons"}
                        control={<Radio size="small" />}
                        label={"Roads as polygons"}
                        key={`map-type-deckGL-polygons`}
                        className={classes.formLabel2}
                        disabled={deckGlMapTypeOptionsEnabled}
                      />
                    </RadioGroup>{" "} */}
                    <div className={classes.footer}>
                      <Typography>
                        Tilt and rotation for Deck.gl map are changed by holding
                        down the right mouse button and moving left/right
                        (rotate) or up/down (tilt).{" "}
                      </Typography>
                    </div>
                  </FormControl>
                </FormGroup>
              </CardContent>
            </Card>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            style={{
              marginTop: "10px",
              marginLeft: "-10px",
              marginRight: "0px",
            }}
          />
          <Grid item xs={3} key={"settings-map-type"}>
            <YearSelection year={year} setYear={setYear} />
            {/* <Paper className={classes.paper + " table-null"}> */}
            <Typography variant="subtitle1" className={classes.note}>
              <strong>Note:</strong> 2021 reporting year (2020 data) was
              impacted by the COVID-19 pandemic and is not reflective of a
              typical year.
            </Typography>
            {/* </Paper> */}
            <RoadSegmentHue
              // type={props.type}
              type={roadGeomFeatures.type}
              numFeatures={roadGeomFeatures.numFeatures}
              minValue={roadGeomFeatures.minValue}
              maxValue={roadGeomFeatures.maxValue}
              mode={props.mode}
            />
          </Grid>{" "}
        </Grid>
      </Paper>
    </div>
  );
}
