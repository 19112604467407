import React, { useState, useContext, useEffect } from "react";

import {
  SUMMARY_VARIABLES_RHINO,
  databaseVariablesHPMS,
  SUMMARY_VARIABLES_TX100,
  SUMMARY_VARIABLES_ROUTE_100,
  SUMMARY_VARIABLES_ROUTE,
  tx100_plot_vars,
} from "../common/constants";

import { RhinoQueryContext } from "../map/RhinoQueryContext";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import TableColumnSelector from "./TableColumnSelector";

import MaterialTable from "@material-table/core";

import Button from "@material-ui/core/Button";

import axios from "axios";

import { API_ROOT } from "../common/constants";

/* plotly.js has an memory issue with node 10 and 11.
 * We can't use Plot from plotly.js or plotly.js-dist.
 * see https://github.com/plotly/react-plotly.js/issues/135 */
import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";

import { render } from "react-dom";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";

import SaveAlt from "@material-ui/icons/SaveAlt";

import ExportFileNameDialog from "./ExportFileNameDialog";

HC_exporting(Highcharts);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

export default function RoadSegmentTable(props) {
  const classes = useStyles();
  const keyField = "label";
  const [query, dispatch] = useContext(RhinoQueryContext);
  const {
    year,
    statusGuide,
    roads,
    setRoads,
    roadsID,
    setRoadsID,
    roadsData,
    setRoadsData,
    rows,
    setRows,
  } = props;

  const Plot = createPlotlyComponent(Plotly);

  const [roadLayerType, setRoadLayerType] = useState(statusGuide[0]);

  const [selectedRows, setSelectedRows] = useState([]);

  const [summary, setSummary] = useState([]);

  useEffect(() => {
    setRoadLayerType(statusGuide[0]);
  }, [statusGuide[0]]);

  const getRoad = (roadLabel) => {
    return roads.find((r) => r.label === roadLabel);
  };

  const isRoadSelected = (selectedRows, feature) => {
    return selectedRows.some(function(rowIndex) {
      const road = getRoad(rowIndex);
      if (road.top100id) {
        //if (roadLayerType === "TX100") {
        return (
          // feature.properties.road === road.road &&
          // feature.properties.from_road === road.from_road &&
          // feature.properties.to_road === road.to_road
          // //feature.properties.top100id === road.top100id

          (feature.rte_id === road.top100id &&
            feature.frm_dfo === road.from_road &&
            feature.to_dfo === road.to_road) ||
          (feature.top100id === road.top100id &&
            feature.from_road === road.from_road &&
            feature.to_road === road.to_road)
        );
      } else {
        return (
          // feature.rte_id === road.ria_rte_id &&
          (feature.ria_rte_id === road.ria_rte_id ||
            feature.rte_id === road.ria_rte_id) &&
          feature.frm_dfo >= road.frm_dfo &&
          feature.to_dfo <= road.to_dfo
        );
      }
    });
  };

  // const updateSelectedRows = (rows) => {
  //   dispatch({
  //     type: "setHighlightQuery",
  //     query: isRoadSelected.bind(null, rows),
  //   });
  // };
  function updateSelectedRows(rows) {
    return dispatch({
      type: "setHighlightQuery",
      query: isRoadSelected.bind(null, rows),
    });
  }

  const fetchSummary = async (roads, keyField) => {
    try {
      if (roadLayerType !== "TX100") {
        let years = [2017, 2018, 2019, 2020, 2021, 2022, 2023];
        let yearroad = [];
        for (var j = 0; j < years.length; j++) {
          yearroad.push([years[j], roads]);
        }
        let promises = yearroad.map((r) => {
          return axios.get(
            `${API_ROOT}/metrics/findByRouteIdYear?year=${r[0]}&route_id=${r[1].ria_rte_id}&min_dfo=${r[1].frm_dfo}&max_dfo=${r[1].to_dfo}`
          );
        });
        axios.all(promises).then((responses) => {
          // console.log("roads.id-check", roads.id);
          let label = 1;
          let id = roads.id;
          let fields = SUMMARY_VARIABLES_RHINO.map((v) => {
            return v.variable;
          });
          let temp = {};
          temp[keyField] = label;
          temp.id = roads.id;
          for (var i = 0; i < years.length; i++) {
            if (years[i] === props.year) {
              temp = {
                ...temp,
                ...responses[(label - 1) * years.length + i].data,
              };
            }
            for (var j = 0; j < fields.length; j++) {
              temp[fields[j].concat(years[i])] =
                responses[(label - 1) * years.length + i].data[fields[j]];
            }
          }
          ++label;

          let summaryTemp = summary;
          summaryTemp.push(temp);
          setSummary(summaryTemp);
        });
      } else {
        let promise = `${API_ROOT}/metric/getTX100Rankings?roadid=${roads.top100id}`;
        let response = await axios.get(promise);
        // console.log("response-check", response);
        setSummary([response.data, ...summary]);
        // let promises = roads.map((r) => {
        //   return axios.get(
        //     `${API_ROOT}/metric/getTX100Rankings?roadid=${r.top100id}`
        //   );
        // });
        // axios.all(promises).then((responses) => {
        //   let label = 1;
        //   const reducer = (acc, curr, index) => {
        //     let currData = { ...curr.data };
        //     currData[keyField] = label;
        //     ++label;
        //     return [...acc, currData];
        //   };
        //   const data = responses.reduce(reducer, []);
        //   setSummary(data);
        // });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (
      !isNaN(query.userSelection.to_dfo) ||
      query.userSelectionTX100.top100id !== ""
    ) {
      let newRoads = [...roads];
      let newSelection = [];
      if (!isNaN(query.userSelection.to_dfo)) {
        let add = false;
        newSelection = query.userSelection;

        if (roads.length === 0) {
          setRoadsID(roadsID + 1);
          newSelection[keyField] = roadsID;
          newSelection.id = roadsID;
          newRoads.push(newSelection);
          setRoads(newRoads);

          fetchSummary(newSelection, keyField);

          add = true;
        } else {
          if (
            roads.filter(
              (f) =>
                f.ria_rte_id === newSelection.ria_rte_id &&
                f.frm_dfo === newSelection.frm_dfo &&
                f.to_dfo === newSelection.to_dfo
            ).length === 0
          ) {
            add = true;
          }

          if (add) {
            setRoadsID(roadsID + 1);
            newSelection[keyField] = roadsID;
            newSelection.id = roadsID;
            newRoads.push(newSelection);
            setRoads(newRoads);

            fetchSummary(newSelection, keyField);
          }
        }

        const roadsDataTemp = [...roadsData];
        if (add) {
          for (var ii = newRoads.length - 1; ii >= 0; ii--) {
            let roadsDataFilter = roadsData.filter(
              (obj) =>
                obj.ria_rte_id === newRoads[ii].ria_rte_id &&
                obj.frm_dfo === newRoads[ii].frm_dfo &&
                obj.to_dfo === newRoads[ii].to_dfo
            );
            if (roadsDataFilter.length === 0) {
              let my_filter = query.features.filter(
                (obj) =>
                  obj.ria_rte_id === newRoads[ii].ria_rte_id &&
                  obj.frm_dfo >= newRoads[ii].frm_dfo &&
                  obj.to_dfo <= newRoads[ii].to_dfo
              );

              //Calculate sums for corridor
              let totalNames = [];
              databaseVariablesHPMS
                .filter((obj) => obj.condition === "sum")
                .map((slt) => totalNames.push([slt.variable]));
              let statsTotals = sumNames(my_filter, totalNames);

              // Calculate weighted values
              let weightValues = [
                "trkttivmt",
                "trkttivmt1",
                "trkttivmt2",
                "ttivmt",
                "ttivmt1",
                "ttivmt2",
              ];
              let weightValuesTotals = sumNames(my_filter, weightValues);

              for (var jjj = weightValues.length - 1; jjj >= 0; jjj--) {
                totalNames = [];
                databaseVariablesHPMS
                  .filter(
                    (obj) =>
                      obj.condition === "normalized" &&
                      obj.normalizedBy === weightValues[jjj]
                  )
                  .map((slt) => totalNames.push([slt.variable]));

                let statsTotalsTemp = weightedSumByNames(
                  my_filter,
                  totalNames,
                  weightValues[jjj]
                );

                for (var iiii = totalNames.length - 1; iiii >= 0; iiii--) {
                  statsTotals[totalNames[iiii][0]] =
                    statsTotalsTemp[totalNames[iiii][0]] /
                    weightValuesTotals[weightValues[jjj]];
                }
              }

              roadsDataTemp.push({
                ria_rte_id: newRoads[ii].ria_rte_id,
                frm_dfo: newRoads[ii].frm_dfo,
                to_dfo: newRoads[ii].to_dfo,
                id: newRoads[ii].id,
                trkvolper: (statsTotals.trkaadt / statsTotals.aadt) * 100,
                ...statsTotals,
              });
            }
          }
        }
        setRoadsData(roadsDataTemp);
      } else {
        let add = false;
        newSelection = query.userSelectionTX100;
        let roadsIDtemp = roadsID;
        if (roads.length === 0) {
          setRoadsID(roadsIDtemp + 1);
          newSelection[keyField] = roadsIDtemp + 1;
          newSelection.id = roadsIDtemp + 1;
          newRoads.push(newSelection);
          setRoads(newRoads);

          fetchSummary(newSelection, keyField);

          add = true;
        } else {
          if (
            roads.filter(
              (f) =>
                // f.ria_rte_id === newSelection.ria_rte_id &&
                // f.frm_dfo === newSelection.frm_dfo &&
                // f.to_dfo === newSelection.to_dfo
                f.top100id === newSelection.top100id
            ).length === 0
          ) {
            add = true;
          }

          if (add) {
            setRoadsID(roadsIDtemp + 1);
            newSelection[keyField] = roadsIDtemp + 1;
            newSelection.id = roadsIDtemp + 1;
            newRoads.push(newSelection);
            setRoads(newRoads);

            fetchSummary(newSelection, keyField);
          }
        }

        const roadsDataTemp = [...roadsData];
        if (add) {
          let queryRow = query.features.filter(
            (obj) =>
              //   obj.seg_name === newSelection.ria_rte_id &&
              //   obj.seg_from === newSelection.frm_dfo &&
              //   obj.seg_to === newSelection.to_dfo
              obj.top100id === newSelection.top100id
          )[0];
          queryRow.roads_id = roadsIDtemp + 1;
          queryRow.id = newSelection.id;
          roadsDataTemp.push({
            ria_rte_id: newSelection.road,
            frm_dfo: newSelection.from_road,
            to_dfo: newSelection.to_road,
            top100id: newSelection.top100id,
            id: newSelection.id,
            trkvolper: (queryRow.trkaadt / queryRow.aadt) * 100,
            tableData: {
              checked: false,
            },
            ...queryRow,
          });
        }
        setRoadsData(roadsDataTemp);
      }
    }
  }, [query.userSelection, query.userSelectionTX100]);

  function filterHPMSVariables(category) {
    let results = [];
    let SUMMARY_VARIABLES_layer;
    if (roadLayerType !== "TX100") {
      SUMMARY_VARIABLES_layer = SUMMARY_VARIABLES_RHINO;
    } else {
      SUMMARY_VARIABLES_layer = SUMMARY_VARIABLES_TX100;
    }

    for (var i = SUMMARY_VARIABLES_layer.length - 1; i >= 0; i--) {
      if (category === SUMMARY_VARIABLES_layer[i].category) {
        results.push(SUMMARY_VARIABLES_layer[i]);
      }
    }

    return results;
  }

  const [headCells, setHeadCells] = React.useState([]);

  function buildCols() {
    let cols = [];
    let colVars = [];
    // let colVars = SUMMARY_VARIABLES_ROUTE;
    // if (roadLayerType === "TX100") colVars = SUMMARY_VARIABLES_ROUTE_100;
    // for (var i = colVars.length - 1; i >= 0; i--) {
    //   cols.push(colVars[i]);
    // }

    colVars = filterHPMSVariables("all");
    for (var i = colVars.length - 1; i >= 0; i--) {
      cols.push({
        id: colVars[i].variable,
        numeric: true,
        disablePadding: false,
        label: colVars[i].summaryName,
        tooltip: colVars[i].tooltip,
        checked: false,
        category: colVars[i].category,
      });
    }

    colVars = filterHPMSVariables("truck");
    for (var i = colVars.length - 1; i >= 0; i--) {
      cols.push({
        id: colVars[i].variable,
        numeric: true,
        disablePadding: false,
        label: colVars[i].summaryName,
        tooltip: colVars[i].tooltip,
        checked: true,
        category: colVars[i].category,
      });
    }

    return cols;
  }
  let columns = buildCols();

  function checkExists(rows, newRow) {
    for (var i = rows.length - 1; i >= 0; i--) {
      if (rows[i].id === newRow.id) {
        return true;
      }
    }

    return false;
  }

  const sumByName = (data, name) => {
    return data.reduce(function(a, b) {
      return a + b[name];
    }, 0);
  };

  const sumNames = (data, totalNames) => {
    let total = {};

    totalNames.forEach((name) => {
      total[name] = sumByName(data, name);
    });
    return total;
  };

  const weightedSumByName = (data, name, weightString) => {
    return data.reduce(function(a, b) {
      return a + b[name] * b[weightString];
    }, 0);
  };

  const weightedSumByNames = (data, totalNames, weightString) => {
    let total = {};

    totalNames.forEach((name) => {
      total[name] = weightedSumByName(data, name, weightString);
    });
    return total;
  };

  // function buildColsState(cols, category, state) {
  //   columns
  //     .filter((item) => item.category === category)
  //     .map(
  //       (col, index) =>
  //         (cols = {
  //           ...cols,
  //           [col.id]: state === "keep" ? columnsState[col.id] : state,
  //         })
  //     );

  //   return cols;
  // }

  // const [columnsState, setColumnsState] = useState(query.tableColumns);
  // useEffect(() => {
  //   let cols = {};

  //   cols = buildColsState(cols, "route", true);
  //   cols = buildColsState(cols, "all", false);
  //   cols = buildColsState(cols, "truck", true);

  //   dispatch({
  //     type: "setTableColumns",
  //     selection: cols,
  //   });

  //   setSelectedRows([]);
  // }, [roadLayerType]);
  useEffect(() => {
    // setColumnsState(query.tableColumns);

    let SUMMARY_VARIABLES_layer = SUMMARY_VARIABLES_RHINO;
    if (roadLayerType === "TX100") {
      SUMMARY_VARIABLES_layer = SUMMARY_VARIABLES_layer;
    }

    SUMMARY_VARIABLES_layer.map(
      (svar) =>
        // colsTemp.push(
        (svar.status =
          query.tableColumns[svar.variable] === true ? "keep" : "drop")
      // )
    );
    let colsTemp = SUMMARY_VARIABLES_layer.filter(
      (svar) => svar.status === "keep"
    );
    setHeadCells(colsTemp);
  }, [query.tableColumns]);

  function buildColsNew(type) {
    let cols = [];
    let columnsTemp = columns;

    if (type === "detailed_panel" || roadLayerType === "TX100") {
      columnsTemp = columnsTemp.filter(
        (headCell) => headCell.id !== "ria_rte_id" //(headCell) => headCell.id !== "ria_rte_id"
      );
    }

    let SUMMARY_VARIABLES_ROUTE_Temp = SUMMARY_VARIABLES_ROUTE;
    if (roadLayerType === "TX100")
      SUMMARY_VARIABLES_ROUTE_Temp = SUMMARY_VARIABLES_ROUTE_100;
    // for (var i = colVars.length - 1; i >= 0; i--) {
    //   cols.push(colVars[i]);
    // }
    SUMMARY_VARIABLES_ROUTE_Temp.map((obj) => {
      cols.push({
        title: obj.label,
        field: obj.id,
        cellStyle: {
          borderTopWidth: 0,
          borderBottomWidth: 1,
          borderLeftWidth: 0,
          borderRightWidth: 2,
          borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
          borderStyle: "solid",
        },
        headerStyle: {
          borderTopWidth: 0,
          borderBottomWidth: 1,
          borderLeftWidth: 0,
          borderRightWidth: 2,
          borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
          borderStyle: "solid",
        },
        tooltip: obj.tooltip,
        tooltipStyle: {
          fontSize: 12,
        },
        render: (rowData) =>
          rowData[obj.id] !== null &&
          typeof rowData[obj.id] !== "undefined" &&
          rowData[obj.id] !== "undefined"
            ? rowData[obj.id].toLocaleString("en-US", obj.formatOptions)
            : "",
      });
    });

    columnsTemp.map(
      (headCell) =>
        query.tableColumns[headCell.id] &&
        cols.push({
          title: headCell.label,
          field: headCell.id,
          cellStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          headerStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          tooltip: headCell.tooltip,
          tooltipStyle: {
            fontSize: 12,
          },
          render: (rowData) =>
            rowData[headCell.id] !== null &&
            typeof rowData[headCell.id] !== "undefined" &&
            rowData[headCell.id] !== "undefined"
              ? rowData[headCell.id].toLocaleString(
                  "en-US",
                  headCell.formatOptions
                )
              : "",
        })
    );

    return cols;
  }

  const handleRemoveSelected = (data) => {
    let roadsTemp = roads;
    let roadsDataTemp = roadsData;

    if (roadLayerType === "TX100") {
      for (var ii = data.length - 1; ii >= 0; ii--) {
        let selectedRoad = roads.filter(
          (match) =>
            match.road === data[ii].road &&
            match.from_road === data[ii].from_road &&
            match.to_road === data[ii].to_road
        );
        let selectedRoadID = selectedRoad[0].id;

        roadsTemp = roadsTemp.filter((match) => match.id !== selectedRoadID);
        roadsDataTemp = roadsDataTemp.filter(
          (match) => match.id !== selectedRoadID
        );
      }
    } else {
      for (var ii = data.length - 1; ii >= 0; ii--) {
        let selectedRoad = roads.filter(
          (match) =>
            match.ria_rte_id === data[ii].ria_rte_id &&
            match.frm_dfo === data[ii].frm_dfo &&
            match.to_dfo === data[ii].to_dfo
        );
        let selectedRoadID = selectedRoad[0].id;

        roadsTemp = roadsTemp.filter((match) => match.id !== selectedRoadID);
        roadsDataTemp = roadsDataTemp.filter(
          (match) => match.id !== selectedRoadID
        );
      }
    }

    setRoads(roadsTemp);
    setRoadsData(roadsDataTemp);
    updateSelectedRows([]);
    setSelectedRows([]);
  };

  const handleClick = (data) => {
    setSelectedRows(data);

    if (data.length > 0) {
      let roadsDataTemp = roadsData.map((row) =>
        data.find((selected) => selected.id === row.id)
          ? {
              ...row,
              tableData: {
                checked: true,
              },
            }
          : {
              ...row,
              tableData: {
                checked: false,
              },
            }
      );
      setRoadsData(roadsDataTemp);

      const newSelecteds = data.map((n) => n.id);
      updateSelectedRows(newSelecteds);

      return;
    }

    let roadsDataTemp = roadsData.map((row) =>
      selectedRows.find((selected) => selected.id === row.id)
        ? {
            ...row,
            tableData: {
              checked: !row.tableData.checked,
            },
          }
        : row
    );
    setRoadsData(roadsDataTemp);

    updateSelectedRows([]);
  };

  //set data for Texas 100 plots
  let tx_100_plot_years = [];
  tx100_plot_vars.forEach((item, i) => tx_100_plot_years.push(item.year));
  // console.log("tx_100_plot_years-check", tx_100_plot_years);
  function setTx100PlotData(type, row) {
    // console.log("row-check", row);
    let yData = [];
    //tx100_plot_vars.forEach((item, i) => yData.push(row[item.trank]));
    tx100_plot_vars.forEach((item, i) => yData.push(row[item[type]]));
    return yData;
  }

  const [TX100chartOptions, setTX100ChartOptions] = useState({
    title: {
      text: "",
    },
    chart: {
      type: "line",
      margin: [60, 130, 70, 70],
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      x: 15,
      y: 30,
    },
    xAxis: {
      categories: tx_100_plot_years,
      title: {
        text:
          "Congestion Reporting Year (data is for prior year)" +
          "<br />" +
          "2021 reporting year (2020 data) was impacted by the COVID-19 pandemic and is not reflective of a typical year.",
      },
    },
    yAxis: {
      title: { text: "Ranking" },
      reversed: true,
    },
    credits: {
      enabled: false,
    },
  });

  const [RhinochartOptions, setRhinoChartOptions] = useState({
    title: {
      text: "",
    },
    chart: {
      type: "line",
      margin: [60, 240, 70, 70],
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      x: 15,
      y: 30,
    },
    xAxis: {
      categories: ["2017", "2018", "2019", "2020", "2021", "2022", "2023"],
      title: {
        text:
          "Congestion Reporting Year (data is for prior year)" +
          "<br />" +
          "2021 reporting year (2020 data) was impacted by the COVID-19 pandemic and is not reflective of a typical year.",
      },
    },
    credits: {
      enabled: false,
    },
  });

  //Clear out selections as roads layer changes
  useEffect(() => {
    //handleRemoveSelected(roadsData);
    updateSelectedRows([]);
    setSelectedRows([]);
  }, [statusGuide]);

  const materialTableDetailPanel = (data) => {
    if (roadLayerType === "TX100") {
      // console.log("data-check", data);
      let row = summary.find((sline) => sline.top100id === data.top100id);
      // console.log("row-check", row, summary, data);
      return (
        <div
          style={{
            fontSize: 14,
            textAlign: "center",
            color: "white",
            backgroundColor: "#FFFFFF",
            marginLeft: 10,
            marginBottom: 10,
            marginTop: 0,
            borderTopWidth: 0,
          }}
        >
          <HighchartsReact
            containerProps={{
              style: { width: "90%" },
            }}
            highcharts={Highcharts}
            options={Object.assign({}, TX100chartOptions, {
              series: [
                {
                  name: "Truck ranking",
                  data: setTx100PlotData("trank", row),
                  color: "rgb(80,0,0)",
                },
                {
                  name: "Overall ranking",
                  data: setTx100PlotData("arank", row),
                  color: "rgb(112,115,115)",
                },
              ],
              title: { text: data.road },
              subtitle: {
                text: data.frm_dfo + " to " + data.to_dfo,
              },
              legend: {
                enabled: true,
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                itemMarginTop: 2,
                itemMarginTop: 2,
                itemMarginLeft: 2,
                symbolWidth: 20,
                itemStyle: {
                  fontSize: "10px",
                },
              },
              exporting: {
                sourceWidth: 1000,
                sourceHeight: 400,
                scale: 1,
              },
            })}
          />
        </div>
      );
    } else {
      let row = summary.filter((sline) => sline.id === data.id);
      return (
        <div
          style={{
            fontSize: 14,
            textAlign: "center",
            color: "white",
            backgroundColor: "#FFFFFF",
            marginLeft: 10,
            marginBottom: 10,
            marginTop: 0,
            borderTopWidth: 0,
          }}
        >
          <HighchartsReact
            containerProps={{ style: { width: "90%" } }}
            highcharts={Highcharts}
            options={Object.assign({}, RhinochartOptions, {
              series: headCells.map((v) => {
                return {
                  name: v.summaryName,
                  data: [
                    row[0][v.variable + (props.year === 2017 ? "" : "2017")],
                    row[0][v.variable + (props.year === 2018 ? "" : "2018")],
                    row[0][v.variable + (props.year === 2019 ? "" : "2019")],
                    row[0][v.variable + (props.year === 2020 ? "" : "2020")],
                    row[0][v.variable + (props.year === 2021 ? "" : "2021")],
                    row[0][v.variable + (props.year === 2022 ? "" : "2022")],
                    row[0][v.variable + (props.year === 2023 ? "" : "2023")],
                  ],
                };
              }),
              title: { text: data.ria_rte_id },
              subtitle: {
                text: "From DFO " + data.frm_dfo + " to To DFO " + data.to_dfo,
              },
              legend: {
                enabled: true,
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                itemMarginTop: 2,
                itemMarginTop: 2,
                itemMarginLeft: 2,
                symbolWidth: 20,
                itemStyle: {
                  fontSize: "10px",
                  textOverflow: "ellipsis",
                },
              },
              exporting: {
                sourceWidth: 1000,
                sourceHeight: 400,
                scale: 1,
              },
            })}
          />
        </div>
      );
    }
  };

  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [csvFileNameTest, setCsvFileNameTest] = useState("myTable");
  const [exportCols, setExportCols] = useState();
  const [exportData, setExportData] = useState();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper + " table-null"}>
        <Typography variant="subtitle1">
          <strong>Note:</strong> 2021 reporting year (2020 data) was impacted by
          the COVID-19 pandemic and is not reflective of a typical year.
        </Typography>
      </Paper>
      {roadsData.length > 0 && (
        <Paper className={classes.paper + " table-wrap " + roadLayerType}>
          <MaterialTable
            title=""
            columns={buildColsNew("full_table")}
            data={roadsData}
            options={{
              exportButton: true,
              selection: true,
              exportAllData: true,
              exportMenu: [
                // {
                //   label: "Export PDF",
                //   exportFunc: (cols, datas) =>
                //     ExportPdf(cols, datas, "myPdfFileName"),
                // },
                // {
                //   label: "Export CSV",
                //   exportFunc: (cols, datas) =>
                //     ExportCsv(cols, datas, "myCsvFileName"),
                // },
                {
                  label: "Export Table",
                  exportFunc: (cols, data) => {
                    setOpenExportDialog(true);
                    setExportCols(cols);
                    setExportData(data);
                  },
                },
              ],
              toolbarButtonAlignment:
                selectedRows.length > 0 ? "left" : "right", // here is the option to change toolbar buttons' alignment
            }}
            icons={{
              Export: () => (
                // <div className={classes.exportButtonDiv}>
                <SaveAlt fontSize="medium" className={classes.exportButton} />
                // </div>
              ),
            }}
            actions={[
              {
                tooltip: "Remove Selected Roads",
                icon: "delete",
                onClick: (evt, data) => handleRemoveSelected(data),
              },
              {
                tooltip: "",
                icon: () => {
                  return (
                    <div>
                      <TableColumnSelector columns={columns} />
                    </div>
                  );
                },
                onClick: () => {},
                isFreeAction: true,
              },
              {
                tooltip: "",
                icon: () => {
                  return (
                    <div>
                      <Button variant="contained" color="secondary">
                        Clear Selection
                      </Button>
                    </div>
                  );
                },
                onClick: () => {
                  let roadsDataTemp = roadsData.map((row) =>
                    selectedRows.find((selected) => selected.id === row.id)
                      ? {
                          ...row,
                          tableData: {
                            checked: false,
                          },
                        }
                      : row
                  );
                  setRoadsData(roadsDataTemp);

                  updateSelectedRows([]);
                  setSelectedRows([]);
                },
                isFreeAction: false,
              },
            ]}
            onSelectionChange={(rows) => handleClick(rows)}
            detailPanel={[
              {
                icon: "timeline",
                tooltip: "Show Timeline",
                render: (rowLine) => {
                  let rowData = rowLine.rowData;
                  return (
                    <div
                      style={{
                        fontSize: 100,
                        textAlign: "center",
                        color: "white",
                        backgroundColor: "#43A047",
                      }}
                    >
                      {materialTableDetailPanel(rowData)}
                    </div>
                  );
                },
              },
            ]}
          />
          <ExportFileNameDialog
            openExportDialog={openExportDialog}
            setOpenExportDialog={setOpenExportDialog}
            csvFileNameTest={csvFileNameTest}
            setCsvFileNameTest={setCsvFileNameTest}
            exportCols={exportCols}
            exportData={exportData}
          />
        </Paper>
      )}
      {roadsData.length === 0 && (
        <Paper className={classes.paper + " table-null"}>
          <Typography variant="subtitle1">
            Please select a segment or custom corridor using the map above.
          </Typography>
        </Paper>
      )}
    </div>
  );
}
