import React, { useState, useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

// import RoadSegmentTable from "./RoadSegmentTable";
// import SummaryTable from "./SummaryTable";

import OtherMapLayers from "../map/OtherMapLayers.js";

import { RhinoQueryContext } from "../map/RhinoQueryContext";

// import RoadSegmentTable from "./RoadSegmentTable.js";
// import TX100RoadSegmentTable from "./TX100RoadSegmentTable.js";

import RoadSegmentTableMui from "./RoadSegmentTable-mui.js";
import MonitoredRoadsTable from "./MonitoredRoadsTable.js";

// import TableWrapper from "../components/table-wrapper";
//import TableControl from "./components/table-control";
// import TableSummary from "../components/table-summary";

import CustomSummary from "./CustomSummary.js";

import SummaryTable from "./SummaryTable";

import SettingsTab from "../tabs/SettingsTab";

import {
  SUMMARY_VARIABLES_RHINO,
  SUMMARY_VARIABLES_ROUTE,
} from "../common/constants";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`data-tabpanel-${index}`}
      aria-labelledby={`data-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `data-tab-${index}`,
    "aria-controls": `data-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    backgroundColor: "#CC7B29",
  },
  appBarText: {
    fontSize: "1.00rem",
    color: "#FFFFFF",
  },
}));

export default function DataTabs(props) {
  const [query, dispatch] = useContext(RhinoQueryContext);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const {
    theme,
    layerID,
    year,
    setYear,
    statusGuide,
    roadSegmentTable,
    mapType,
    setMapType,
    // deckglMapType,
    // setDeckglMapType,
    roadGeomFeatures,
    setRoadGeomFeatures,
    monitoredRoads,
    monitoredRoadsData,
  } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [rows, setRows] = useState([]);
  const [roads, setRoads] = useState([]);
  const [roadsID, setRoadsID] = useState(0);
  const [roadsData, setRoadsData] = useState([]);

  useEffect(() => {
    dispatch({
      type: "setUserSelection",
      selection: {
        ria_rte_id: "",
        frm_dfo: NaN,
        to_dfo: NaN,
      },
    });

    dispatch({
      type: "setUserSelectionTX100",
      selection: {
        top100id: "",
        from_road: NaN,
        to_road: "",
        road: "",
      },
    });

    setRoads([]);
    setRoadsID(0);
    setRoadsData([]);
    setRows([]);

    setValue(0);
  }, [statusGuide]);
  //}, [statusGuide[0]]);

  // const [isTx100, setIsTx100] = useState(true);
  // useEffect(() => {
  //   if (statusGuide[0] !== "TX100") {
  //     setIsTx100(false);
  //   } else {
  //     setIsTx100(true);
  //   }
  // }, [statusGuide]);

  const [utpLayersChecked, setutpLayersChecked] = useState({
    underwayorsoon: false,
    within4years: false,
    fivetotenyears: false,
    tenplusyears: false,
  });

  const [contextLayersChecked, setContextLayersChecked] = useState({
    countyBoundaries: false,
    districtBoundaries: false,
    odessaDistSandMines: false,
    disposalWells: false,
    commercialBorderCrossings: false,
    nonCommercialBorderCrossings: false,
    railBorderCrossings: false,
    portsLayer: false,
    cargoAirports: false,
    airports: false,
    truckParkingLayer: false,
  });

  function filterHPMSVariables(category) {
    let results = [];
    let SUMMARY_VARIABLES_layer;
    SUMMARY_VARIABLES_layer = SUMMARY_VARIABLES_RHINO;

    for (var i = SUMMARY_VARIABLES_layer.length - 1; i >= 0; i--) {
      if (category === SUMMARY_VARIABLES_layer[i].category) {
        results.push(SUMMARY_VARIABLES_layer[i]);
      }
    }

    return results;
  }

  function buildCols() {
    let cols = [];
    let colVars = [];
    // let colVars = SUMMARY_VARIABLES_ROUTE;
    // // if (roadLayerType === "TX100") colVars = SUMMARY_VARIABLES_ROUTE_100;
    // for (var i = colVars.length - 1; i >= 0; i--) {
    //   cols.push(colVars[i]);
    // }

    colVars = filterHPMSVariables("all");
    for (var i = colVars.length - 1; i >= 0; i--) {
      cols.push({
        id: colVars[i].variable,
        numeric: true,
        disablePadding: false,
        label: colVars[i].summaryName,
        tooltip: colVars[i].tooltip,
        checked: false,
        category: colVars[i].category,
      });
    }

    colVars = filterHPMSVariables("truck");
    for (var i = colVars.length - 1; i >= 0; i--) {
      cols.push({
        id: colVars[i].variable,
        numeric: true,
        disablePadding: false,
        label: colVars[i].summaryName,
        tooltip: colVars[i].tooltip,
        checked: true,
        category: colVars[i].category,
      });
    }

    return cols;
  }
  let columns = buildCols();
  function buildColsState(cols, category, state) {
    columns
      .filter((item) => item.category === category)
      .map(
        (col, index) =>
          (cols = {
            ...cols,
            [col.id]: state === "keep" ? columnsState[col.id] : state,
          })
      );

    return cols;
  }

  // const [columnsState, setColumnsState] = useState(query.tableColumns);
  const [columnsState, setColumnsState] = useState(query.tableColumns);
  useEffect(() => {
    let cols = {};

    cols = buildColsState(cols, "route", true);
    cols = buildColsState(cols, "all", false);
    cols = buildColsState(cols, "truck", true);

    dispatch({
      type: "setTableColumns",
      selection: cols,
    });
  }, [statusGuide[0]]);

  return (
    <div className={classes.root}>
      <AppBar color="secondary" position="static" className={classes.appBar}>
        <Tabs
          indicatorColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="data tabs for road segments and summaries"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#14375A",
              height: "0.5vh",
            },
          }}
        >
          <Tab
            label="Settings/Inputs"
            {...a11yProps(0)}
            className={classes.appBarText}
          />
          <Tab
            label="Road Segments"
            {...a11yProps(1)}
            className={classes.appBarText}
          />
          <Tab
            label="Monitored Roads"
            {...a11yProps(2)}
            className={classes.appBarText}
            // disabled={isTx100}
          />
          <Tab
            label="Summary"
            {...a11yProps(3)}
            className={classes.appBarText}
          />
          {/* {statusGuide[0] !== "TX100" && (
            <Tab
              label="Custom Summary"
              {...a11yProps(4)}
              className={classes.appBarText}
            />
          )} */}
          <Tab
            label="Custom Summary"
            {...a11yProps(4)}
            className={classes.appBarText}
            // disabled={isTx100}
          />

          <Tab
            label="Other Map Layers"
            {...a11yProps(5)}
            className={classes.appBarText}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <SettingsTab
          mapType={mapType}
          setMapType={setMapType}
          // deckglMapType={deckglMapType}
          // setDeckglMapType={setDeckglMapType}
          roadGeomFeatures={roadGeomFeatures}
          setRoadGeomFeatures={setRoadGeomFeatures}
          year={year}
          setYear={setYear}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* {roadSegmentTable} */}
        <RoadSegmentTableMui
          year={year}
          layerID={statusGuide}
          statusGuide={statusGuide}
          roads={roads}
          setRoads={setRoads}
          roadsID={roadsID}
          setRoadsID={setRoadsID}
          roadsData={roadsData}
          setRoadsData={setRoadsData}
        />
      </TabPanel>{" "}
      <TabPanel value={value} index={2}>
        <MonitoredRoadsTable
          year={year}
          monitoredRoads={monitoredRoads}
          monitoredRoadsData={monitoredRoadsData}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <SummaryTable layerID={props.layerID} statusGuide={statusGuide} />
      </TabPanel>
      {/* {statusGuide[0] !== "TX100" && (
        <TabPanel value={value} index={4}>
          <CustomSummary layerID={layerID} />
        </TabPanel>
      )} */}
      <TabPanel value={value} index={4}>
        <CustomSummary layerID={layerID} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <OtherMapLayers
          utpLayersChecked={utpLayersChecked}
          setutpLayersChecked={setutpLayersChecked}
          contextLayersChecked={contextLayersChecked}
          setContextLayersChecked={setContextLayersChecked}
        />
      </TabPanel>
    </div>
  );
}
