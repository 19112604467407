import { makeStyles, createMuiTheme } from "@material-ui/core/styles";

// Main theme and responsive fonts
export function makeTheme(mode) {
  return createMuiTheme({
    palette: {
      type: mode,
      primary: {
        light: "#1E1E1E",
        // main: "#14375a",
        main: "#14375a",
        dark: "#000000",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ffd35f",
        main: "#ffc838",
        dark: "#b28c27",
        contrastText: "#000",
      },
      background: {
        paper: mode === "dark" ? "#1E1E1E" : "#ffffff",
      },
    },
  });
}

// Sidebar
const drawerWidth = 325;
export const sidebarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: "#14375a",
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: 5,
    overflowX: "auto",
    overflowY: "hidden",
  },
  divider: {
    // margin: "8px 0",
    marginBottom: "5px",
    height: 1.5,
  },
  sectionHeader: {
    color: "#FFFFFF",
    // fontStyle: "bold",
    paddingLeft: "0.5em",
    // fontSize: "1.15em",
  },
  text: {
    color: "#FFFFFF  !important",
    // fontStyle: "bold",
    paddingLeft: "0.75em",
    fontSize: "1.15em",
  },
  iconText: {
    color: "#FFFFFF  !important",
    // fontStyle: "bold",
    // paddingLeft: "0.25em",
    fontSize: "1.15em",
  },
}));
