import React, {
  // useState,
  useContext,
  // useEffect
} from "react";

import { lighten, makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { RhinoQueryContext } from "../map/RhinoQueryContext";

// import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// import colorScale from '../utils/colorScale'

import { ListSubheader } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  roadSegmentSelector: {
    backgroundColor: theme.palette.background.paper,
  },
  wrapIcon: {
    //verticalAlign: 'middle',
    display: "flex",
    alignItems: "center",
  },
  roadwayLegendLine: {
    width: "100%",
    height: "5px",
    background: "#4d4dff",
    //float: 'left',
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "10px",
  },

  roadwayLegendLine2: {
    width: "100%",
    height: "20px",
    //background: '#4d4dff',
    //float: 'left',
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "10px",
  },
  roadwayLegendLine2Text: {
    //width: "100%",
    //height: '20px',
    //background: '#4d4dff',
    //float: 'left',
    //marginLeft: '5px',
    //marginRight: '5px',
    marginTop: "10px",
  },
  roadwayLegendDiv: {
    borderBottomStyle: "solid",
    borderWidth: "1px",
    borderColor: "grey",
    marginBottom: "5px",
  },
}));

//function rgbToHex(r, g, b) {
//    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
//}

//function colorScale(height, minValue, maxValue) {
//    //const color = d3.color(d3.interpolateRdYlGn(linearScale(height)));
//    //const color = d3.color(d3.interpolatePuRd(linearScale(height)));
//    //const color = d3.color(d3.interpolateOrRd(linearScale(height)));
//
//    const linearScale = scaleLinear()
//        //.domain([minTempTPTI, maxTempTPTI])
//        .domain([minValue, maxValue])
//        .range([0, 1]);
//
//    const color = colorF(interpolateTurbo(linearScale(height)));
//
//    if (color) return rgbToHex(color.r, color.g, color.b);
//}

export default function YearSelection(props) {
  const classes = useStyles();
  const [query, dispatch] = useContext(RhinoQueryContext);
  const { year, setYear } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const options = [2023, 2022, 2021, 2020, 2019, 2018, 2017];

  const handleMenuItemClick = (event, index, value) => {
    //console.log("event", event);
    setSelectedIndex(index);
    setAnchorEl(null);

    dispatch({
      type: "setUserSelection",
      selection: {
        ria_rte_id: "",
        frm_dfo: NaN,
        to_dfo: NaN,
      },
    });

    dispatch({
      type: "setUserSelectionTX100",
      selection: {
        top100id: "",
        from_road: NaN,
        to_road: "",
        road: "",
      },
    });

    dispatch({
      type: "setYearSelection",
      //selection: event.target.value
      selection: options[index],
    });
    setYear(options[index]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.roadSegmentSelector}>
      <List
        component="nav"
        aria-label="Device settings"
        style={{ marginTop: "0px", paddingTop: "0px" }}
      >
        <ListItem
          style={{ marginBottom: "1px", marginTop: "0px", paddingTop: "0px" }}
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          onClick={handleClickListItem}
        >
          <ListItemText
            primary={"Congestion Reporting Year:"}
            // primary={
            //   <ListItemText
            //     primary={"Congestion Reporting Year"}
            //     secondary={"(data is for prior year)"}
            //   />
            // }
            // secondary={query.yearSelection}
            secondary={
              <ListItemText
                primary={query.yearSelection}
                secondary={"(data is for prior year)"}
                style={{ marginTop: "1px", paddingTop: "1px" }}
              />
            }
            style={{
              marginTop: "1px",
              paddingTop: "1px",
              marginBottom: "1px",
              paddingBottom: "1px",
            }}
          />
          <div style={{ minWidth: "40px", textAlign: "right" }}>
            <ListItemIcon>
              {" "}
              {/*alignItemsFlexStart>*/}
              <ExpandMoreIcon />
            </ListItemIcon>
          </div>
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            //disabled={index === 0}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
