import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
  //    useCallback
} from "react";
import axios from "axios";
import { RhinoQueryContext } from "./RhinoQueryContext";
import DeckGL from "@deck.gl/react";
// import { FlyToInterpolator } from "@deck.gl/core";
// import { LineLayer, GeoJsonLayer, IconLayer } from "@deck.gl/layers";
import { GeoJsonLayer, IconLayer } from "@deck.gl/layers";
import {
  StaticMap,
  MapContext,
  NavigationControl,
  Popup,
  ScaleControl,
  WebMercatorViewport,
} from "react-map-gl";
import {
  API_ROOT,
  COLOR_RHINO,
  MAPBOX_ACCESSTOKEN,
  MAPBOX_ATTRIBUTION,
  // MAPBOX_URL,
  // MAPBOX_STREETS_V10_URL,
  tx100_monitored_counties,
  baseLayers,
  colorContextlayers,
} from "../common/constants";

import * as d3 from "d3";

import MapTabs from "./mapTabs";
import { makeStyles, Dialog } from "@material-ui/core";

import LayerControls from "./contols/LayerControls";

import CircularProgress from "@mui/material/CircularProgress";

import bbox from "@turf/bbox";
import buffer from "@turf/buffer";

import {
  fetchCountyBoundaryData,
  fetchDistrictBoundariesData,
  fetchSandMinesData,
  fetchDisposalWellsData,
  fetchCommercialBorderCrossingData,
  fetchNonCommercialBorderCrossingData,
  fetchRailBorderCrossingData,
  fetchTruckParkingData,
  fetchPortsData,
  fetchCargoAirportsData,
  fetchAirportsData,
  fetchUtpData,
} from "../data-loaders/DataLoaders";

import commercialBorderCrossingIconSvg from "../assets/icons/green_box_24dp.svg";
import nonCommercialBorderCrossingIconSvg from "../assets/icons/violet_box_24dp.svg";
import railBorderCrossingIconSvg from "../assets/icons/pink_box_24dp.svg";
import truckParkingIconSvg from "../assets/icons/local_parking_white_on_green_24dp.svg";
import portsIconSvg from "../assets/icons/anchor_white_24dp.svg";
import cargoAirportsIconSvg from "../assets/icons/flight_yellow_24dp.svg";
import airportsIconSvg from "../assets/icons/flight_white_24dp.svg";
import UtpIconSVG1 from "../assets/icons/construction_white_on_red_24dp.svg";
import UtpIconSVG2 from "../assets/icons/construction_white_on_purple_24dp.svg";
import UtpIconSVG3 from "../assets/icons/construction_white_on_green_24dp.svg";
import UtpIconSVG4 from "../assets/icons/construction_white_on_blue_24dp.svg";

import { scaleLinear } from "d3-scale";
import { interpolateTurbo } from "d3-scale-chromatic";
import { color as colorF } from "d3-color";
import Utils from "../common/utils";
//d3.color(d3.interpolateTurbo

const useStyles = makeStyles({
  deckglMap: {
    position: "relative",
  },
  dialog: {
    // position: "absolute",
    // left: "92%",
    // top: "50%",
    // transform: "translate(-75%,-50%)",
    position: "absolute",
    // position: "relative",
    // left: "60%",
    top: "0%",
    // transform: "translate(-75%,-50%)",
    // left: "1000px",
    right: "5px",
  },
  dialog2: {
    position: "absolute",
    left: "69vw",
    top: "27vh",
  },
});

function DeckglMap(props) {
  const classes = useStyles();

  /*loading overlay status to display spinner*/
  const [isLoading, setIsLoading] = useState(false);

  const viewport = {
    // height: "500px",
    //height: "65vh",
    // height: "65%",
    // width: "100%",
    //initViewport
    width: "100%",
    height: "100%",
  };

  // const initialViewState = {
  //   longitude: -99.458333,
  //   latitude: 31.243333,
  //   // longitude: -122.41669,
  //   // latitude: 37.7833,
  //   zoom: 4.85,
  //   pitch: 0,
  //   bearing: 0,
  // };
  // const MAP_STYLE =
  //   "https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json";

  const NAV_CONTROL_STYLE = {
    // position: "absolute",
    // top: 5,
    // left: 5,
    position: "absolute",
    // position: "relative",
    top: "2%",
    left: "10px",
    width: 30,
  };
  const ScaleControl_STYLE = {
    // position: "absolute",
    // top: 350,
    // left: 5,
    position: "absolute",
    // position: "relative",
    top: "87%",
    left: "10px",
  };
  const layers_CONTROL_STYLE = {
    // position: "absolute",
    // top: 90,
    // left: 5,
    position: "absolute",
    // position: "relative",
    top: "22%",
    // left: "0.5%",
  };

  const [rhino, setRhino] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [rhinoPolygon, setRhinoPolygon] = useState(null);
  const [rhinoLineColorsWeight, setRhinoLineColorsWeight] = useState(null);

  /* the queries set by selecting the summary table rows */
  const [query, dispatch] = useContext(RhinoQueryContext);
  // const [query, dispatch] = useContext(TX100QueryContext);
  const [countyList, setCountyList] = useState([]);

  const {
    statusGuide,
    // deckglMapType,
    roadGeomFeatures,
    setRoadGeomFeatures,
    mapType,
  } = props;
  // console.log("mapType-check", mapType);
  const [homeMapBounds, setHomeMapBounds] = useState({
    longitude: -99.458333,
    latitude: 31.243333,
    zoom: 4.85,
  });
  const [countyBoundaries, setCountyBoundaries] = useState(null);
  const [districtBoundaries, setDistrictBoundaries] = useState(null);
  const [sandMinesData, setSandMinesData] = useState(null);
  const [disposalWellsData, setdisposalWellsData] = useState(null);
  const [
    commercialBorderCrossingsData,
    setCommercialBorderCrossingData,
  ] = useState(null);
  const [
    nonCommercialBorderCrossingsData,
    setNonCommercialBorderCrossingData,
  ] = useState(null);
  const [railBorderCrossingsData, setRailBorderCrossingData] = useState(null);
  const [truckParkingData, setTruckParkingData] = useState(null);
  const [portsData, setPortsData] = useState(null);
  const [cargoAirportsData, setCargoAirportsData] = useState(null);
  const [airportsData, setAirportsData] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [utpData, setUTPData] = useState({
    type: "FeatureCollection",
    features: [],
  });

  const [showRoads, setShowRoads] = useState(true);

  const [fromPoints, setFromPoints] = useState([]);

  //useMemo(() => {
  useEffect(() => {
    // console.log("fetchRhinoData-check");
    const fetchTX100Network = async () => {
      try {
        if (props.layerID) {
          // setIsLoading(true);
          // const response = await axios.get(
          //   `${API_ROOT}` +
          //     props.route +
          //     (props.route.includes("?") ? "&" : "?") +
          //     "year=" +
          //     query.yearSelection
          // );

          // let fc = response.data.features;
          let fc = roadGeomFeatures.features;

          let fc_colors_weight = [];
          let colorTemp = COLOR_RHINO.normal
            .match(/[0-9a-f]{2}/g)
            .map((x) => parseInt(x, 16));
          fc.map((obj) => {
            fc_colors_weight.push({
              rte_id: obj.properties.top100id,
              top100id: obj.properties.top100id,
              ogc_fid_new: "",
              frm_dfo: obj.properties.from_road,
              to_dfo: obj.properties.to_road,
              color: colorTemp,
              color_base: colorTemp,
              weight: 1,
              weight_base: 1,
              elevScale: 1,
            });
          });

          //.log(fc);
          //console.log("Test f end");
          setRhino({
            type: "FeatureCollection",
            features: fc,
          });
          dispatch({
            type: "setFeatures",
            payload: fc,
          });
          setRhinoLineColorsWeight(fc_colors_weight);

          // setRoadGeomFeatures({
          //   features: fc,
          //   //  features: roadGeomFeatures.features,
          //   numFeatures: fc.length,
          //   type: "tx100",
          //   minValue: roadGeomFeatures.minValue,
          //   maxValue: roadGeomFeatures.maxValue,
          // });
          // setGeoJsonKey(Date.now());
          // setIsLoading(false);

          // var pointG = point([-90.54863, 14.616599]);
          // var buffered = buffer(
          //   {
          //     type: "FeatureCollection",
          //     features: fc,
          //   },
          //   0.05,
          //   { units: "miles" }
          // );
          // let polygon = buffer(rhino, 500, { units: "miles" });
          // console.log("polygon-check", buffered);
          // setRhinoPolygon(
          //   buffer(
          //     {
          //       type: "FeatureCollection",
          //       features: fc,
          //     },
          //     0.05,
          //     { units: "miles" }
          //   )
          // );

          dispatch({
            type: "setroadSegmentCategoryIndex",
            selection: 0,
          });
          dispatch({
            type: "setroadSegmentCategory",
            selection: "none",
          });

          const findUnique = (array, key) => {
            var unique = array
              .map((p) => p.properties[key])
              .filter((key, index, arr) => arr.indexOf(key) === index);
            return unique;
          };
          // const response = await axios.get(process.env.PUBLIC_URL + `/TxDOT_Projects.geojson`);
          let cntyList = findUnique(fc, "co");
          //type={props.layerID === "statewide" ? "rhino" : "tx100"}
          if (statusGuide[0] === "TX100") {
            //if (props.layerID !== "statewide") {
            cntyList = tx100_monitored_counties.map((f) => {
              var nbr = f.cnty_nbr;
              return nbr;
            });
          }
          setCountyList(cntyList);
        }
      } catch (e) {
        console.log(e);
      }
    };

    const fetchRhinoNetwork = async () => {
      try {
        //if (props.quads.length > 0) {
        if (props.layerID) {
          // setIsLoading(true);
          // const response = await axios.get(
          //   `${API_ROOT}` + props.route + "&year=" + query.yearSelection
          // );
          // let fc = response.data.features;
          let fc = roadGeomFeatures.features;

          let fc_colors_weight = [];
          let colorTemp = COLOR_RHINO.normal
            .match(/[0-9a-f]{2}/g)
            .map((x) => parseInt(x, 16));
          fc.map((obj) => {
            fc_colors_weight.push({
              rte_id: obj.properties.ria_rte_id,
              top100id: "",
              ogc_fid_new: obj.properties.ogc_fid_new,
              frm_dfo: obj.properties.frm_dfo,
              to_dfo: obj.properties.to_dfo,
              color: colorTemp,
              color_base: colorTemp,
              weight: 1,
              weight_base: 1,
              elevScale: 1,
            });
          });
          // let fc = f.map((obj) => {
          //   obj.color = COLOR_RHINO.normal;
          //   obj.color_base = COLOR_RHINO.normal;
          //   obj.properties.color = COLOR_RHINO.normal;
          //   obj.properties.color_base = COLOR_RHINO.normal;
          //   obj.weight = 1;
          //   obj.weight_base = 1;
          //   fc_colors_weight.push({
          //     rte_id: obj.properties.ria_rte_id,
          //     frm_dfo: obj.properties.frm_dfo,
          //     to_dfo: obj.properties.to_dfo,
          //     color: COLOR_RHINO.normal,
          //     color_base: COLOR_RHINO.normal,
          //     weight: 1,
          //     weight_base: 1,
          //   });
          //   return obj;
          // });
          setRhino({
            type: "FeatureCollection",
            features: fc,
          });
          dispatch({
            type: "setFeatures",
            payload: fc,
          });
          setRhinoLineColorsWeight(fc_colors_weight);
          // setRoadGeomFeatures({
          //   features: fc,
          //   //  features: roadGeomFeatures.features,
          //   numFeatures: fc.length,
          //   type: "rhino",
          //   minValue: roadGeomFeatures.minValue,
          //   maxValue: roadGeomFeatures.maxValue,
          // });

          // setGeoJsonKey(Date.now());
          // setIsLoading(false);

          setHomeMapBounds(getBoundsForRoads(fc));

          dispatch({
            type: "setroadSegmentCategoryIndex",
            selection: 0,
          });
          dispatch({
            type: "setroadSegmentCategory",
            selection: "none",
          });

          const findUnique = (array, key) => {
            var unique = array
              .map((p) => p.properties[key])
              .filter((key, index, arr) => arr.indexOf(key) === index);
            return unique;
          };
          // const response = await axios.get(process.env.PUBLIC_URL + `/TxDOT_Projects.geojson`);
          let cntyList = findUnique(fc, "co");
          //type={props.layerID === "statewide" ? "rhino" : "tx100"}
          if (statusGuide[0] === "TX100") {
            //if (props.layerID !== "statewide") {
            cntyList = tx100_monitored_counties.map((f) => {
              var nbr = f.cnty_nbr;
              return nbr;
            });
          }
          setCountyList(cntyList);
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (statusGuide[0] === "TX100") {
      fetchTX100Network();
      setUTPData({
        type: "FeatureCollection",
        features: [],
      });
    } else {
      fetchRhinoNetwork();
      setUTPData({
        type: "FeatureCollection",
        features: [],
      });
    }

    // Re-set county dependent context data.
    //   setCountyBoundaries(null);
    //   setDistrictBoundaries(null);
    //   setSandMinesData(null);
    //   setdisposalWellsData(null);
    //   setCommercialBorderCrossingData(null);
    //   setNonCommercialBorderCrossingData(null);
    //   setRailBorderCrossingData(null);
    setTruckParkingData(null);
    //   setPortsData(null);
    //   setCargoAirportsData(null);
    //   setAirportsData(null);
    setInfo(<h4>Hover over a road</h4>);
    // }, [props.layerID, props.year, statusGuide[0], query.yearSelection]);
  }, [roadGeomFeatures]);
  // console.log("roadGeomFeatures-check", roadGeomFeatures);

  // useMemo(() => {
  //   if (deckglMapType === "lines") setRhinoPolygon(rhino);
  //   else setRhinoPolygon(buffer(rhino, 0.05, { units: "miles" }));
  // }, [rhino, deckglMapType]);
  useMemo(() => {
    if (rhino && mapType === "deckGL-polygons") {
      setRhinoPolygon(buffer(rhino, 0.05, { units: "miles" }));
    }
  }, [rhino, mapType]);

  const [roadLayerVisibility, setRoadLayerVisibility] = useState({
    lines: true,
    polygons: false,
  });
  useMemo(() => {
    if (showRoads === false)
      setRoadLayerVisibility({
        lines: false,
        polygons: false,
      });
    else {
      if (mapType === "deckGL-lines")
        setRoadLayerVisibility({
          lines: true,
          polygons: false,
        });
      else
        setRoadLayerVisibility({
          lines: false,
          polygons: true,
        });
    }
  }, [mapType, showRoads]);

  useMemo(() => {
    if (airportsData === null && query.contextLayersVisibility.airports) {
      fetchAirportsData()
        .then((res) => {
          // console.log("succesfull stuff to be done here", res.data);
          setAirportsData(res.data);
          let numFeaturesContext = query.contextLayersNumbFeatures;
          numFeaturesContext.airports = res.data.features.length;
          dispatch({
            type: "setContextLayersNumbFeatures",
            selection: numFeaturesContext,
          });
        })
        .catch((err) => console.error(err)); // promise
    }
  }, [query.contextLayersVisibility.airports]);
  useMemo(() => {
    if (
      cargoAirportsData === null &&
      query.contextLayersVisibility.cargoAirports
    ) {
      fetchCargoAirportsData()
        .then((res) => {
          // console.log("succesfull stuff to be done here", res.data);
          setCargoAirportsData(res.data);
          let numFeaturesContext = query.contextLayersNumbFeatures;
          numFeaturesContext.cargoAirports = res.data.features.length;
          dispatch({
            type: "setContextLayersNumbFeatures",
            selection: numFeaturesContext,
          });
        })
        .catch((err) => console.error(err)); // promise
    }
  }, [query.contextLayersVisibility.cargoAirports]);
  useMemo(() => {
    if (portsData === null && query.contextLayersVisibility.portsLayer) {
      fetchPortsData()
        .then((res) => {
          // console.log("succesfull stuff to be done here", res.data);
          setPortsData(res.data);
          let numFeaturesContext = query.contextLayersNumbFeatures;
          numFeaturesContext.portsLayer = res.data.features.length;
          dispatch({
            type: "setContextLayersNumbFeatures",
            selection: numFeaturesContext,
          });
        })
        .catch((err) => console.error(err)); // promise
    }
  }, [query.contextLayersVisibility.portsLayer]);
  useMemo(() => {
    if (
      truckParkingData === null &&
      countyList.length > 0 &&
      query.contextLayersVisibility.truckParkingLayer
    ) {
      //setIsLoading(true);

      fetchTruckParkingData(countyList)
        .then((res) => {
          // console.log("succesfull stuff to be done here", res.data);
          setTruckParkingData(res.data);
          let numFeaturesContext = query.contextLayersNumbFeatures;
          numFeaturesContext.truckParkingLayer = res.data.features.length;
          dispatch({
            type: "setContextLayersNumbFeatures",
            selection: numFeaturesContext,
          });
        })
        .catch((err) => console.error(err)); // promise
    }
  }, [countyList, query.contextLayersVisibility.truckParkingLayer]);
  useMemo(() => {
    if (
      railBorderCrossingsData === null &&
      query.contextLayersVisibility.railBorderCrossings
    ) {
      fetchRailBorderCrossingData()
        .then((res) => {
          // console.log("succesfull stuff to be done here", res.data);
          setRailBorderCrossingData(res.data);
          let numFeaturesContext = query.contextLayersNumbFeatures;
          numFeaturesContext.railBorderCrossings = res.data.features.length;
          dispatch({
            type: "setContextLayersNumbFeatures",
            selection: numFeaturesContext,
          });
        })
        .catch((err) => console.error(err)); // promise
    }
  }, [query.contextLayersVisibility.railBorderCrossings]);
  useMemo(() => {
    if (
      nonCommercialBorderCrossingsData === null &&
      query.contextLayersVisibility.nonCommercialBorderCrossings
    ) {
      fetchNonCommercialBorderCrossingData()
        .then((res) => {
          // console.log("succesfull stuff to be done here", res.data);
          setNonCommercialBorderCrossingData(res.data);
          let numFeaturesContext = query.contextLayersNumbFeatures;
          numFeaturesContext.nonCommercialBorderCrossings =
            res.data.features.length;
          dispatch({
            type: "setContextLayersNumbFeatures",
            selection: numFeaturesContext,
          });
        })
        .catch((err) => console.error(err)); // promise
    }
  }, [query.contextLayersVisibility.nonCommercialBorderCrossings]);
  useMemo(() => {
    if (
      commercialBorderCrossingsData === null &&
      query.contextLayersVisibility.commercialBorderCrossings
    ) {
      fetchCommercialBorderCrossingData()
        .then((res) => {
          // console.log("succesfull stuff to be done here", res.data);
          setCommercialBorderCrossingData(res.data);
          let numFeaturesContext = query.contextLayersNumbFeatures;
          numFeaturesContext.commercialBorderCrossings =
            res.data.features.length;
          dispatch({
            type: "setContextLayersNumbFeatures",
            selection: numFeaturesContext,
          });
        })
        .catch((err) => console.error(err)); // promise
    }
  }, [query.contextLayersVisibility.commercialBorderCrossings]);
  useMemo(() => {
    if (
      disposalWellsData === null &&
      query.contextLayersVisibility.disposalWells
    ) {
      fetchDisposalWellsData()
        .then((res) => {
          // console.log("succesfull stuff to be done here", res.data);
          setdisposalWellsData(res.data);
          let numFeaturesContext = query.contextLayersNumbFeatures;
          numFeaturesContext.disposalWells = res.data.features.length;
          dispatch({
            type: "setContextLayersNumbFeatures",
            selection: numFeaturesContext,
          });
        })
        .catch((err) => console.error(err)); // promise
    }
  }, [query.contextLayersVisibility.disposalWells]);
  useMemo(() => {
    if (
      sandMinesData === null &&
      query.contextLayersVisibility.odessaDistSandMines
    ) {
      fetchSandMinesData()
        .then((res) => {
          // console.log("succesfull stuff to be done here", res.data);
          setSandMinesData(res.data);
          let numFeaturesContext = query.contextLayersNumbFeatures;
          numFeaturesContext.odessaDistSandMines = res.data.features.length;
          dispatch({
            type: "setContextLayersNumbFeatures",
            selection: numFeaturesContext,
          });
        })
        .catch((err) => console.error(err)); // promise
    }
  }, [query.contextLayersVisibility.odessaDistSandMines]);
  useMemo(() => {
    if (
      districtBoundaries === null &&
      query.contextLayersVisibility.districtBoundaries
    ) {
      fetchDistrictBoundariesData()
        .then((res) => {
          // console.log("succesfull stuff to be done here", res.data);
          setDistrictBoundaries(res.data);
        })
        .catch((err) => console.error(err)); // promise
    }
  }, [query.contextLayersVisibility.districtBoundaries]);
  useMemo(() => {
    setIsLoading(true);
    if (
      countyBoundaries === null &&
      countyList.length > 0 &&
      query.contextLayersVisibility.countyBoundaries
    ) {
      fetchCountyBoundaryData(countyList, setIsLoading)
        .then((res) => {
          // console.log("succesfull stuff to be done here", res.data);
          setCountyBoundaries(res.data);
        })
        .catch((err) => console.error(err)); // promise
    }
    setIsLoading(false);
  }, [countyList, query.contextLayersVisibility.countyBoundaries]);

  /* UTP projects data in GeoJSON format */
  // const [utpData, setUTPData] = useState({
  //   type: "FeatureCollection",
  //   features: [],
  // });
  const [utpPointsData, setUtpPointsData] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [selectedUtpData, setSelectedUTPData] = useState(null);
  useMemo(() => {
    if (
      utpData.features.length === 0 &&
      countyList.length > 0 &&
      (query.utpMapLayersVisibility.underwayorsoon ||
        query.utpMapLayersVisibility.within4years ||
        query.utpMapLayersVisibility.fivetotenyears ||
        query.utpMapLayersVisibility.tenplusyears)
    ) {
      //setIsLoading(true);

      fetchUtpData(countyList)
        .then((res) => {
          // console.log("res.data-check", res.data);

          const getFirstCoordinates = (g) => {
            switch (g.type) {
              case "LineString":
                return [g.coordinates[0][1], g.coordinates[0][0]];
              case "MultiLineString":
                return [g.coordinates[0][0][1], g.coordinates[0][0][0]];
              default:
                return null;
            }
          };
          let dataTemp = res.data.features;
          let fcTemp = [];
          // console.log("res.data-check", dataTemp);
          dataTemp.map((d) => {
            // console.log("res.data-check", d);
            // fcTemp.push({ ...d, geomPoint: "test" });
            // fcTemp.push({
            //   ...d,
            //   geometry: getFirstCoordinates(d.geometry),
            // });
            let geomTemp = getFirstCoordinates(d.geometry);
            fcTemp.push({
              ...d,
              geometry: {
                type: "Point",
                coordinates: [geomTemp[1], geomTemp[0]],
              },
            });
          });
          //{type: 'Point', coordinates: Array(2)}
          // res.data.features.forEach((d) => {
          //   let geomPoint = { geomPoint: "test" };
          //   d.push(geomPoint);
          // });
          // console.log("res.data-check", fcTemp);

          setUTPData(res.data);
          setUtpPointsData({
            type: res.data.type,
            crs: res.data.crs,
            features: fcTemp,
          });

          const featureCount = {
            underwayorsoon: res.data.features.filter(filterPhases[0]).length,
            within4years: res.data.features.filter(filterPhases[1]).length,
            fivetotenyears: res.data.features.filter(filterPhases[2]).length,
            tenplusyears: res.data.features.filter(filterPhases[3]).length,
          };
          dispatch({
            type: "setutpMapLayersCount",
            selection: featureCount, //fs //tableDataUTPvalues.concat(fs) //fs
          });

          // setutpMapLayersCount(featureCount);
        })
        .catch((err) => console.error(err)); // promise
      // fetchUtpData(countyList)
      //   .then((res) => {
      //     const filterPhases = [
      //       "begins soon",
      //       "4 years",
      //       "5 to 10 years",
      //       "10+ years",
      //     ].map((val) => {
      //       return (fc) => fc.properties.PT_PHASE.includes(val);
      //     });

      //     // console.log("succesfull stuff to be done here", res.data);
      //     let f = res.data.features;
      //     // console.log("f-check", f);

      //     let data = {
      //       all: {
      //         type: "FeatureCollection",
      //         features: f,
      //       },
      //       category0: {
      //         name: "UTP-Underway or begins soon",
      //         type: "FeatureCollection",
      //         features: f.filter(filterPhases[0]), //f.properties.PT_PHASE.includes("begins soon"),
      //       },
      //       category1: {
      //         name: "UTP-Begins in 4 years",
      //         type: "FeatureCollection",
      //         features: f.filter(filterPhases[1]),
      //       },
      //       category2: {
      //         name: "UTP-Begins in 5-10 years",
      //         type: "FeatureCollection",
      //         features: f.filter(filterPhases[2]),
      //       },
      //       category3: {
      //         name: "UTP-Begins in 10+ years",
      //         type: "FeatureCollection",
      //         features: f.filter(filterPhases[3]),
      //       },
      //     };
      //     console.log("data-check", data.category0);
      //     setUTPData({ data: data });

      //     const featureCount = {
      //       underwayorsoon: f.filter(filterPhases[0]).length,
      //       within4years: f.filter(filterPhases[1]).length,
      //       fivetotenyears: f.filter(filterPhases[2]).length,
      //       tenplusyears: f.filter(filterPhases[3]).length,
      //     };
      //     //   setutpMapLayersCount(featureCount);
      //     // dispatch({
      //     //   type: "setContextLayersNumbFeatures",
      //     //   selection: numFeaturesContext,
      //     // });

      //     // old Code
      //     //           setTruckParkingData(res.data);
      //     //           let numFeaturesContext = query.contextLayersNumbFeatures;
      //     //           numFeaturesContext.truckParkingLayer = res.data.features.length;
      //     //           dispatch({
      //     //             type: "setContextLayersNumbFeatures",
      //     //             selection: numFeaturesContext,
      //     //           });
      //   })
      //   .catch((err) => console.error(err)); // promise
    }
  }, [
    countyList,
    query.utpMapLayersVisibility.underwayorsoon,
    query.utpMapLayersVisibility.within4years,
    query.utpMapLayersVisibility.fivetotenyears,
    query.utpMapLayersVisibility.tenplusyears,
  ]);
  // }, [countyList, query.utpMapLayersVisibility]);

  //props.layerID, props.year, statusGuide[0]
  const getFirstCoordinates = (g) => {
    switch (g.type) {
      case "LineString":
        return [g.coordinates[0][1], g.coordinates[0][0]];
      case "MultiLineString":
        return [g.coordinates[0][0][1], g.coordinates[0][0][0]];
      default:
        return null;
    }
  };

  function getBoundsForRoads(features) {
    const mapBounds_bbox = bbox({
      type: "FeatureCollection",
      features: features,
    });
    // Calculate corner values of bounds
    // const pointsLong = points.map((point) => point.coordinates._long);
    // const pointsLat = points.map((point) => point.coordinates._lat);
    // const cornersLongLat = [
    //   [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
    //   [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
    // ];
    const cornersLongLat = [
      [mapBounds_bbox[0], mapBounds_bbox[1]],
      [mapBounds_bbox[2], mapBounds_bbox[3]],
    ];
    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({
      width: 800,
      height: 600,
    }).fitBounds(cornersLongLat, { padding: 100 }); // Can also use option: offset: [0, -100]
    // const viewport = new WebMercatorViewport({
    //   width: 800,
    //   height: 600,
    // }).fitBounds(mapBounds_bbox, { padding: 200 }); // Can also use option: offset: [0, -100]
    const { longitude, latitude, zoom } = viewport;
    return { longitude, latitude, zoom };
  }

  // const [geoJsonKey, setGeoJsonKey] = useState(Date.now());

  // const getLineColor = (d) => {
  //   const hex = d.color;
  //   // convert to RGB
  //   return hex.match(/[0-9a-f]{2}/g).map((x) => parseInt(x, 16));
  // };

  // const getLineColor2 = (d) => {
  //   if (zoomLevel < 6) {
  //     return COLOR_RHINO.normal
  //       .match(/[0-9a-f]{2}/g)
  //       .map((x) => parseInt(x, 16));
  //   } else {
  //     return COLOR_RHINO.selected
  //       .match(/[0-9a-f]{2}/g)
  //       .map((x) => parseInt(x, 16));
  //   }
  // };

  // function colorScale2(height) {
  //   const linearScale = d3
  //     .scaleLinear()
  //     // .domain([0, dataCategoryMax])
  //     // .domain([0, 2000])
  //     // .range([0, 1]);
  //     .domain([100, 0])
  //     .range([0, 1]);

  //   const color = d3.color(d3.interpolateTurbo(linearScale(height)));
  //   //const color = d3.color(d3.interpolateOrRd(linearScale(height)));
  //   //const color = d3.color(d3.interpolateBlues(linearScale(height)));

  //   //const linearScale = d3.scaleLinear().domain([0, dataCategoryMax]).range([1, 0]);
  //   //const color = d3.color(d3.interpolateCubehelixDefault(linearScale(height)));

  //   return [color.r, color.g, color.b];
  // }
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [activePopup, setActivePopup] = useState(false);
  const renderPopup = (obj) => {
    if (selectedPoint !== null && selectedPoint !== undefined) {
      //   return (
      //     <Popup
      //       captureClick
      //       closeButton={false}
      //       closeOnClick
      //       longitude={selectedPoint.geometry.coordinates[0]}
      //       latitude={selectedPoint.geometry.coordinates[1]}
      //       onClose={deselectPoint}
      //       className={styles.popup}
      //       offsetTop={-10}
      //     >
      //       <div>{selectedPoint?.properties?.name}</div>
      //     </Popup>
      //   );
      // }
      if (activePopup) {
        if (activePopup && statusGuide[0] === "TX100") {
          return (
            <Popup
              key="popup"
              // longitude={-99.45833}
              // latitude={31.243333}
              longitude={selectedPoint.coordinate[0]}
              latitude={selectedPoint.coordinate[1]}
              // closeButton={true}
              // onClose={setSelectedPoint(null)}
              //closeOnClick={false}
              closeButton={true}
              //closeOnClick={false}
              onClose={() => setSelectedPoint(null)}
            >
              {/* <h5>${selectedPoint.object.properties.road}</h5>
          <div>
            {selectedPoint.object.properties.from_road} to{" "}
            {selectedPoint.object.properties.to_road}
          </div>
          <div>TX100 ID: {selectedPoint.object.properties.top100id}</div> */}

              <div className="custom-block">
                <p className="custom-block-layer">
                  <strong>Layer ID:</strong> {props.layerID}
                </p>
                <h6 className="custom-block-title">
                  {selectedPoint.object.properties.road}
                </h6>{" "}
                <br />
                <p className="custom-block-text">
                  {selectedPoint.object.properties.from_road} to
                  {selectedPoint.object.properties.to_road}
                </p>
                <br />
                <div>
                  <p className="custom-block-text">
                    To add road segment to the data table, click <br />
                    <strong>Add segment</strong> below. <br />
                  </p>
                  <br />
                  <p className="from-to-links">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={handleAddSegment}
                      // onClick={handleAddSegment(selectedPoint.object.properties)}
                    >
                      Add segment
                    </button>
                  </p>
                </div>
              </div>
            </Popup>
          );
        } else {
          return (
            <Popup
              key="popup"
              // longitude={-99.45833}
              // latitude={31.243333}
              longitude={selectedPoint.coordinate[0]}
              latitude={selectedPoint.coordinate[1]}
              // closeButton={true}
              // onClose={setSelectedPoint(null)}
              //closeOnClick={false}
              closeButton={true}
              //closeOnClick={false}
              onClose={() => setSelectedPoint(null)}
            >
              <div className="custom-block">
                <p className="custom-block-layer">
                  <strong>Layer ID:</strong> {props.layerID}
                </p>
                <h6 className="custom-block-title">
                  Route ID: {selectedPoint.object.properties.ria_rte_id}
                </h6>
                <br />
                <p className="custom-block-text">
                  From/To DFO: {selectedPoint.object.properties.frm_dfo} to{" "}
                  {selectedPoint.object.properties.to_dfo}
                </p>
                <br />
                <div>
                  {/* <p className="custom-block-text">
                    To add road segment to the data table, click <br />
                    <strong>Add segment</strong> below.
                  </p> */}
                  <p className="custom-block-text">
                    To add road segment to the data table: <br />
                  </p>
                  <br />
                  <p className="from-to-links">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={handleAddSegment}
                    >
                      Add segment
                    </button>
                  </p>
                </div>
                <div>
                  <div>
                    <p> To add a corridor to the data table:</p>
                    {/* <ol>
                      <li>
                        Click <strong>From Here</strong> on first segment
                      </li>
                      <li>
                        Click <strong>To Here</strong> on final segment to{" "}
                        <br />
                        add corridor to data table.
                      </li>
                    </ol> */}
                  </div>
                  <p className="from-to-links">
                    <button
                      className="btn btn-secondary btn-sm"
                      onClick={handleFrom}
                      disabled={fromToCleasrButtonDisabled.fromButton}
                    >
                      From here
                    </button>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={handleTo}
                      disabled={fromToCleasrButtonDisabled.toButton}
                    >
                      To here
                    </button>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={handleClearSelection}
                      disabled={fromToCleasrButtonDisabled.clearButton}
                    >
                      Clear prior selection
                    </button>
                  </p>
                </div>
              </div>
            </Popup>
          );
        }
      }
    }
  };

  const [fromToCleasrButtonDisabled, setFromToClearButtonDisabled] = useState({
    fromButton: false,
    toButton: true,
    clearButton: true,
  });

  function handleAddSegment() {
    setActivePopup(false);
    setSelectedPoint(null);
    let rprops = selectedPoint.object.properties;
    if (statusGuide[0] === "TX100") {
      resetUserSelectionTX100(userSelectionTX100.current.top100id);
      // userSelection.current = {
      //   top100id: rprops.top100id,
      //   from_road: rprops.from_road,
      //   to_road: rprops.to_road,
      //   road: rprops.road,
      // };
      dispatch({
        type: "setUserSelectionTX100",
        selection: {
          top100id: rprops.top100id,
          from_road: rprops.from_road,
          to_road: rprops.to_road,
          road: rprops.road,
        },
      });
      // highlightUserSelectionTX100();
    } else {
      resetUserSelection(userSelection.current.ria_rte_id);
      dispatch({
        type: "setUserSelection",
        selection: {
          ria_rte_id: rprops.ria_rte_id,
          frm_dfo: rprops.frm_dfo,
          to_dfo: rprops.to_dfo,
        },
      });
    }
  }
  const defaultUserSelectionTX100 = {
    /* 
        from_ria_rte_id: "",
        from_frm_dfo: Number.Nan,
        from_to_dfo: Number.NaN,
        to_frm_dfo: Number.NaN,
        to_to_dfo: Number.NaN
        */

    top100id: "",
    from_road: "",
    to_road: "",
  };
  const defaultUserSelection = {
    from_ria_rte_id: "",
    from_frm_dfo: Number.Nan,
    from_to_dfo: Number.NaN,
    to_frm_dfo: Number.NaN,
    to_to_dfo: Number.NaN,
  };
  const userSelection = useRef(defaultUserSelection);
  const userSelectionTX100 = useRef(defaultUserSelectionTX100);

  // function highlightUserSelection(selectionType) {
  //   const selection = userSelection.current;
  //   if (selectionType === "from") {
  //     console.log(
  //       "BUTTON FROM TEST GOOD",
  //       rhino.features.filter(
  //         (f) => f.properties.ria_rte_id !== selection.from_ria_rte_id
  //       )
  //     );
  //     rhino.features
  //       .filter((f) => f.properties.ria_rte_id === selection.from_ria_rte_id)
  //       .forEach((f) => {
  //         f.color = COLOR_RHINO.selected;
  //         f.weight = 5;
  //       });
  //     rhino.features
  //       .filter((f) => f.properties.ria_rte_id !== selection.from_ria_rte_id)
  //       .forEach((f) => {
  //         f.color = COLOR_RHINO.notSelected;
  //         f.weight = 1;
  //       });
  //   } else {
  //     rhino.features
  //       .filter((f) => f.properties.ria_rte_id === selection.from_ria_rte_id)
  //       .forEach((f) => {
  //         f.color = COLOR_RHINO.selected;
  //         f.weight = 5;
  //       });
  //     rhino.features
  //       .filter((f) => f.properties.ria_rte_id !== selection.from_ria_rte_id)
  //       .forEach((f) => {
  //         f.color = f.color_base;
  //         f.weight = f.weight_base;
  //       });
  //     if (!isNaN(selection.to_to_dfo)) {
  //       const fromDfo = Math.min(selection.from_frm_dfo, selection.to_frm_dfo);
  //       const toDfo = Math.max(selection.from_to_dfo, selection.to_to_dfo);
  //       rhino.features
  //         .filter(
  //           (f) =>
  //             f.properties.ria_rte_id === selection.from_ria_rte_id &&
  //             f.properties.frm_dfo >= fromDfo &&
  //             f.properties.to_dfo <= toDfo
  //         )
  //         .forEach((f) => {
  //           f.color = COLOR_RHINO.selectedSection;
  //           f.weight = 5;
  //         });
  //       // rhino.features
  //       //   .filter(
  //       //     (f) =>
  //       //       f.properties.ria_rte_id !== selection.from_ria_rte_id ||
  //       //       f.properties.frm_dfo < fromDfo ||
  //       //       f.properties.to_dfo > toDfo
  //       //   )
  //       //   .forEach((f) => {
  //       //     f.color = COLOR_RHINO.notSelected;
  //       //     f.weight = 5;
  //       //   });
  //     } else {
  //       /* if toHere is not selected */
  //       rhino.features.filter(
  //         (f) =>
  //           f.properties.ria_rte_id === selection.from_ria_rte_id &&
  //           f.properties.frm_dfo === selection.from_frm_dfo
  //       )[0].color = COLOR_RHINO.selectedSection;
  //     }
  //     // setRhino(newRhino);
  //     // setGeoJsonKey(Date.now());
  //     // console.log(
  //     //   "selectedTest",
  //     //   rhino.features.filter((f) => f.color === COLOR_RHINO.selected)
  //     // );
  //     // console.log("selectionTest", selection);
  //   }
  // }

  function handleFrom() {
    let featureProps = selectedPoint.object.properties;
    // console.log("selectedPoint-check", selectedPoint);

    resetUserSelection(userSelection.current.from_ria_rte_id);
    userSelection.current = {
      from_ria_rte_id: featureProps.ria_rte_id,
      from_frm_dfo: featureProps.frm_dfo,
      from_to_dfo: featureProps.to_dfo,
    };

    // highlightUserSelection("from");

    setFromToClearButtonDisabled({
      fromButton: true,
      toButton: false,
      clearButton: false,
    });

    // let verticeIndex = Math.floor(
    //   selectedPoint.object.geometry.coordinates[0].length / 2
    // );

    // setFromPoints([
    //   {
    //     type: "From",
    //     // coordinates: selectedPoint.coordinate,
    //     coordinates: selectedPoint.object.geometry.coordinates[0][verticeIndex],
    //   },
    // ]);
    setFromPoints([
      {
        type: "From",
        // coordinates: selectedPoint.coordinate,
        coordinates: selectedPoint.coordinate,
      },
    ]);

    // console.log(
    //   "rhinoTest",
    //   rhino.features.filter(
    //     (f) => f.properties.ria_rte_id === featureProps.ria_rte_id
    //   )
    // );
    setActivePopup(false);
    // setSelectedPoint(null);
  }

  function handleTo() {
    let rprops = selectedPoint.object.properties;

    let newSelection = { ...userSelection.current };
    newSelection.to_frm_dfo = rprops.frm_dfo;
    newSelection.to_to_dfo = rprops.to_dfo;
    userSelection.current = newSelection;
    const fromDfo = Math.min(
      newSelection.from_frm_dfo,
      newSelection.to_frm_dfo
    );
    const toDfo = Math.max(newSelection.from_to_dfo, newSelection.to_to_dfo);
    dispatch({
      type: "setUserSelection",
      selection: {
        ria_rte_id: newSelection.from_ria_rte_id,
        frm_dfo: fromDfo,
        to_dfo: toDfo,
      },
    });
    // highlightUserSelection();

    resetUserSelection(userSelection.current.from_ria_rte_id);

    setFromToClearButtonDisabled({
      fromButton: false,
      toButton: true,
      clearButton: true,
    });
    setActivePopup(false);
    setSelectedPoint(null);
    // console.log("userSelection.current", userSelection.current);

    setFromPoints([]);
  }

  function handleClearSelection() {
    resetUserSelection(userSelection.current.from_ria_rte_id);
    setFromToClearButtonDisabled({
      fromButton: false,
      toButton: true,
      clearButton: true,
    });
    setFromPoints([]);
  }

  function resetUserSelectionTX100(ria_rte_id) {
    // rhino.features
    //   .filter((f) => f.properties.ria_rte_id === ria_rte_id)
    //   .forEach((f) => {
    //     f.color = f.color_base;
    //     f.weight = f.weight_base;
    //   });
    // setGeoJsonKey(Date.now());
    userSelectionTX100.current = defaultUserSelectionTX100;
  }
  function resetUserSelection(ria_rte_id) {
    // rhino.features
    //   .filter((f) => f.properties.ria_rte_id === ria_rte_id)
    //   .forEach((f) => {
    //     f.color = f.color_base;
    //     f.weight = f.weight_base;
    //   });
    // setGeoJsonKey(Date.now());
    // userSelection.current = defaultUserSelection;

    // rhino.features.forEach((f) => {
    //   f.color = f.color_base;
    //   f.weight = f.weight_base;
    // });

    userSelection.current = defaultUserSelection;
  }

  const [info, setInfo] = useState(
    <h4
      style={{
        paddingBottom: "0.25px",
        marginBottom: "0.25px",
      }}
    >
      Hover over a road
    </h4>
  );

  const [minTrkCatValue, setminTrkCatValue] = useState(0);
  const [maxTrkCatValue, setmaxTrkCatValue] = useState(1000000);

  const [utpMapLayersCount, setutpMapLayersCount] = useState({
    underwayorsoon: 0,
    within4years: 0,
    fivetotenyears: 0,
    tenplusyears: 0,
  });

  /* Build a list of property as an unordered list in jsx
   * @param {*} rprops Rhino road segment properties
   */
  const stylesHoverBox = {
    h6Header: {
      textAlign: "left",
    },
    leftText: {
      textAlign: "left",
      marginBottom: "0",
      paddingBottom: "0",
    },
    leftBullet: {
      textAlign: "left",
      marginLeft: "-25px",
    },
    rightBullet: {
      textAlign: "left",
      marginLeft: "20px",
    },
    leftHeader: {
      textAlign: "left",
      marginLeft: "-35px",
    },
    rightHeader: {
      textAlign: "left",
      marginLeft: "10px",
    },
  };
  const tx100Info = (rprops) => {
    return (
      <div className={props.mode} style={{ padding: "1px" }}>
        <h4
          style={{
            paddingBottom: "0px",
            marginBottom: "0px",
          }}
        >
          {/* {rprops.highway_name} ({rprops.frm_dfo} - {rprops.to_dfo}) */}
          {/* {rprops.ria_rte_id} ({rprops.frm_dfo} - {rprops.to_dfo}) */}
          {rprops.road}
        </h4>
        {/* <h6 >{rprops.from_rd} to {rprops.to_rd}</h6> */}
        <h6
          style={{
            paddingBottom: "0.25px",
            marginBottom: "0.25px",
          }}
        >
          From: {rprops.from_road}
        </h6>
        <h6
          style={{
            paddingBottom: "0.25px",
            marginBottom: "0.25px",
          }}
        >
          To: {rprops.to_road}
        </h6>
        {/* <p style={stylesHoverBox.leftText}>From/To DFO: {rprops.frm_dfo.toLocaleString()} to {rprops.to_dfo.toLocaleString()}</p> */}
        <p style={stylesHoverBox.leftText}>TX100 ID: {rprops.top100id} </p>
        {/* <p
          style={stylesHoverBox.leftText}
          style={{
            paddingBottom: "0.25px",
            marginBottom: "0.25px",
          }}
        >
          Congestion Stats Year: {props.year}{" "}
        </p> */}
        {/* <p style={stylesHoverBox.leftText}>Length of Segment: {rprops.leng.toLocaleString()} </p> */}
        <ul style={stylesHoverBox.leftText}>
          <div style={{ display: "flex" }}>
            <div>
              <b style={stylesHoverBox.leftHeader}>Truck</b>
              <li style={stylesHoverBox.leftBullet}>
                Top 100 Rank: {rprops.trank.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                Hours of Delay: {rprops.tdly.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                Delay Per Mile: {rprops.tdmile.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                Congestion Cost: ${rprops.tcost.toLocaleString()}{" "}
              </li>
            </div>
            <div>
              <b style={stylesHoverBox.rightHeader}>All Vehicles</b>
              <li style={stylesHoverBox.rightBullet}>
                Top 100 Rank: {rprops.arank.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.rightBullet}>
                Hours of Delay: {rprops.dly.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.rightBullet}>
                Delay Per Mile: {rprops.dmile.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.rightBullet}>
                Congestion Cost: ${rprops.cost.toLocaleString()}{" "}
              </li>
            </div>
          </div>
        </ul>
      </div>
    );
  };
  const rhinoInfo = (rprops) => {
    return (
      <div className={props.mode} style={{ padding: "2px" }}>
        <h4 style={stylesHoverBox.leftText}>
          {/* {rprops.highway_name} ({rprops.frm_dfo} - {rprops.to_dfo}) */}
          {/* {rprops.ria_rte_id} ({rprops.frm_dfo} - {rprops.to_dfo}) */}
          Route ID: {rprops.ria_rte_id}
        </h4>
        <h6>
          <strong>From/To DFO: </strong>
          {rprops.frm_dfo.toLocaleString()} to {rprops.to_dfo.toLocaleString()}
        </h6>
        {/* <p style={stylesHoverBox.leftText}>
          Congestion Stats Year: {rprops.year}{" "}
        </p> */}
        <p style={stylesHoverBox.leftText}>
          Length of Segment: {rprops.len_sec.toLocaleString()}{" "}
        </p>
        <p style={stylesHoverBox.leftText}>Estimated: {rprops.estimated}</p>
        <ul style={stylesHoverBox.leftText}>
          <div style={{ display: "flex" }}>
            <div>
              <li style={stylesHoverBox.leftBullet}>
                Adjusted current ADT: {rprops.adt_cur.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                Truck AADT: {rprops.tottrk.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                Daily Truck VMT: {rprops.trkttivmt.toLocaleString()}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                % of single unit trucks in ADT: {rprops.pct_sadt.toFixed(2)}{" "}
              </li>
              <li style={stylesHoverBox.leftBullet}>
                Truck Delay: {rprops.trkdelay.toLocaleString()}{" "}
              </li>
            </div>
            <div>
              <li style={stylesHoverBox.rightBullet}>
                Truck Travel Time Index: {rprops.trktti}{" "}
              </li>
              <li style={stylesHoverBox.rightBullet}>
                Truck Planning Time Index 80: {rprops.trkpti80}{" "}
              </li>
              <li style={stylesHoverBox.rightBullet}>
                Truck Planning Time Index 95: {rprops.trkpti95}{" "}
              </li>
              <li style={stylesHoverBox.rightBullet}>
                % of combo unit trucks in ADT: {rprops.pct_cadt.toFixed(2)}{" "}
              </li>
            </div>
          </div>
        </ul>
      </div>
    );
  };

  const [baseMap, setBaseMap] = useState("mapbox://styles/mapbox/streets-v11");

  const [zoomLevel, setZoomLevel] = useState({
    zoomLevel: 4.85,
    zoomScale: 1,
  });

  // /* Highlights selected Rhino */
  // useEffect(() => {
  //   /* I am cloning the rhino data.
  //    * Is it the best way to refresh the map
  //    * with highlights?
  //    */
  //   // let newRhino = { ...rhino };
  //   const isSelected = query.highlightQuery;
  //   if (typeof isSelected === "function") {
  //     rhino.features
  //       .filter((f) => isSelected(f))
  //       .forEach((f) => {
  //         f.color = COLOR_RHINO.selected;
  //         f.weight = 5;
  //       });
  //     rhino.features
  //       .filter((f) => !isSelected(f))
  //       //.forEach(f => (f.color = COLOR_RHINO.normal));
  //       .forEach((f) => {
  //         f.color = f.color_base;
  //         f.weight = f.weight_base;
  //       });
  //     // setTimeout(() => setRhino(newRhino), 100);

  //     // if (rhino.features.filter((f) => isSelected(f)).length > 0) {
  //     //   const mapBounds_bbox = bbox(
  //     //     {
  //     //       type: "FeatureCollection",
  //     //       features: rhino.features.filter((f) => isSelected(f)),
  //     //     }
  //     //     //{ rhino }
  //     //   );

  //     //   mapRef.current.leafletElement.flyToBounds([
  //     //     [mapBounds_bbox[1] * 0.999, mapBounds_bbox[0] * 1.001],
  //     //     [mapBounds_bbox[3] * 1.001, mapBounds_bbox[2] * 0.999],
  //     //   ]);
  //     // } else {
  //     //   mapRef.current.leafletElement.flyToBounds([homeMapBounds]);
  //     // }

  //     // setGeoJsonKey(Date.now());
  //     setSelectedPoint(null);
  //   }
  // }, [query.highlightQuery]);

  const applyToArray = (func, array) => func.apply(Math, array);

  const getBoundsForPoints = (points) => {
    // Calculate corner values of bounds
    const pointsLong = points.map((point) => point.coordinates._long);
    const pointsLat = points.map((point) => point.coordinates._lat);
    const cornersLongLat = [
      [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
      [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
    ];
    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({
      width: 800,
      height: 600,
    }).fitBounds(cornersLongLat, { padding: 200 }); // Can also use option: offset: [0, -100]
    const { longitude, latitude, zoom } = viewport;
    return { longitude, latitude, zoom };
  };

  const ICON_MAPPING = {
    marker: {
      x: 0,
      y: 0,
      width: 128,
      height: 128,
      mask: true,
    },
  };

  function deckGlTooltip(layer, object) {
    let toolTipStyle = {
      backgroundColor: "#e1e7ed",
      color: "#000000",
      fontSize: "1.0em",
    };
    // if (layer.id === "roads")
    //   return {
    //     html: `<h5>${object.properties.road}</h5><div>From: ${object.properties.from_road}</div>
    //       <div>To: ${object.properties.to_road}</div>
    //       <div>arank: ${object.properties.arank}</div>
    //       <div>trank: ${object.properties.trank}</div>`,
    //     style: {
    //       backgroundColor: "#e1e7ed",
    //       color: "000000",
    //       fontSize: "1.0em",
    //     },
    //   };
    if (layer.id === "sandMines")
      return {
        html: `<h7>Sand Mine</h7>`,
        style: toolTipStyle,
      };
    if (layer.id === "disposalWells")
      return {
        html: `<h7>Disposal Well</h7>`,
        style: toolTipStyle,
      };
    if (
      layer.id === "commercialBorderCrossings" ||
      layer.id === "nonCommercialBorderCrossings" ||
      layer.id === "railBorderCrossings"
    ) {
      let borderCrossingCategory = "Commercial";
      if (layer.id === "nonCommercialBorderCrossings")
        borderCrossingCategory = "Non-Commercial";
      if (layer.id === "railBorderCrossings") borderCrossingCategory = "Rail";
      let commercialAccess = object.properties.VEH_COML === 1 ? "Yes" : "No";
      let noncommercialAccess = object.properties.VEH_CAR === 1 ? "Yes" : "No";
      let railAccess = object.properties.RR_BRDGE === 1 ? "Yes" : "No";
      let pedestrianAccess = object.properties.PED === 1 ? "Yes" : "No";

      return {
        html: `<div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Border Crossings - ${borderCrossingCategory} Layer</h5>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${object.properties.CROSS_NM}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Type:</strong> ${object.properties.CRS_TYPE}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Owner:</strong> ${object.properties.OWNER}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Operator:</strong> ${object.properties.OPRTR}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Jurisdiction Type:</strong> ${object.properties.JURIS_TYPE}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Commercial:</strong> ${commercialAccess}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Non-Commercial:</strong> ${noncommercialAccess}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Rail:</strong> ${railAccess}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Pedestrian:</strong> ${pedestrianAccess}</p>
    </div>`,
        style: toolTipStyle,
      };
    }
    if (layer.id === "truckParking")
      return {
        html: `<h7>Truck Parking Location</h7>`,
        style: toolTipStyle,
      };
    if (layer.id === "ports")
      return {
        html: `<div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Ports Layer</h5>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${object.properties.PORT_NM}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Type:</strong> ${object.properties.PORT_TYPE}</p>
    </div>`,
        style: toolTipStyle,
      };
    if (layer.id === "cargoAirports" || layer.id === "airports") {
      let airportCategory = "Cargo Airports";
      if (layer.id === "airports")
        airportCategory = "Other Major Airports Airports";
      return {
        html: `<div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">${airportCategory} Layer</h5>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Facitlity Name:</strong> ${object.properties.Fac_Name}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Location:</strong> ${object.properties.City}, TX</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Commercial Ops:</strong> ${object.properties.Commercial_Ops}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Commuter Ops:</strong> ${object.properties.Commuter_Ops}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Other Services:</strong> ${object.properties.Other_Services}</p>
    </div>`,
        style: toolTipStyle,
      };
    }
    if (layer.id === "countyBoundaries")
      return {
        html: `<div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Counties Layer</h5>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>${object.properties.CNTY_NM} County</strong></p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County Code:</strong> ${object.properties.CNTY_NBR}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County FIPS:</strong> ${object.properties.CNTY_FIPS}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>TxDOT District:</strong> ${object.properties.DIST_NM}</p>
    </div>`,
        style: toolTipStyle,
      };
    if (layer.id === "districtBoundaries")
      return {
        html: `<div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">TxDOT Districts Layer</h5>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>${object.properties.DIST_NM} District</strong></p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>District Number:</strong> ${object.properties.DIST_NBR}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>District Abbreviation:</strong> ${object.properties.DIST_ABRVN_NM}</p>
    </div>`,
        style: toolTipStyle,
      };
    if (
      layer.id === "utp-points-1" ||
      layer.id === "utp-points-2" ||
      layer.id === "utp-points-3" ||
      layer.id === "utp-points-4"
    ) {
      let category = "UTP";
      // if (layer.id === "airports")
      //   airportCategory = "Other Major Airports Airports";
      return {
        html: `<div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">${category} Layer</h5>
      </div>
                  <b>Control Section Job: ${object.properties.CONTROL_SECT_JOB}</b>
                  <br />
                  <b>Road:</b> ${object.properties.HIGHWAY_NUMBER}
                  <br />
                  <b>From:</b> ${object.properties.LIMITS_FROM}
                  <br />
                  <b>To:</b> ${object.properties.LIMITS_TO}
                  <br />
                  <b>Type of Work:</b> ${object.properties.TYPE_OF_WORK}
                  <br />
                  <b>Layman Description:</b>
                  ${object.properties.LAYMAN_DESCRIPTION1}
                  <br />
                  <b>Phase:</b> ${object.properties.PT_PHASE}
                  <br />
                  <b>Freight Element:</b> ${object.properties.FREIGHT}
                  <br />
                  <b>
                    <h6>(Click on marker for more information)</h6>
                  </b>
                  <br />
                </div>`,
        style: toolTipStyle,
      };
    }

    return;
  }

  // /* Highlights selected Rhino */
  // useEffect(() => {
  //   /* I am cloning the rhino data.
  //    * Is it the best way to refresh the map
  //    * with highlights?
  //    */
  //   // let newRhino = { ...rhino };
  //   const isSelected = query.highlightQuery;
  //   if (typeof isSelected === "function") {
  //     rhino.features
  //       .filter((f) => isSelected(f))
  //       .forEach((f) => {
  //         f.color = COLOR_RHINO.selected;
  //         f.weight = 5;
  //       });
  //     rhino.features
  //       .filter((f) => !isSelected(f))
  //       //.forEach(f => (f.color = COLOR_RHINO.normal));
  //       //.forEach(f => (f.color = setColor(f)));
  //       .forEach((f) => {
  //         f.color = f.color_base;
  //         f.weight = f.weight_base;
  //       });
  //     // setTimeout(() => setRhino(newRhino), 100); .color_tdly color_tdly2

  //     if (rhino.features.filter((f) => isSelected(f)).length > 0) {
  //       const mapBounds_bbox = bbox(
  //         {
  //           type: "FeatureCollection",
  //           features: rhino.features.filter((f) => isSelected(f)),
  //         }
  //         //{ rhino }
  //       );

  //       // setmapBounds([
  //       //   [mapBounds_bbox[1] * 0.999, mapBounds_bbox[0] * 1.001],
  //       //   [mapBounds_bbox[3] * 1.001, mapBounds_bbox[2] * 0.999],
  //       // ]);

  //       // mapRef.current.leafletElement.flyToBounds([
  //       //   [mapBounds_bbox[1] * 0.999, mapBounds_bbox[0] * 1.001],
  //       //   [mapBounds_bbox[3] * 1.001, mapBounds_bbox[2] * 0.999],
  //       // ]);
  //     } else {
  //       // setmapBounds(homeMapBounds);
  //       // if (homeMapBounds)
  //       //   mapRef.current.leafletElement.flyToBounds([homeMapBounds]);
  //     }

  //     // setGeoJsonKey(Date.now());
  //   }
  // }, [query.highlightQuery]);

  // Functions for colors, line widths
  function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  function colorScale(height, minValue, maxValue) {
    //const color = d3.color(d3.interpolateRdYlGn(linearScale(height)));
    //const color = d3.color(d3.interpolatePuRd(linearScale(height)));
    //const color = d3.color(d3.interpolateOrRd(linearScale(height)));

    const linearScale = scaleLinear()
      .domain([minValue, maxValue])
      .range([0, 1]);

    //const color = colorF(interpolateTurbo(linearScale(height)));
    let color = colorF(interpolateTurbo(linearScale(height)));
    if (height === null)
      color = colorF(interpolateTurbo(linearScale(minValue)));
    return rgbToHex(color.r, color.g, color.b);
  }
  function colorScaleInv(height, minValue, maxValue) {
    //const color = d3.color(d3.interpolateRdYlGn(linearScale(height)));
    //const color = d3.color(d3.interpolatePuRd(linearScale(height)));
    //const color = d3.color(d3.interpolateOrRd(linearScale(height)));

    const linearScale = scaleLinear()
      .domain([minValue, maxValue])
      .range([1, 0]);

    //const color = colorF(interpolateTurbo(linearScale(height)));
    let color = colorF(interpolateTurbo(linearScale(height)));
    if (height === null)
      color = colorF(interpolateTurbo(linearScale(maxValue)));

    return rgbToHex(color.r, color.g, color.b);
  }

  function weightScale(height, minValue, maxValue) {
    const linearScale = scaleLinear()
      .domain([minValue, maxValue])
      .range([0, 5]);

    const weight = linearScale(height);

    return weight;
  }
  function weightScaleInv(height, minValue, maxValue) {
    const linearScale = scaleLinear()
      .domain([minValue, maxValue])
      .range([5, 0]);

    const weight = linearScale(height);

    return weight;
  }
  function maxCalc(compVal, value) {
    if (value !== null) {
      return Math.max(compVal, value);
    } else {
      return compVal;
    }
  }
  function minCalc(compVal, value) {
    if (value !== null) {
      return Math.min(compVal, value);
    } else {
      return compVal;
    }
  }
  function elevScaleF(height, minValue, maxValue) {
    const linearScaleElev = scaleLinear()
      .domain([minValue, maxValue])
      .range([0.0001, 5]);
    // .range([5, 0.001]);

    const elevation = linearScaleElev(height);

    return elevation;
  }
  function elevScaleInvF(height, minValue, maxValue) {
    const linearScaleElevInv = scaleLinear()
      .domain([minValue, maxValue])
      .range([5, 0.0001]);

    const elevation = linearScaleElevInv(height);

    return elevation;
  }

  useMemo(() => {
    if (rhino.features.length) {
      let geomType = setRoadGeomFeaturesType(rhino.features[0].properties);

      let rdSeqCat = "none";

      let minValue = 10000000;
      let maxValue = 0;

      let tempLineColorsWeight = rhinoLineColorsWeight;
      if (geomType === "rhino") {
        //if (query.roadSegmentCategory === "none") return f.color_normal
        if (query.roadSegmentCategory === "tdly") rdSeqCat = "trkdelay";
        if (query.roadSegmentCategory === "tpti95") rdSeqCat = "trkpti95";
        if (query.roadSegmentCategory === "tpti80") rdSeqCat = "trkpti80";
        if (query.roadSegmentCategory === "ttti") rdSeqCat = "trktti";
        if (query.roadSegmentCategory === "trefspd") rdSeqCat = "trkrefspee";
        if (query.roadSegmentCategory === "tcongspd") rdSeqCat = "trkspeed";
        if (query.roadSegmentCategory === "taadt") rdSeqCat = "tottrk";
        if (query.roadSegmentCategory === "tvmt") rdSeqCat = "trkttivmt";

        rhino.features.forEach((f) => {
          maxValue = maxCalc(maxValue, f.properties[rdSeqCat]);
          minValue = minCalc(minValue, f.properties[rdSeqCat]);
        });

        if (query.roadSegmentCategory === "tdly")
          setminTrkCatValue(minValue.toFixed(0));
        if (query.roadSegmentCategory === "tpti95")
          setminTrkCatValue(minValue.toFixed(2));
        if (query.roadSegmentCategory === "tpti80")
          setminTrkCatValue(minValue.toFixed(2));
        if (query.roadSegmentCategory === "ttti")
          setminTrkCatValue(minValue.toFixed(2));
        if (query.roadSegmentCategory === "trefspd")
          setminTrkCatValue(minValue.toFixed(2));
        if (query.roadSegmentCategory === "tcongspd")
          setminTrkCatValue(minValue.toFixed(2));
        if (query.roadSegmentCategory === "taadt")
          setminTrkCatValue(minValue.toFixed(0));
        if (query.roadSegmentCategory === "tvmt")
          setminTrkCatValue(minValue.toFixed(0));

        if (query.roadSegmentCategory === "tdly")
          setmaxTrkCatValue(maxValue.toFixed(0));
        if (query.roadSegmentCategory === "tpti95")
          setmaxTrkCatValue(maxValue.toFixed(2));
        if (query.roadSegmentCategory === "tpti80")
          setmaxTrkCatValue(maxValue.toFixed(2));
        if (query.roadSegmentCategory === "ttti")
          setmaxTrkCatValue(maxValue.toFixed(2));
        if (query.roadSegmentCategory === "trefspd")
          setmaxTrkCatValue(maxValue.toFixed(2));
        if (query.roadSegmentCategory === "tcongspd")
          setmaxTrkCatValue(maxValue.toFixed(2));
        if (query.roadSegmentCategory === "taadt")
          setmaxTrkCatValue(maxValue.toFixed(0));
        if (query.roadSegmentCategory === "tvmt")
          setmaxTrkCatValue(maxValue.toFixed(0));

        tempLineColorsWeight
          // .filter(
          //   (f) =>
          //     // !isSelected(f) &&
          //     f.rte_id !== userSelection.current.from_ria_rte_id
          // )
          // .forEach((f) => (f.color = COLOR_RHINO.selected))
          .forEach((f) => {
            if (query.roadSegmentCategory === "none") {
              f.color_base = COLOR_RHINO.normal
                .match(/[0-9a-f]{2}/g)
                .map((x) => parseInt(x, 16));
              f.color = f.color_base;
              f.weight_base = 1;
              f.weight = 1;
              f.elevScale = 1;
            } else {
              let tempValues = rhino.features.filter(
                (rf) =>
                  f.rte_id === rf.properties.ria_rte_id &&
                  f.frm_dfo === rf.properties.frm_dfo &&
                  f.to_dfo === rf.properties.to_dfo
              )[0].properties;
              if (
                query.roadSegmentCategory !== "tcongspd" &&
                query.roadSegmentCategory !== "trefspd"
              ) {
                f.color_base = colorScale(
                  tempValues[rdSeqCat],
                  minValue,
                  maxValue
                )
                  .match(/[0-9a-f]{2}/g)
                  .map((x) => parseInt(x, 16));
                f.weight_base = Math.max(
                  1,
                  weightScale(tempValues[rdSeqCat], minValue, maxValue) * 250
                );
                f.elevScale_base = elevScaleF(
                  tempValues[rdSeqCat],
                  minValue,
                  maxValue
                );
              } else {
                f.color_base = colorScaleInv(
                  tempValues[rdSeqCat],
                  minValue,
                  maxValue
                )
                  .match(/[0-9a-f]{2}/g)
                  .map((x) => parseInt(x, 16));
                f.weight_base = Math.max(
                  1,
                  weightScaleInv(tempValues[rdSeqCat], minValue, maxValue) * 250
                );
                f.elevScale_base = elevScaleInvF(
                  tempValues[rdSeqCat],
                  minValue,
                  maxValue
                );
              }
              f.color = f.color_base;
              f.weight = f.weight_base;
              f.elevScale = f.elevScale_base;
            }
          });
      } else {
        if (query.roadSegmentCategory === "tdly") rdSeqCat = "tdly";
        if (query.roadSegmentCategory === "trank") rdSeqCat = "trank";
        if (query.roadSegmentCategory === "tdmile") rdSeqCat = "tdmile";
        if (query.roadSegmentCategory === "ttci") rdSeqCat = "ttci";
        if (query.roadSegmentCategory === "tpti") rdSeqCat = "tpti";
        if (query.roadSegmentCategory === "tcost") rdSeqCat = "tcost";

        rhino.features.forEach((f) => {
          maxValue = maxCalc(maxValue, f.properties[rdSeqCat]);
          minValue = minCalc(minValue, f.properties[rdSeqCat]);
        });

        if (query.roadSegmentCategory === "tdly")
          setminTrkCatValue(minValue.toFixed(0));
        if (query.roadSegmentCategory === "trank")
          setminTrkCatValue(minValue.toFixed(0));
        if (query.roadSegmentCategory === "tdmile")
          setminTrkCatValue(minValue.toFixed(2));
        if (query.roadSegmentCategory === "ttci")
          setminTrkCatValue(minValue.toFixed(2));
        if (query.roadSegmentCategory === "tpti")
          setminTrkCatValue(minValue.toFixed(2));
        if (query.roadSegmentCategory === "tcost")
          setminTrkCatValue(minValue.toFixed(2));

        if (query.roadSegmentCategory === "tdly")
          setmaxTrkCatValue(maxValue.toFixed(0));
        if (query.roadSegmentCategory === "trank")
          setmaxTrkCatValue(maxValue.toFixed(0));
        if (query.roadSegmentCategory === "tdmile")
          setmaxTrkCatValue(maxValue.toFixed(2));
        if (query.roadSegmentCategory === "ttci")
          setmaxTrkCatValue(maxValue.toFixed(2));
        if (query.roadSegmentCategory === "tpti")
          setmaxTrkCatValue(maxValue.toFixed(2));
        if (query.roadSegmentCategory === "tcost")
          setmaxTrkCatValue(maxValue.toFixed(2));

        tempLineColorsWeight
          // .filter((f) => !isSelected(f))
          .forEach((f) => {
            if (query.roadSegmentCategory === "none") {
              f.color_base = COLOR_RHINO.normal
                .match(/[0-9a-f]{2}/g)
                .map((x) => parseInt(x, 16));
              f.color = f.color_base;
              f.weight_base = 1;
              f.weight = 1;
              f.elevScale = 1;
            } else {
              let tempValues = rhino.features.filter(
                (rf) =>
                  f.rte_id === rf.properties.top100id &&
                  f.frm_dfo === rf.properties.from_road &&
                  f.to_dfo === rf.properties.to_road
              )[0].properties;
              if (query.roadSegmentCategory !== "trank") {
                f.color_base = colorScale(
                  tempValues[rdSeqCat],
                  minValue,
                  maxValue
                )
                  .match(/[0-9a-f]{2}/g)
                  .map((x) => parseInt(x, 16));
                f.weight_base =
                  weightScaleInv(tempValues[rdSeqCat], minValue, maxValue) *
                  0.05;
                f.elevScale_base = elevScaleF(
                  tempValues[rdSeqCat],
                  minValue,
                  maxValue
                );
              } else {
                f.color_base = colorScaleInv(
                  tempValues[rdSeqCat],
                  minValue,
                  maxValue
                )
                  .match(/[0-9a-f]{2}/g)
                  .map((x) => parseInt(x, 16));
                f.weight_base =
                  weightScaleInv(tempValues[rdSeqCat], minValue, maxValue) *
                  0.5;

                f.elevScale_base = elevScaleInvF(
                  tempValues[rdSeqCat],
                  minValue,
                  maxValue
                );
              }
              f.color = f.color_base;
              f.weight = f.weight_base;
              f.elevScale = f.elevScale_base;
              // f.elevScale = 1000;
            }
          });
      }
    }
  }, [query.roadSegmentCategory]);

  useEffect(() => {
    if (rhino.features.length) {
      let tempLineColorsWeight = rhinoLineColorsWeight;
      if (userSelection.current.from_ria_rte_id !== "") {
        // tempLineColorsWeight
        //   .filter((f) => f.rte_id === userSelection.current.from_ria_rte_id)
        //   .forEach((f) => {
        //     f.color = COLOR_RHINO.selectedSection
        //       .match(/[0-9a-f]{2}/g)
        //       .map((x) => parseInt(x, 16));
        //     f.weight = f.weight;
        //     f.elevScale = f.elevScale;
        //   });
        tempLineColorsWeight
          .filter((f) => f.rte_id !== userSelection.current.from_ria_rte_id)
          .forEach((f) => {
            f.color = COLOR_RHINO.notSelected
              .match(/[0-9a-f]{2}/g)
              .map((x) => parseInt(x, 16));
            f.weight = 0.01;
            f.elevScale = 0.01;
          });
      } else {
        tempLineColorsWeight
          // .filter((f) => f.rte_id === featureProps.ria_rte_id)
          .forEach((f) => {
            f.color = f.color_base;
            f.weight = f.weight_base;
            f.elevScale = f.elevScale_base;
          });
      }
      setRhinoLineColorsWeight(tempLineColorsWeight);
    }
  }, [userSelection.current]);

  useEffect(() => {
    if (rhino.features.length) {
      const isSelected = query.highlightQuery;
      let tempLineColorsWeight = rhinoLineColorsWeight;
      let visibleFeatures = [];
      let colorTemp = [];
      if (tempLineColorsWeight.filter((f) => isSelected(f)).length > 0) {
        tempLineColorsWeight
          .filter((f) => isSelected(f))
          .forEach((f) => {
            f.color = f.color_base;
            f.weight = 5;
            f.elevScale = f.elevScale_base;
          });
        tempLineColorsWeight
          .filter((f) => !isSelected(f))
          .forEach((f) => {
            // colorTemp = COLOR_RHINO.notSelected
            //   .match(/[0-9a-f]{2}/g)
            //   .map((x) => parseInt(x, 16));
            // colorTemp.push(200);
            f.color = COLOR_RHINO.notSelected
              .match(/[0-9a-f]{2}/g)
              .map((x) => parseInt(x, 16));
            f.weight = 0.001;
            f.elevScale = 0.001;
          });

        rhino.features
          .filter((f) => isSelected(f.properties))
          .forEach((obj) => {
            visibleFeatures.push(obj);
          });
      } else {
        tempLineColorsWeight
          // .filter((f) => f.rte_id === featureProps.ria_rte_id)
          .forEach((f) => {
            f.color = f.color_base;
            f.weight = f.weight_base;
            f.elevScale = f.elevScale_base;
          });

        visibleFeatures = rhino.features;
      }
      setRhinoLineColorsWeight(tempLineColorsWeight);
      setHomeMapBounds(getBoundsForRoads(visibleFeatures));
    }
  }, [query.highlightQuery]);

  // useMemo(() => {
  //   if (rhino.features.length) {
  //     const isSelected = query.highlightQuery;
  //     if (typeof isSelected === "function") {
  //       let geomType = setRoadGeomFeaturesType(rhino.features[0].properties);

  //       let rdSeqCat = "none";

  //       if (geomType === "rhino") {
  //         //if (query.roadSegmentCategory === "none") return f.color_normal
  //         if (query.roadSegmentCategory === "tdly") rdSeqCat = "trkdelay";
  //         if (query.roadSegmentCategory === "tpti95") rdSeqCat = "trkpti95";
  //         if (query.roadSegmentCategory === "tpti80") rdSeqCat = "trkpti80";
  //         if (query.roadSegmentCategory === "ttti") rdSeqCat = "trktti";
  //         if (query.roadSegmentCategory === "trefspd")
  //           rdSeqCat = "trkrefspee";
  //         if (query.roadSegmentCategory === "tcongspd") rdSeqCat = "trkspeed";
  //         if (query.roadSegmentCategory === "taadt") rdSeqCat = "tottrk";
  //         if (query.roadSegmentCategory === "tvmt") rdSeqCat = "trkttivmt";
  //       } else {
  //         if (query.roadSegmentCategory === "tdly") rdSeqCat = "tdly";
  //         if (query.roadSegmentCategory === "trank") rdSeqCat = "trank";
  //         if (query.roadSegmentCategory === "tdmile") rdSeqCat = "tdmile";
  //         if (query.roadSegmentCategory === "ttci") rdSeqCat = "ttci";
  //         if (query.roadSegmentCategory === "tpti") rdSeqCat = "tpti";
  //         if (query.roadSegmentCategory === "tcost") rdSeqCat = "tcost";
  //       }

  //       function rgbToHex(r, g, b) {
  //         return (
  //           "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
  //         );
  //       }

  //       function colorScale(height, minValue, maxValue) {
  //         //const color = d3.color(d3.interpolateRdYlGn(linearScale(height)));
  //         //const color = d3.color(d3.interpolatePuRd(linearScale(height)));
  //         //const color = d3.color(d3.interpolateOrRd(linearScale(height)));

  //         const linearScale = scaleLinear()
  //           .domain([minValue, maxValue])
  //           .range([0, 1]);

  //         //const color = colorF(interpolateTurbo(linearScale(height)));
  //         let color = colorF(interpolateTurbo(linearScale(height)));
  //         if (height === null)
  //           color = colorF(interpolateTurbo(linearScale(minValue)));
  //         return rgbToHex(color.r, color.g, color.b);
  //       }
  //       function colorScaleInv(height, minValue, maxValue) {
  //         //const color = d3.color(d3.interpolateRdYlGn(linearScale(height)));
  //         //const color = d3.color(d3.interpolatePuRd(linearScale(height)));
  //         //const color = d3.color(d3.interpolateOrRd(linearScale(height)));

  //         const linearScale = scaleLinear()
  //           .domain([minValue, maxValue])
  //           .range([1, 0]);

  //         //const color = colorF(interpolateTurbo(linearScale(height)));
  //         let color = colorF(interpolateTurbo(linearScale(height)));
  //         if (height === null)
  //           color = colorF(interpolateTurbo(linearScale(maxValue)));

  //         return rgbToHex(color.r, color.g, color.b);
  //       }

  //       function weightScale(height, minValue, maxValue) {
  //         const linearScale = scaleLinear()
  //           .domain([minValue, maxValue])
  //           .range([0, 1]);

  //         const weight = linearScale(height);

  //         return weight;
  //       }
  //       function weightScaleInv(height, minValue, maxValue) {
  //         const linearScale = scaleLinear()
  //           .domain([minValue, maxValue])
  //           .range([1, 0]);

  //         const weight = linearScale(height);

  //         return weight;
  //       }

  //       let minValue = 10000000;
  //       let maxValue = 0;
  //       function maxCalc(compVal, value) {
  //         if (value !== null) {
  //           return Math.max(compVal, value);
  //         } else {
  //           return compVal;
  //         }
  //       }
  //       function minCalc(compVal, value) {
  //         if (value !== null) {
  //           return Math.min(compVal, value);
  //         } else {
  //           return compVal;
  //         }
  //       }
  //       rhino.features.forEach(
  //         (f) => (
  //           (maxValue = maxCalc(maxValue, f.properties[rdSeqCat])),
  //           (minValue = minCalc(minValue, f.properties[rdSeqCat]))
  //         )
  //       );
  //       // rhino.features.forEach(
  //       //   (f) => (
  //       //     (maxValue = Math.max(maxValue, f.properties[rdSeqCat])),
  //       //     (minValue = Math.min(minValue, f.properties[rdSeqCat]))
  //       //   )
  //       // );

  //       if (geomType === "rhino") {
  //         if (query.roadSegmentCategory === "tdly")
  //           setminTrkCatValue(minValue.toFixed(0));
  //         if (query.roadSegmentCategory === "tpti95")
  //           setminTrkCatValue(minValue.toFixed(2));
  //         if (query.roadSegmentCategory === "tpti80")
  //           setminTrkCatValue(minValue.toFixed(2));
  //         if (query.roadSegmentCategory === "ttti")
  //           setminTrkCatValue(minValue.toFixed(2));
  //         if (query.roadSegmentCategory === "trefspd")
  //           setminTrkCatValue(minValue.toFixed(2));
  //         if (query.roadSegmentCategory === "tcongspd")
  //           setminTrkCatValue(minValue.toFixed(2));
  //         if (query.roadSegmentCategory === "taadt")
  //           setminTrkCatValue(minValue.toFixed(0));
  //         if (query.roadSegmentCategory === "tvmt")
  //           setminTrkCatValue(minValue.toFixed(0));

  //         if (query.roadSegmentCategory === "tdly")
  //           setmaxTrkCatValue(maxValue.toFixed(0));
  //         if (query.roadSegmentCategory === "tpti95")
  //           setmaxTrkCatValue(maxValue.toFixed(2));
  //         if (query.roadSegmentCategory === "tpti80")
  //           setmaxTrkCatValue(maxValue.toFixed(2));
  //         if (query.roadSegmentCategory === "ttti")
  //           setmaxTrkCatValue(maxValue.toFixed(2));
  //         if (query.roadSegmentCategory === "trefspd")
  //           setmaxTrkCatValue(maxValue.toFixed(2));
  //         if (query.roadSegmentCategory === "tcongspd")
  //           setmaxTrkCatValue(maxValue.toFixed(2));
  //         if (query.roadSegmentCategory === "taadt")
  //           setmaxTrkCatValue(maxValue.toFixed(0));
  //         if (query.roadSegmentCategory === "tvmt")
  //           setmaxTrkCatValue(maxValue.toFixed(0));
  //       } else {
  //         if (query.roadSegmentCategory === "tdly")
  //           setminTrkCatValue(minValue.toFixed(0));
  //         if (query.roadSegmentCategory === "trank")
  //           setminTrkCatValue(minValue.toFixed(0));
  //         if (query.roadSegmentCategory === "tdmile")
  //           setminTrkCatValue(minValue.toFixed(2));
  //         if (query.roadSegmentCategory === "ttci")
  //           setminTrkCatValue(minValue.toFixed(2));
  //         if (query.roadSegmentCategory === "tpti")
  //           setminTrkCatValue(minValue.toFixed(2));
  //         if (query.roadSegmentCategory === "tcost")
  //           setminTrkCatValue(minValue.toFixed(2));

  //         if (query.roadSegmentCategory === "tdly")
  //           setmaxTrkCatValue(maxValue.toFixed(0));
  //         if (query.roadSegmentCategory === "trank")
  //           setmaxTrkCatValue(maxValue.toFixed(0));
  //         if (query.roadSegmentCategory === "tdmile")
  //           setmaxTrkCatValue(maxValue.toFixed(2));
  //         if (query.roadSegmentCategory === "ttci")
  //           setmaxTrkCatValue(maxValue.toFixed(2));
  //         if (query.roadSegmentCategory === "tpti")
  //           setmaxTrkCatValue(maxValue.toFixed(2));
  //         if (query.roadSegmentCategory === "tcost")
  //           setmaxTrkCatValue(maxValue.toFixed(2));
  //       }

  //       function elevScaleF(height, minValue, maxValue) {
  //         const linearScaleElev = scaleLinear()
  //           .domain([minValue, maxValue])
  //           .range([0.001, 5]);
  //         // .range([5, 0.001]);

  //         const elevation = linearScaleElev(height);

  //         return elevation;
  //       }
  //       function elevScaleInvF(height, minValue, maxValue) {
  //         const linearScaleElevInv = scaleLinear()
  //           .domain([minValue, maxValue])
  //           .range([5, 0.001]);

  //         const elevation = linearScaleElevInv(height);

  //         return elevation;
  //       }

  //       let tempLineColorsWeight = rhinoLineColorsWeight;
  //       // rhino.features
  //       //   .filter((f) => isSelected(f))
  //       //   // .forEach((f) => (f.color = COLOR_RHINO.selected))
  //       //   .forEach((f) => {
  //       //     f.color = COLOR_RHINO.selected;
  //       //     f.properties.color = COLOR_RHINO.selected;
  //       //   });

  //       // tempLineColorsWeight
  //       //   .filter((f) => isSelected(f))
  //       //   .forEach((f) => {
  //       //     f.color = COLOR_RHINO.selected
  //       //       .match(/[0-9a-f]{2}/g)
  //       //       .map((x) => parseInt(x, 16));
  //       //   });

  //       if (geomType === "rhino") {
  //         // if (userSelection.current.from_ria_rte_id !== "") {
  //         //   console.log("userSelection.current-check-good");
  //         //   tempLineColorsWeight
  //         //     .filter((f) => f.rte_id === userSelection.current.from_ria_rte_id)
  //         //     .forEach((f) => {
  //         //       f.color = COLOR_RHINO.selectedSection
  //         //         .match(/[0-9a-f]{2}/g)
  //         //         .map((x) => parseInt(x, 16));
  //         //     });
  //         // }

  //         tempLineColorsWeight
  //           .filter(
  //             (f) =>
  //               // !isSelected(f) &&
  //               f.rte_id !== userSelection.current.from_ria_rte_id
  //           )
  //           // .forEach((f) => (f.color = COLOR_RHINO.selected))
  //           .forEach((f) => {
  //             if (query.roadSegmentCategory === "none") {
  //               f.color_base = COLOR_RHINO.normal
  //                 .match(/[0-9a-f]{2}/g)
  //                 .map((x) => parseInt(x, 16));
  //               f.color = f.color_base;
  //               f.weight_base = 1;
  //               f.weight = 1;
  //               f.elevScale = 1;
  //             } else {
  //               let tempValues = rhino.features.filter(
  //                 (rf) =>
  //                   f.rte_id === rf.properties.ria_rte_id &&
  //                   f.frm_dfo === rf.properties.frm_dfo &&
  //                   f.to_dfo === rf.properties.to_dfo
  //               )[0].properties;
  //               if (
  //                 query.roadSegmentCategory !== "tcongspd" &&
  //                 query.roadSegmentCategory !== "trefspd"
  //               ) {
  //                 f.color_base = colorScale(
  //                   tempValues[rdSeqCat],
  //                   minValue,
  //                   maxValue
  //                 )
  //                   .match(/[0-9a-f]{2}/g)
  //                   .map((x) => parseInt(x, 16));
  //                 f.weight_base = Math.max(
  //                   1,
  //                   weightScale(tempValues[rdSeqCat], minValue, maxValue) * 5
  //                 );
  //                 f.elevScale = elevScaleF(
  //                   tempValues[rdSeqCat],
  //                   minValue,
  //                   maxValue
  //                 );
  //               } else {
  //                 f.color_base = colorScaleInv(
  //                   tempValues[rdSeqCat],
  //                   minValue,
  //                   maxValue
  //                 )
  //                   .match(/[0-9a-f]{2}/g)
  //                   .map((x) => parseInt(x, 16));
  //                 f.weight_base = Math.max(
  //                   1,
  //                   weightScaleInv(tempValues[rdSeqCat], minValue, maxValue) *
  //                     5
  //                 );
  //                 f.elevScale = elevScaleInvF(
  //                   tempValues[rdSeqCat],
  //                   minValue,
  //                   maxValue
  //                 );
  //               }
  //               f.color = f.color_base;
  //               f.weight = f.weight_base;
  //             }
  //           });
  //       } else {
  //         tempLineColorsWeight
  //           // .filter((f) => !isSelected(f))
  //           .forEach((f) => {
  //             if (query.roadSegmentCategory === "none") {
  //               f.color_base = COLOR_RHINO.normal
  //                 .match(/[0-9a-f]{2}/g)
  //                 .map((x) => parseInt(x, 16));
  //               f.color = f.color_base;
  //               f.weight_base = 1;
  //               f.weight = 1;
  //               f.elevScale = 1;
  //             } else {
  //               let tempValues = rhino.features.filter(
  //                 (rf) =>
  //                   f.rte_id === rf.properties.top100id &&
  //                   f.frm_dfo === rf.properties.from_road &&
  //                   f.to_dfo === rf.properties.to_road
  //               )[0].properties;
  //               if (query.roadSegmentCategory !== "trank") {
  //                 f.color_base = colorScale(
  //                   tempValues[rdSeqCat],
  //                   minValue,
  //                   maxValue
  //                 )
  //                   .match(/[0-9a-f]{2}/g)
  //                   .map((x) => parseInt(x, 16));
  //                 f.weight_base = Math.max(
  //                   1,
  //                   weightScale(tempValues[rdSeqCat], minValue, maxValue) * 5
  //                 );
  //                 f.elevScale = elevScaleF(
  //                   tempValues[rdSeqCat],
  //                   minValue,
  //                   maxValue
  //                 );
  //               } else {
  //                 f.color_base = colorScaleInv(
  //                   tempValues[rdSeqCat],
  //                   minValue,
  //                   maxValue
  //                 )
  //                   .match(/[0-9a-f]{2}/g)
  //                   .map((x) => parseInt(x, 16));
  //                 f.weight_base = Math.max(
  //                   1,
  //                   weightScaleInv(tempValues[rdSeqCat], minValue, maxValue) *
  //                     5
  //                 );

  //                 f.elevScale = elevScaleInvF(
  //                   tempValues[rdSeqCat],
  //                   minValue,
  //                   maxValue
  //                 );
  //               }
  //               f.color = f.color_base;
  //               f.weight = f.weight_base;
  //               // f.elevScale = 1000;
  //             }
  //           });
  //       }

  //       if (tempLineColorsWeight.filter((f) => isSelected(f)).length > 0) {
  //         // console.log(
  //         //   "TEST GOOD",
  //         //   tempLineColorsWeight.filter((f) => !isSelected(f))
  //         // );
  //         tempLineColorsWeight
  //           .filter((f) => !isSelected(f))
  //           .forEach((f) => {
  //             f.color = COLOR_RHINO.notSelected
  //               .match(/[0-9a-f]{2}/g)
  //               .map((x) => parseInt(x, 16));
  //             f.weight = 0.01;
  //             f.elevScale = 0.01;
  //           });
  //       }
  //       setRhinoLineColorsWeight(tempLineColorsWeight);

  //       setRoadGeomFeatures({
  //         features: rhino.features,
  //         //  features: roadGeomFeatures.features,
  //         numFeatures: rhino.features.length,
  //         type: setRoadGeomFeaturesType(rhino.features[0].properties),
  //         minValue: minValue,
  //         maxValue: maxValue,
  //       });

  //       let testFC = [];
  //       console.log("rhino.features-check", rhino.features);
  //       if (geomType === "rhino") {
  //         rhino.features
  //           .filter((f) => isSelected(f.properties))
  //           .forEach((obj) => {
  //             testFC.push(obj);
  //           });
  //       } else {
  //         rhino.features
  //           .filter((f) => isSelected(f.properties))
  //           .forEach((obj) => {
  //             testFC.push(obj);
  //           });
  //       }

  //       if (testFC.length > 0) {
  //         const mapBounds_bbox = bbox(
  //           {
  //             type: "FeatureCollection",
  //             features: testFC,
  //           }
  //           //{ rhino }
  //         );
  //         setHomeMapBounds(getBoundsForRoads(mapBounds_bbox));
  //       } else {
  //         const mapBounds_bbox = bbox(
  //           {
  //             type: "FeatureCollection",
  //             features: rhino.features,
  //           }
  //           //{ rhino }
  //         );
  //         setHomeMapBounds(getBoundsForRoads(mapBounds_bbox));
  //       }

  //       // setGeoJsonKey(Date.now());
  //     }
  //   }
  // }, [
  //   query.roadSegmentCategory,
  //   query.highlightQuery,
  //   userSelection.current,
  // ]);

  function setRoadGeomFeaturesType(obj) {
    if (obj.top100id) return "tx100";
    return "rhino";
  }

  const filterPhases = [
    "begins soon",
    "4 years",
    "5 to 10 years",
    "10+ years",
  ].map((val) => {
    return (fc) => fc.properties.PT_PHASE.includes(val);
  });

  const [activePopupUtp, setActivePopupUtp] = useState(false);
  const renderPopupUtp = (d) => {
    // function renderPopupUtp(obj) {
    if (activePopupUtp) {
      if (d)
        return (
          <Popup
            key="popup"
            //longitude={-99.45833}
            //latitude={31.243333}
            longitude={d.coordinate[0]}
            latitude={d.coordinate[1]}
            // closeButton={true}
            // onClose={setSelectedPoint(null)}
            //closeOnClick={false}
            closeButton={true}
            //closeOnClick={false}
            onClose={() => setActivePopupUtp(false)}
            // tipSize={25}
            // anchor={"top"}
            // dynamicPosition={false}
          >
            <b>Control Section Job: {d.object.properties.CONTROL_SECT_JOB} </b>
            <br />
            <b>Road:</b> {d.object.properties.HIGHWAY_NUMBER}
            <br />
            <b>From:</b> {d.object.properties.LIMITS_FROM}
            <br />
            <b>To:</b> {d.object.properties.LIMITS_TO}
            <br />
            <b>Type of Work:</b> {d.object.properties.TYPE_OF_WORK}
            <br />
            <b>Layman Description:</b> {d.object.properties.LAYMAN_DESCRIPTION1}
            <br />
            <b>Phase:</b> {d.object.properties.PT_PHASE}
            <br />
            <b>UTP Project Tier:</b> {d.object.properties.PRJ_TIER}
            <br />
            <b>UTP Level of Authority:</b> {d.object.properties.PRIORITY_CODE}
            <br />
            <b>Freight Element:</b> {d.object.properties.FREIGHT}
            <br />
            <b>Project Let Date:</b> {d.object.properties.ACTUAL_LET_DATE}
            <br />
            <b>Month of Estimated Project Let Date:</b>{" "}
            {d.object.properties.NBR_LET_MONTH}
            <br />
            <b>Year of Estimated Project Let Date:</b>{" "}
            {d.object.properties.NBR_LET_YEAR}
            <br />
            <a
              href={
                "https://gis-txdot.opendata.arcgis.com/datasets/txdot-projects/data?orderBy=CONTROL_SECT_JOB&where=CONTROL_SECT_JOB%20%3D%20%27" +
                d.object.properties.CONTROL_SECT_JOB +
                "%27"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              TxDOT Open Data Portal - Project
            </a>
          </Popup>
        );
    }
    return;
  };

  let currentContentHeight = 1;
  try {
    currentContentHeight = document.querySelector(`#map`).offsetHeight;
  } catch {
    currentContentHeight = 1;
  }

  return (
    // <div onContextMenu={(e)=> e.preventDefault()}... />
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
      }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <DeckGL
        {...viewport}
        // initialViewState={initialViewState}
        initialViewState={{
          longitude: homeMapBounds.longitude,
          latitude: homeMapBounds.latitude,
          zoom: homeMapBounds.zoom,
          pitch: 0,
          // pitch: homeMapBounds.pitch,
          bearing: 0,
          transitionDuration: 2000,
          // transitionInterpolator: new FlyToInterpolator(),
        }}
        controller={true}
        // layers={newLayers2}
        // layers={[layers2]}
        // layers={[layers3]}
        ContextProvider={MapContext.Provider}
        // getTooltip={({ object }) =>
        //   object &&
        //   (object.properties.road ||
        //     "<br />" ||
        //     object.properties.from_road ||
        //     object.properties.to_road)
        // }
        getTooltip={
          ({ layer, object }) => object && deckGlTooltip(layer, object)
          //   html: `<h5>${object.properties.road}</h5><div>From: ${object.properties.from_road}</div>
          // <div>To: ${object.properties.to_road}</div>
          // <div>arank: ${object.properties.arank}</div>
          // <div>trank: ${object.properties.trank}</div>`,
          //   style: {
          //     backgroundColor: "#e1e7ed",
          //     color: "000000",
          //     fontSize: "1.0em",
          //   },
          // }
        }
        onViewStateChange={({ viewState }) => {
          // console.log("viewState-change", viewState);
          // setZoomLevel(viewState.zoom);

          let zoomScaleTemp = 1;
          if (viewState.zoom > 4.85)
            zoomScaleTemp = 4.85 / (viewState.zoom * viewState.zoom);
          setZoomLevel({
            zoomLevel: viewState.zoom,
            zoomScale: zoomScaleTemp,
            // pitch: viewState.pitch,
          });

          // buildGeoJsonLayer();
        }}
      >
        <StaticMap
          reuseMaps
          // mapStyle={"mapbox://styles/mapbox/streets-v11"}
          mapStyle={baseMap}
          mapboxApiAccessToken={MAPBOX_ACCESSTOKEN}
          preventStyleDiffing={true}
        />
        {renderPopup()}
        {renderPopupUtp(selectedUtpData)}
        {isLoading && (
          <CircularProgress
            style={{
              marginLeft: "50%",
              marginTop: "15%",
            }}
          />
        )}
        {/* <GeoJsonLayer
        id="geojson"
        data={rhino}
        filled="true"
        opacity={0.91}
      ></GeoJsonLayer> */}
        {/* 
        <PathLayer
          id="path-layer"
          data={roadGeomFeatures.features}
          pickable={true}
          widthScale={20}
          widthMinPixels={2}
          // getPath={(d) => d.path}
          getPath={(d) => d.geometry.coordinates}
          // getColor={(d) => colorToRGBArray(d.color)}
          getColor={(d, index) => {
            let colorWeightTemp = rhinoLineColorsWeight[index.index];
            let opacityTemp = 125 * colorWeightTemp.weight;
            if (query.roadSegmentCategory === "none") opacityTemp = 200;
            let colorTemp = colorWeightTemp.color;

            return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
          }}
          getWidth={(d) => 5}
        /> */}
        {roadLayerVisibility.lines && (
          <GeoJsonLayer
            id="roads"
            data={rhino}
            // data={rhinoPolygon}
            visible={roadLayerVisibility.lines}
            // filled: true,
            // opacity: 0.01,
            pickable={true}
            pickingRadius={5}
            stroked={false}
            // filled={true}
            filled={false}
            // extruded={true}
            extruded={false}
            // pointType={"circle"}
            // lineWidthScale={5}
            lineWidthMinPixels={2}
            // getPointRadius={100}
            getLineWidth={(d, index) => {
              let colorWeightTemp = rhinoLineColorsWeight[index.index];

              // if (
              //   selectedPoint &&
              //   statusGuide[0] === "TX100" &&
              //   d.properties.gid === selectedPoint.object.properties.gid
              // )
              //   // if (selectedPoint)
              //   return colorWeightTemp[0].weight * 5;
              // if (
              //   selectedPoint &&
              //   statusGuide[0] !== "TX100" &&
              //   d.properties.ogc_fid_new ===
              //     selectedPoint.object.properties.ogc_fid_new
              // )
              //   return colorWeightTemp[0].weight * 5;
              // if (
              //   userSelection.current.from_ria_rte_id &&
              //   statusGuide[0] !== "TX100" &&
              //   d.properties.ria_rte_id === userSelection.current.from_ria_rte_id
              // )
              //   return colorWeightTemp[0].weight * 5;
              // if (d.color === COLOR_RHINO.selected)
              //   return colorWeightTemp[0].weight * (5 / zoomLevel);

              return colorWeightTemp.weight;
            }}
            // getElevation={(d, index) => {
            //   // if (d.properties.trank < 100)
            //   //   return 2500 * (100 / d.properties.arank);
            //   // return 2500;
            //   let colorWeightTemp = rhinoLineColorsWeight[index.index];
            //   // if (statusGuide[0] === "TX100")
            //   //   return 2500 * (100 / d.properties.trank);
            //   return 1500 * colorWeightTemp.weight;
            // }}
            onClick={(d, event) => {
              setActivePopup(true);
              setSelectedPoint(d);
            }}
            onHover={(d, event) => {
              if (d.object !== undefined && statusGuide[0] === "TX100")
                setInfo(tx100Info(d.object.properties));
              if (d.object !== undefined && statusGuide[0] !== "TX100")
                setInfo(rhinoInfo(d.object.properties));
            }}
            autoHighlight={true}
            highlightColor={COLOR_RHINO.selected
              .match(/[0-9a-f]{2}/g)
              .map((x) => parseInt(x, 16))}
            getLineColor={(d, index) => {
              // let opacityTemp = 125 * d.properties.weight;
              // if (query.roadSegmentCategory === "none") opacityTemp = 200;
              // let colorWeightTemp = rhinoLineColorsWeight.filter(
              //   (obj) =>
              //     obj.rte_id ===
              //       d.properties.ria_rte_id &&
              //     obj.frm_dfo ===
              //       d.properties.frm_dfo &&
              //     obj.to_dfo === d.properties.to_dfo
              // );
              // let opacityTemp =
              //   125 * colorWeightTemp[0].weight;
              // if (
              //   query.roadSegmentCategory === "none"
              // )
              //   opacityTemp = 200;
              // let colorTemp = colorWeightTemp[0].color
              //   .match(/[0-9a-f]{2}/g)
              //   .map((x) => parseInt(x, 16));

              // // let colorWeightTemp = rhinoLineColorsWeight[index];
              // console.log(
              //   "colorWeightTemp - check",
              //   index.index
              // );
              let colorWeightTemp = rhinoLineColorsWeight[index.index];
              let opacityTemp = 3 * colorWeightTemp.weight;
              if (query.roadSegmentCategory === "none") opacityTemp = 200;
              let colorTemp = colorWeightTemp.color;
              // if (
              //   selectedPoint &&
              //   statusGuide[0] === "TX100" &&
              //   d.properties.gid === selectedPoint.object.properties.gid
              // ) {
              //   colorTemp = COLOR_RHINO.selected
              //     .match(/[0-9a-f]{2}/g)
              //     .map((x) => parseInt(x, 16));
              //   return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
              // }

              // if (
              //   selectedPoint &&
              //   statusGuide[0] !== "TX100" &&
              //   d.properties.ogc_fid_new ===
              //     selectedPoint.object.properties.ogc_fid_new
              // ) {
              //   colorTemp = COLOR_RHINO.selected
              //     .match(/[0-9a-f]{2}/g)
              //     .map((x) => parseInt(x, 16));
              //   return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
              // }
              // if (
              //   userSelection.current.from_ria_rte_id !== "" &&
              //   statusGuide[0] !== "TX100" &&
              //   d.properties.ria_rte_id === userSelection.current.from_ria_rte_id
              // ) {
              //   colorTemp = COLOR_RHINO.selectedSection
              //     .match(/[0-9a-f]{2}/g)
              //     .map((x) => parseInt(x, 16));
              //   return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
              // }

              return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
            }}
            // getFillColor={(d, index) => {
            //   // let opacityTemp = 125 * d.properties.weight;
            //   // if (query.roadSegmentCategory === "none") opacityTemp = 200;
            //   // let colorTemp = d.properties.color
            //   //   .match(/[0-9a-f]{2}/g)
            //   //   .map((x) => parseInt(x, 16));
            //   // let colorWeightTemp = rhinoLineColorsWeight.filter(
            //   //   (obj) =>
            //   //     obj.rte_id === d.properties.ria_rte_id &&
            //   //     obj.frm_dfo === d.properties.frm_dfo &&
            //   //     obj.to_dfo === d.properties.to_dfo
            //   // );
            //   // let opacityTemp = 125 * colorWeightTemp[0].weight;
            //   // if (query.roadSegmentCategory === "none") opacityTemp = 200;
            //   // let colorTemp = colorWeightTemp[0].color
            //   //   .match(/[0-9a-f]{2}/g)
            //   //   .map((x) => parseInt(x, 16));

            //   let colorWeightTemp = rhinoLineColorsWeight[index.index];
            //   let opacityTemp = 125 * colorWeightTemp.weight;
            //   if (query.roadSegmentCategory === "none") opacityTemp = 200;
            //   let colorTemp = colorWeightTemp.color;

            //   // if (
            //   //   selectedPoint &&
            //   //   statusGuide[0] === "TX100" &&
            //   //   d.properties.gid === selectedPoint.object.properties.gid
            //   // ) {
            //   //   colorTemp = COLOR_RHINO.selected
            //   //     .match(/[0-9a-f]{2}/g)
            //   //     .map((x) => parseInt(x, 16));
            //   //   return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
            //   // }

            //   // if (
            //   //   selectedPoint &&
            //   //   statusGuide[0] !== "TX100" &&
            //   //   d.properties.ogc_fid_new ===
            //   //     selectedPoint.object.properties.ogc_fid_new
            //   // ) {
            //   //   colorTemp = COLOR_RHINO.selected
            //   //     .match(/[0-9a-f]{2}/g)
            //   //     .map((x) => parseInt(x, 16));
            //   //   return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
            //   // }
            //   // if (
            //   //   userSelection.current.from_ria_rte_id !== "" &&
            //   //   statusGuide[0] !== "TX100" &&
            //   //   d.properties.ria_rte_id === userSelection.current.from_ria_rte_id
            //   // ) {
            //   //   // if (selectedPoint)
            //   //   colorTemp = COLOR_RHINO.selectedSection
            //   //     .match(/[0-9a-f]{2}/g)
            //   //     .map((x) => parseInt(x, 16));
            //   //   return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
            //   // }

            //   return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
            // }}
            updateTriggers={{
              getLineColor: [
                // selectedPoint,
                userSelection.current,
                query.highlightQuery,
                query.roadSegmentCategory,
                rhinoLineColorsWeight,
              ],
              // getFillColor: [
              //   // selectedPoint,
              //   // userSelection.current,
              //   // query.highlightQuery,
              //   query.roadSegmentCategory,
              //   // rhinoLineColorsWeight,
              // ],
              // // getFillColor: [
              // //   selectedPoint ? selectedPoint.object.properties.top100id : null,
              // // ],
              getLineWidth: [
                // selectedPoint,
                userSelection.current,
                query.highlightQuery,
                query.roadSegmentCategory,
                rhinoLineColorsWeight,
              ],
              // getElevation: [
              //   // selectedPoint,
              //   // userSelection.current,
              //   // query.highlightQuery,
              //   query.roadSegmentCategory,
              //   // rhinoLineColorsWeight,
              // ],
            }}
            // transitions={{
            //   getLineColor: 500,
            //   getLineWidth: 500,
            // }}
          />
        )}
        {roadLayerVisibility.polygons && (
          <GeoJsonLayer
            id="roads"
            // data={rhino}
            data={rhinoPolygon}
            visible={roadLayerVisibility.polygons}
            // filled: true,
            // opacity: 0.01,
            pickable={true}
            pickingRadius={5}
            stroked={false}
            filled={true}
            extruded={true}
            pointType={"circle"}
            lineWidthScale={20}
            lineWidthMinPixels={0.5}
            getPointRadius={100}
            getLineWidth={(d, index) => {
              let colorWeightTemp = rhinoLineColorsWeight[index.index];

              // if (
              //   selectedPoint &&
              //   statusGuide[0] === "TX100" &&
              //   d.properties.gid === selectedPoint.object.properties.gid
              // )
              //   // if (selectedPoint)
              //   return colorWeightTemp[0].weight * 5;
              // if (
              //   selectedPoint &&
              //   statusGuide[0] !== "TX100" &&
              //   d.properties.ogc_fid_new ===
              //     selectedPoint.object.properties.ogc_fid_new
              // )
              //   return colorWeightTemp[0].weight * 5;
              // if (
              //   userSelection.current.from_ria_rte_id &&
              //   statusGuide[0] !== "TX100" &&
              //   d.properties.ria_rte_id === userSelection.current.from_ria_rte_id
              // )
              //   return colorWeightTemp[0].weight * 5;
              // if (d.color === COLOR_RHINO.selected)
              //   return colorWeightTemp[0].weight * (5 / zoomLevel);

              return colorWeightTemp.weight * 5;
            }}
            getElevation={(d, index) => {
              // let zoomScale = 1;
              // if (zoomLevel > 4.85) zoomScale = 4.85 / (zoomLevel * zoomLevel);
              let zoomScale = zoomLevel.zoomScale;
              let elevationTemp = rhinoLineColorsWeight[index.index].elevScale;
              // if (rhinoLineColorsWeight[index.index].top100id === 2500500)
              //   console.log(
              //     "rhinoLineColorsWeight[index.index]-check",
              //     rhinoLineColorsWeight[index.index]
              //   );

              return (
                20000 * (currentContentHeight / 421) * zoomScale * elevationTemp
              );
            }}
            onClick={(d, event) => {
              setActivePopup(true);
              setSelectedPoint(d);
            }}
            onHover={(d, event) => {
              if (d.object !== undefined && statusGuide[0] === "TX100")
                setInfo(tx100Info(d.object.properties));
              if (d.object !== undefined && statusGuide[0] !== "TX100")
                setInfo(rhinoInfo(d.object.properties));
            }}
            autoHighlight={true}
            highlightColor={COLOR_RHINO.selected
              .match(/[0-9a-f]{2}/g)
              .map((x) => parseInt(x, 16))}
            getLineColor={(d, index) => {
              // let opacityTemp = 125 * d.properties.weight;
              // if (query.roadSegmentCategory === "none") opacityTemp = 200;
              // let colorWeightTemp = rhinoLineColorsWeight.filter(
              //   (obj) =>
              //     obj.rte_id ===
              //       d.properties.ria_rte_id &&
              //     obj.frm_dfo ===
              //       d.properties.frm_dfo &&
              //     obj.to_dfo === d.properties.to_dfo
              // );
              // let opacityTemp =
              //   125 * colorWeightTemp[0].weight;
              // if (
              //   query.roadSegmentCategory === "none"
              // )
              //   opacityTemp = 200;
              // let colorTemp = colorWeightTemp[0].color
              //   .match(/[0-9a-f]{2}/g)
              //   .map((x) => parseInt(x, 16));

              // // let colorWeightTemp = rhinoLineColorsWeight[index];
              // console.log(
              //   "colorWeightTemp - check",
              //   index.index
              // );
              let colorWeightTemp = rhinoLineColorsWeight[index.index];
              let opacityTemp = 125 * colorWeightTemp.weight;
              if (query.roadSegmentCategory === "none") opacityTemp = 200;
              else
                opacityTemp =
                  colorWeightTemp.weight *
                  colorWeightTemp.weight *
                  colorWeightTemp.weight;
              let colorTemp = colorWeightTemp.color;
              // if (
              //   selectedPoint &&
              //   statusGuide[0] === "TX100" &&
              //   d.properties.gid === selectedPoint.object.properties.gid
              // ) {
              //   colorTemp = COLOR_RHINO.selected
              //     .match(/[0-9a-f]{2}/g)
              //     .map((x) => parseInt(x, 16));
              //   return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
              // }

              // if (
              //   selectedPoint &&
              //   statusGuide[0] !== "TX100" &&
              //   d.properties.ogc_fid_new ===
              //     selectedPoint.object.properties.ogc_fid_new
              // ) {
              //   colorTemp = COLOR_RHINO.selected
              //     .match(/[0-9a-f]{2}/g)
              //     .map((x) => parseInt(x, 16));
              //   return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
              // }
              // if (
              //   userSelection.current.from_ria_rte_id !== "" &&
              //   statusGuide[0] !== "TX100" &&
              //   d.properties.ria_rte_id === userSelection.current.from_ria_rte_id
              // ) {
              //   colorTemp = COLOR_RHINO.selectedSection
              //     .match(/[0-9a-f]{2}/g)
              //     .map((x) => parseInt(x, 16));
              //   return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
              // }

              return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
            }}
            getFillColor={(d, index) => {
              // let opacityTemp = 125 * d.properties.weight;
              // if (query.roadSegmentCategory === "none") opacityTemp = 200;
              // let colorTemp = d.properties.color
              //   .match(/[0-9a-f]{2}/g)
              //   .map((x) => parseInt(x, 16));
              // let colorWeightTemp = rhinoLineColorsWeight.filter(
              //   (obj) =>
              //     obj.rte_id === d.properties.ria_rte_id &&
              //     obj.frm_dfo === d.properties.frm_dfo &&
              //     obj.to_dfo === d.properties.to_dfo
              // );
              // let opacityTemp = 125 * colorWeightTemp[0].weight;
              // if (query.roadSegmentCategory === "none") opacityTemp = 200;
              // let colorTemp = colorWeightTemp[0].color
              //   .match(/[0-9a-f]{2}/g)
              //   .map((x) => parseInt(x, 16));

              let colorWeightTemp = rhinoLineColorsWeight[index.index];
              // let opacityTemp = 125 * colorWeightTemp.weight;
              // if (query.roadSegmentCategory === "none") opacityTemp = 200;
              let colorTemp = colorWeightTemp.color;

              let rdSeqCat = "none";
              if (statusGuide[0] !== "TX100") {
                //if (query.roadSegmentCategory === "none") return f.color_normal
                if (query.roadSegmentCategory === "tdly") rdSeqCat = "trkdelay";
                if (query.roadSegmentCategory === "tpti95")
                  rdSeqCat = "trkpti95";
                if (query.roadSegmentCategory === "tpti80")
                  rdSeqCat = "trkpti80";
                if (query.roadSegmentCategory === "ttti") rdSeqCat = "trktti";
                if (query.roadSegmentCategory === "trefspd")
                  rdSeqCat = "trkrefspee";
                if (query.roadSegmentCategory === "tcongspd")
                  rdSeqCat = "trkspeed";
                if (query.roadSegmentCategory === "taadt") rdSeqCat = "tottrk";
                if (query.roadSegmentCategory === "tvmt")
                  rdSeqCat = "trkttivmt";
              } else {
                if (query.roadSegmentCategory === "tdly") rdSeqCat = "tdly";
                if (query.roadSegmentCategory === "trank") rdSeqCat = "trank";
                if (query.roadSegmentCategory === "tdmile") rdSeqCat = "tdmile";
                if (query.roadSegmentCategory === "ttci") rdSeqCat = "ttci";
                if (query.roadSegmentCategory === "tpti") rdSeqCat = "tpti";
                if (query.roadSegmentCategory === "tcost") rdSeqCat = "tcost";
              }
              const linearScale = d3
                .scaleLinear()
                // .domain([0, dataCategoryMax])
                // .domain([0, 2000])
                // .range([0, 1]);
                .domain([minTrkCatValue, maxTrkCatValue])
                .range([0, 5000]);
              const linearScaleInv = d3
                .scaleLinear()
                // .domain([0, dataCategoryMax])
                // .domain([0, 2000])
                // .range([0, 1]);
                .domain([minTrkCatValue, maxTrkCatValue])
                .range([5000, 0]);
              let opacityTemp = 200;
              // if (rdSeqCat !== "TX100")
              //   opacityTemp = linearScale(d.properties[rdSeqCat]);
              if (rdSeqCat !== "none") {
                if (
                  query.roadSegmentCategory !== "trank" &&
                  query.roadSegmentCategory !== "tcongspd" &&
                  query.roadSegmentCategory !== "trefspd"
                )
                  opacityTemp = linearScale(d.properties[rdSeqCat]);
                else opacityTemp = linearScaleInv(d.properties[rdSeqCat]);
              }

              // if (
              //   selectedPoint &&
              //   statusGuide[0] === "TX100" &&
              //   d.properties.gid === selectedPoint.object.properties.gid
              // ) {
              //   colorTemp = COLOR_RHINO.selected
              //     .match(/[0-9a-f]{2}/g)
              //     .map((x) => parseInt(x, 16));
              //   return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
              // }

              // if (
              //   selectedPoint &&
              //   statusGuide[0] !== "TX100" &&
              //   d.properties.ogc_fid_new ===
              //     selectedPoint.object.properties.ogc_fid_new
              // ) {
              //   colorTemp = COLOR_RHINO.selected
              //     .match(/[0-9a-f]{2}/g)
              //     .map((x) => parseInt(x, 16));
              //   return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
              // }
              // if (
              //   userSelection.current.from_ria_rte_id !== "" &&
              //   statusGuide[0] !== "TX100" &&
              //   d.properties.ria_rte_id === userSelection.current.from_ria_rte_id
              // ) {
              //   // if (selectedPoint)
              //   colorTemp = COLOR_RHINO.selectedSection
              //     .match(/[0-9a-f]{2}/g)
              //     .map((x) => parseInt(x, 16));
              //   return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
              // }

              return [colorTemp[0], colorTemp[1], colorTemp[2], opacityTemp];
            }}
            updateTriggers={{
              getLineColor: [
                // selectedPoint,
                userSelection.current,
                query.highlightQuery,
                query.roadSegmentCategory,
                // rhinoLineColorsWeight,
              ],
              getFillColor: [
                // selectedPoint,
                userSelection.current,
                query.highlightQuery,
                query.roadSegmentCategory,
                // rhinoLineColorsWeight,
              ],
              // getFillColor: [
              //   selectedPoint ? selectedPoint.object.properties.top100id : null,
              // ],
              getLineWidth: [
                // selectedPoint,
                userSelection.current,
                query.highlightQuery,
                query.roadSegmentCategory,
                rhinoLineColorsWeight,
              ],
              getElevation: [
                userSelection.current,
                query.highlightQuery,
                query.roadSegmentCategory,
                zoomLevel,
              ],
            }}
            // transitions={{
            //   getLineColor: 500,
            //   getLineWidth: 500,
            // }}
          />
        )}

        <IconLayer
          id="from-points"
          data={fromPoints}
          // visible={query.utpMapLayersVisibility.underwayorsoon}
          visible={fromPoints.length > 0 ? true : false}
          // pickable={true}
          // pickingRadius={5}
          // stroked={false}
          // filled={false}
          // extruded={false}
          // pointType={"icon"}
          // getIconSize={1}
          // getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
          // getIconAngle={0}
          // getIconPixelOffset={[0, 0]}
          // iconSizeUnits={"pixels"}
          // iconSizeScale={15}
          // getIcon={() => ({
          //   url: UtpIconSVG1,
          //   width: 24,
          //   height: 24,
          // })}
          // getPosition={(d) => d.coordinates}
          // onClick={(d, event) => {
          //   // console.log("d-check", d);
          //   setActivePopupUtp(true);
          //   setSelectedUTPData(d);
          //   // renderPopupUtp(d);
          // }}
          pickable={true}
          // iconAtlas and iconMapping are required
          // getIcon: return a string
          iconAtlas={
            "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png"
          }
          iconMapping={{
            marker: {
              x: 0,
              y: 0,
              width: 128,
              height: 128,
              mask: true,
              anchorY: 128,
              // anchorX: 0,
            },
          }}
          getIcon={(d) => "marker"}
          sizeScale={3}
          getPosition={(d) => d.coordinates}
          getSize={(d) => 10}
          getColor={(d) => [140, 140, 0]}
          // sizeScale={15}
          updateTriggers={{
            getColor: [
              // selectedPoint,
              userSelection.current,
              query.highlightQuery,
              query.roadSegmentCategory,
              // rhinoLineColorsWeight,
            ],
          }}
        />

        {/* {countyBoundaries !== null &&
          query.contextLayersVisibility.countyBoundaries && ( */}
        <GeoJsonLayer
          id="countyBoundaries"
          data={countyBoundaries}
          visible={query.contextLayersVisibility.countyBoundaries}
          filled={true}
          opacity={0.05}
          pickable={true}
          pickingRadius={5}
          stroked={true}
          // filled={true}
          //extruded={true}
          pointType={"circle"}
          lineWidthScale={20}
          lineWidthMinPixels={0.5}
          getPointRadius={0.5}
          getLineWidth={1}
          //getElevation={500}
          // onClick={(d, event) => {
          //   setActivePopup(true);
          //   setSelectedPoint(d);
          // }}
          // onHover={(d, event) => {
          //   if (d.object !== undefined && statusGuide[0] === "TX100")
          //     setInfo(tx100Info(d.object.properties));
          //   if (d.object !== undefined && statusGuide[0] !== "TX100")
          //     setInfo(rhinoInfo(d.object.properties));
          // }}
          autoHighlight={true}
          highlightColor={[160, 160, 180, 10]}
          getLineColor={[46, 49, 49]}
          getFillColor={"#ffa305"
            .match(/[0-9a-f]{2}/g)
            .map((x) => parseInt(x, 16))}
        />
        {/* )} */}
        {/* {districtBoundaries !== null &&
          query.contextLayersVisibility.districtBoundaries && ( */}
        <GeoJsonLayer
          id="districtBoundaries"
          data={districtBoundaries}
          visible={query.contextLayersVisibility.districtBoundaries}
          filled={true}
          opacity={0.05}
          pickable={true}
          pickingRadius={5}
          stroked={true}
          // filled={true}
          //extruded={true}
          pointType={"circle"}
          lineWidthScale={20}
          lineWidthMinPixels={1}
          getPointRadius={0.5}
          getLineWidth={2}
          //getElevation={500}
          // onClick={(d, event) => {
          //   setActivePopup(true);
          //   setSelectedPoint(d);
          // }}
          // onHover={(d, event) => {
          //   if (d.object !== undefined && statusGuide[0] === "TX100")
          //     setInfo(tx100Info(d.object.properties));
          //   if (d.object !== undefined && statusGuide[0] !== "TX100")
          //     setInfo(rhinoInfo(d.object.properties));
          // }}
          autoHighlight={true}
          highlightColor={[160, 160, 180, 10]}
          getLineColor={[46, 49, 49]}
          getFillColor={"#90ee90"
            .match(/[0-9a-f]{2}/g)
            .map((x) => parseInt(x, 16))}
        />
        {/* )} */}
        {/* {sandMinesData !== null &&
          query.contextLayersVisibility.odessaDistSandMines && ( */}
        <GeoJsonLayer
          id="sandMines"
          data={sandMinesData}
          visible={query.contextLayersVisibility.odessaDistSandMines}
          // filled={true}
          // opacity={1}
          // pickable={true}
          // pickingRadius={5}
          // stroked={true}
          // filled={true}
          // //extruded={true}
          // pointType={"circle"}
          // lineWidthScale={20}
          // lineWidthMinPixels={5}
          // getPointRadius={100.5}
          // getLineWidth={1}
          pickable={true}
          opacity={0.8}
          stroked={true}
          filled={true}
          radiusScale={1}
          radiusMinPixels={1}
          radiusMaxPixels={5000}
          lineWidthMinPixels={1}
          getRadius={2000}
          //getElevation={500}
          // onClick={(d, event) => {
          //   setActivePopup(true);
          //   setSelectedPoint(d);
          // }}
          // onHover={(d, event) => {
          //   if (d.object !== undefined && statusGuide[0] === "TX100")
          //     setInfo(tx100Info(d.object.properties));
          //   if (d.object !== undefined && statusGuide[0] !== "TX100")
          //     setInfo(rhinoInfo(d.object.properties));
          // }}
          autoHighlight={true}
          highlightColor={[160, 160, 180, 10]}
          getLineColor={[46, 49, 49]}
          getFillColor={colorContextlayers.sandMines
            .match(/[0-9a-f]{2}/g)
            .map((x) => parseInt(x, 16))}
        />
        {/* )} */}
        {/* {disposalWellsData !== null &&
          query.contextLayersVisibility.disposalWells && ( */}
        <GeoJsonLayer
          id="disposalWells"
          data={disposalWellsData}
          visible={query.contextLayersVisibility.disposalWells}
          // filled={true}
          // opacity={1}
          // pickable={true}
          // pickingRadius={5}
          // stroked={true}
          // filled={true}
          // //extruded={true}
          pointType={"circle"}
          // lineWidthScale={20}
          // lineWidthMinPixels={5}
          // getPointRadius={100.5}
          // getLineWidth={1}
          pickable={true}
          opacity={0.8}
          stroked={true}
          filled={true}
          radiusScale={1}
          radiusMinPixels={1}
          radiusMaxPixels={5000}
          lineWidthMinPixels={1}
          getRadius={2000}
          //getElevation={500}
          // onClick={(d, event) => {
          //   setActivePopup(true);
          //   setSelectedPoint(d);
          // }}
          // onHover={(d, event) => {
          //   if (d.object !== undefined && statusGuide[0] === "TX100")
          //     setInfo(tx100Info(d.object.properties));
          //   if (d.object !== undefined && statusGuide[0] !== "TX100")
          //     setInfo(rhinoInfo(d.object.properties));
          // }}
          autoHighlight={true}
          highlightColor={[160, 160, 180, 10]}
          getLineColor={[46, 49, 49]}
          getFillColor={colorContextlayers.disposalWells
            .match(/[0-9a-f]{2}/g)
            .map((x) => parseInt(x, 16))}
        />
        {/* )} */}
        {/* {commercialBorderCrossingsData !== null &&
          query.contextLayersVisibility.commercialBorderCrossings && ( */}
        <GeoJsonLayer
          id="commercialBorderCrossings"
          data={commercialBorderCrossingsData}
          visible={query.contextLayersVisibility.commercialBorderCrossings}
          pointType={"icon"}
          pickable={true}
          opacity={0.8}
          // onHover={(d, event) => {
          //   if (d.object !== undefined && statusGuide[0] === "TX100")
          //     setInfo(tx100Info(d.object.properties));
          //   if (d.object !== undefined && statusGuide[0] !== "TX100")
          //     setInfo(rhinoInfo(d.object.properties));
          // }}
          autoHighlight={true}
          highlightColor={[160, 160, 180, 10]}
          // iconAtlas and iconMapping are required
          // getIcon: return a string
          // iconAtlas={
          //   "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png"
          // }
          // iconMapping={ICON_MAPPING}
          // getIcon={(d) => "marker"}
          getIconSize={1}
          getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
          getIconAngle={0}
          getIconPixelOffset={[0, 0]}
          iconSizeUnits={"pixels"}
          iconSizeScale={15}
          // iconSizeMinPixels
          // getPosition={(d) => d.coordinates}
          // getSize={(d) => 5}
          // getColor={(d) => [Math.sqrt(d.exits), 140, 0]}

          getIcon={() => ({
            url: commercialBorderCrossingIconSvg,
            width: 24,
            height: 24,
          })}
        />
        {/* )} */}
        {/* {nonCommercialBorderCrossingsData !== null &&
          query.contextLayersVisibility.nonCommercialBorderCrossings && ( */}
        <GeoJsonLayer
          id="nonCommercialBorderCrossings"
          data={nonCommercialBorderCrossingsData}
          visible={query.contextLayersVisibility.nonCommercialBorderCrossings}
          pointType={"icon"}
          pickable={true}
          opacity={0.8}
          // onHover={(d, event) => {
          //   if (d.object !== undefined && statusGuide[0] === "TX100")
          //     setInfo(tx100Info(d.object.properties));
          //   if (d.object !== undefined && statusGuide[0] !== "TX100")
          //     setInfo(rhinoInfo(d.object.properties));
          // }}
          autoHighlight={true}
          highlightColor={[160, 160, 180, 10]}
          // iconAtlas and iconMapping are required
          // getIcon: return a string
          // iconAtlas={
          //   "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png"
          // }
          // iconMapping={ICON_MAPPING}
          // getIcon={(d) => "marker"}
          getIconSize={1}
          getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
          getIconAngle={0}
          getIconPixelOffset={[0, 0]}
          iconSizeUnits={"pixels"}
          iconSizeScale={15}
          // iconSizeMinPixels
          // getPosition={(d) => d.coordinates}
          // getSize={(d) => 5}
          // getColor={(d) => [Math.sqrt(d.exits), 140, 0]}

          getIcon={() => ({
            url: nonCommercialBorderCrossingIconSvg,
            width: 24,
            height: 24,
          })}
        />
        {/* )} */}
        {/* {railBorderCrossingsData !== null &&
          query.contextLayersVisibility.railBorderCrossings && ( */}
        <GeoJsonLayer
          id="railBorderCrossings"
          data={railBorderCrossingsData}
          visible={query.contextLayersVisibility.railBorderCrossings}
          pointType={"icon"}
          pickable={true}
          opacity={0.8}
          // onHover={(d, event) => {
          //   if (d.object !== undefined && statusGuide[0] === "TX100")
          //     setInfo(tx100Info(d.object.properties));
          //   if (d.object !== undefined && statusGuide[0] !== "TX100")
          //     setInfo(rhinoInfo(d.object.properties));
          // }}
          autoHighlight={true}
          highlightColor={[160, 160, 180, 10]}
          // iconAtlas and iconMapping are required
          // getIcon: return a string
          // iconAtlas={
          //   "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png"
          // }
          // iconMapping={ICON_MAPPING}
          // getIcon={(d) => "marker"}
          getIconSize={1}
          getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
          getIconAngle={0}
          getIconPixelOffset={[0, 0]}
          iconSizeUnits={"pixels"}
          iconSizeScale={15}
          // iconSizeMinPixels
          // getPosition={(d) => d.coordinates}
          // getSize={(d) => 5}
          // getColor={(d) => [Math.sqrt(d.exits), 140, 0]}

          getIcon={() => ({
            url: railBorderCrossingIconSvg,
            width: 24,
            height: 24,
          })}
        />
        {/* )} */}
        {/* {truckParkingData !== null &&
          query.contextLayersVisibility.truckParkingLayer && ( */}
        <GeoJsonLayer
          id="truckParking"
          data={truckParkingData}
          visible={query.contextLayersVisibility.truckParkingLayer}
          pointType={"icon"}
          pickable={true}
          opacity={0.8}
          // onHover={(d, event) => {
          //   if (d.object !== undefined && statusGuide[0] === "TX100")
          //     setInfo(tx100Info(d.object.properties));
          //   if (d.object !== undefined && statusGuide[0] !== "TX100")
          //     setInfo(rhinoInfo(d.object.properties));
          // }}
          autoHighlight={true}
          highlightColor={[160, 160, 180, 10]}
          // iconAtlas and iconMapping are required
          // getIcon: return a string
          // iconAtlas={
          //   "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png"
          // }
          // iconMapping={ICON_MAPPING}
          // getIcon={(d) => "marker"}
          getIconSize={1}
          getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
          getIconAngle={0}
          getIconPixelOffset={[0, 0]}
          iconSizeUnits={"pixels"}
          iconSizeScale={15}
          // iconSizeMinPixels
          // getPosition={(d) => d.coordinates}
          // getSize={(d) => 5}
          // getColor={(d) => [Math.sqrt(d.exits), 140, 0]}

          getIcon={() => ({
            url: truckParkingIconSvg,
            width: 24,
            height: 24,
          })}
        />
        {/* )} */}
        {/* {portsData !== null && query.contextLayersVisibility.portsLayer && ( */}
        <GeoJsonLayer
          id="ports"
          data={portsData}
          visible={query.contextLayersVisibility.portsLayer}
          pointType={"icon"}
          pickable={true}
          opacity={0.8}
          // onHover={(d, event) => {
          //   if (d.object !== undefined && statusGuide[0] === "TX100")
          //     setInfo(tx100Info(d.object.properties));
          //   if (d.object !== undefined && statusGuide[0] !== "TX100")
          //     setInfo(rhinoInfo(d.object.properties));
          // }}
          autoHighlight={true}
          highlightColor={[160, 160, 180, 10]}
          // iconAtlas and iconMapping are required
          // getIcon: return a string
          // iconAtlas={
          //   "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png"
          // }
          // iconMapping={ICON_MAPPING}
          // getIcon={(d) => "marker"}
          getIconSize={1}
          getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
          getIconAngle={0}
          getIconPixelOffset={[0, 0]}
          iconSizeUnits={"pixels"}
          iconSizeScale={15}
          // iconSizeMinPixels
          // getPosition={(d) => d.coordinates}
          // getSize={(d) => 5}
          // getColor={(d) => [Math.sqrt(d.exits), 140, 0]}

          getIcon={() => ({
            url: portsIconSvg,
            width: 24,
            height: 24,
          })}
        />
        {/* )} */}
        {/* {cargoAirportsData !== null &&
          query.contextLayersVisibility.cargoAirports && ( */}
        <GeoJsonLayer
          id="cargoAirports"
          data={cargoAirportsData}
          visible={query.contextLayersVisibility.cargoAirports}
          pointType={"icon"}
          pickable={true}
          opacity={0.8}
          // onHover={(d, event) => {
          //   if (d.object !== undefined && statusGuide[0] === "TX100")
          //     setInfo(tx100Info(d.object.properties));
          //   if (d.object !== undefined && statusGuide[0] !== "TX100")
          //     setInfo(rhinoInfo(d.object.properties));
          // }}
          autoHighlight={true}
          highlightColor={[160, 160, 180, 10]}
          // iconAtlas and iconMapping are required
          // getIcon: return a string
          // iconAtlas={
          //   "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png"
          // }
          // iconMapping={ICON_MAPPING}
          // getIcon={(d) => "marker"}
          getIconSize={1}
          getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
          getIconAngle={0}
          getIconPixelOffset={[0, 0]}
          iconSizeUnits={"pixels"}
          iconSizeScale={15}
          // iconSizeMinPixels
          // getPosition={(d) => d.coordinates}
          // getSize={(d) => 5}
          // getColor={(d) => [Math.sqrt(d.exits), 140, 0]}

          getIcon={() => ({
            url: cargoAirportsIconSvg,
            width: 24,
            height: 24,
          })}
        />
        {/* )} */}
        {/* {airportsData !== null && query.contextLayersVisibility.airports && ( */}
        <GeoJsonLayer
          id="airports"
          data={airportsData}
          visible={query.contextLayersVisibility.airports}
          pointType={"icon"}
          pickable={true}
          opacity={0.8}
          // onHover={(d, event) => {
          //   if (d.object !== undefined && statusGuide[0] === "TX100")
          //     setInfo(tx100Info(d.object.properties));
          //   if (d.object !== undefined && statusGuide[0] !== "TX100")
          //     setInfo(rhinoInfo(d.object.properties));
          // }}
          autoHighlight={true}
          highlightColor={[160, 160, 180, 10]}
          // iconAtlas and iconMapping are required
          // getIcon: return a string
          // iconAtlas={
          //   "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png"
          // }
          // iconMapping={ICON_MAPPING}
          // getIcon={(d) => "marker"}
          getPosition={(d) => d.geomPoint}
          getIconSize={1}
          getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
          getIconAngle={0}
          getIconPixelOffset={[0, 0]}
          iconSizeUnits={"pixels"}
          iconSizeScale={15}
          // iconSizeMinPixels
          // getPosition={(d) => d.coordinates}
          // getSize={(d) => 5}
          // getColor={(d) => [Math.sqrt(d.exits), 140, 0]}

          getIcon={() => ({
            url: airportsIconSvg,
            width: 24,
            height: 24,
          })}
        />

        {/* )} */}

        {query.utpMapLayersVisibility.underwayorsoon &&
          utpData.features.length > 1 && (
            <GeoJsonLayer
              id="utp-points-1"
              data={utpPointsData.features.filter(filterPhases[0])}
              visible={query.utpMapLayersVisibility.underwayorsoon}
              pickable={true}
              pickingRadius={5}
              stroked={false}
              filled={false}
              extruded={false}
              pointType={"icon"}
              getIconSize={1}
              getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
              getIconAngle={0}
              getIconPixelOffset={[0, 0]}
              iconSizeUnits={"pixels"}
              iconSizeScale={15}
              getIcon={() => ({
                url: UtpIconSVG1,
                width: 24,
                height: 24,
              })}
              onClick={(d, event) => {
                // console.log("d-check", d);
                setActivePopupUtp(true);
                setSelectedUTPData(d);
                // renderPopupUtp(d);
              }}
            />
          )}

        {query.utpMapLayersVisibility.within4years &&
          utpData.features.length > 1 && (
            <GeoJsonLayer
              id="utp-points-2"
              data={utpPointsData.features.filter(filterPhases[1])}
              visible={query.utpMapLayersVisibility.within4years}
              pickable={true}
              pickingRadius={5}
              stroked={false}
              filled={false}
              extruded={false}
              pointType={"icon"}
              getIconSize={1}
              getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
              getIconAngle={0}
              getIconPixelOffset={[0, 0]}
              iconSizeUnits={"pixels"}
              iconSizeScale={15}
              getIcon={() => ({
                url: UtpIconSVG2,
                width: 24,
                height: 24,
              })}
              onClick={(d, event) => {
                // console.log("d-check", d);
                setActivePopupUtp(true);
                setSelectedUTPData(d);
                // renderPopupUtp(d);
              }}
            />
          )}

        {query.utpMapLayersVisibility.fivetotenyears &&
          utpData.features.length > 1 && (
            <GeoJsonLayer
              id="utp-points-3"
              data={utpPointsData.features.filter(filterPhases[2])}
              visible={query.utpMapLayersVisibility.fivetotenyears}
              pickable={true}
              pickingRadius={5}
              stroked={false}
              filled={false}
              extruded={false}
              pointType={"icon"}
              getIconSize={1}
              getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
              getIconAngle={0}
              getIconPixelOffset={[0, 0]}
              iconSizeUnits={"pixels"}
              iconSizeScale={15}
              getIcon={() => ({
                url: UtpIconSVG3,
                width: 24,
                height: 24,
              })}
              onClick={(d, event) => {
                // console.log("d-check", d);
                setActivePopupUtp(true);
                setSelectedUTPData(d);
                // renderPopupUtp(d);
              }}
            />
          )}

        {query.utpMapLayersVisibility.tenplusyears &&
          utpData.features.length > 1 && (
            <GeoJsonLayer
              id="utp-points-4"
              data={utpPointsData.features.filter(filterPhases[3])}
              visible={query.utpMapLayersVisibility.tenplusyears}
              pickable={true}
              pickingRadius={5}
              stroked={false}
              filled={false}
              extruded={false}
              pointType={"icon"}
              getIconSize={1}
              getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
              getIconAngle={0}
              getIconPixelOffset={[0, 0]}
              iconSizeUnits={"pixels"}
              iconSizeScale={15}
              getIcon={() => ({
                url: UtpIconSVG4,
                width: 24,
                height: 24,
              })}
              onClick={(d, event) => {
                // console.log("d-check", d);
                setActivePopupUtp(true);
                setSelectedUTPData(d);
                // renderPopupUtp(d);
              }}
            />
          )}
        {selectedUtpData && (
          <GeoJsonLayer
            id="utp-selected"
            data={utpData.features.filter(
              (f) =>
                f.properties.OBJECTID ===
                selectedUtpData.object.properties.OBJECTID
            )}
            visible={activePopupUtp}
            pickable={false}
            pickingRadius={5}
            stroked={false}
            filled={false}
            extruded={false}
            lineWidthMinPixels={4}
            getLineWidth={10}
            autoHighlight={true}
            highlightColor={COLOR_RHINO.selected
              .match(/[0-9a-f]{2}/g)
              .map((x) => parseInt(x, 16))}
            getLineColor={"#00FF00"
              .match(/[0-9a-f]{2}/g)
              .map((x) => parseInt(x, 16))}
          />
        )}

        {/* <StaticMap mapStyle={MAP_STYLE} /> */}
        <div
          // classes={{
          //   paper: classes.dialog,
          // }}
          className={classes.dialog}
        >
          <MapTabs
            hoverInfo={info}
            type="rhino"
            numFeatures={rhino.features.length.toLocaleString()}
            minValue={minTrkCatValue}
            maxValue={maxTrkCatValue}
            statusGuide={statusGuide}
            utpMapLayersCount={utpMapLayersCount}
            mode={props.mode}
          />
        </div>
        {/* {!isLoading && <NavigationControl style={NAV_CONTROL_STYLE} />}
        {!isLoading && <ScaleControl style={ScaleControl_STYLE} />}
        {!isLoading && (
          <div style={layers_CONTROL_STYLE}>
            <LayerControls baseMap={baseMap} setBaseMap={setBaseMap} />
          </div>
        )} */}
        <NavigationControl style={NAV_CONTROL_STYLE} />
        <ScaleControl style={ScaleControl_STYLE} />
        <div style={layers_CONTROL_STYLE}>
          <LayerControls
            baseMap={baseMap}
            setBaseMap={setBaseMap}
            showRoads={showRoads}
            setShowRoads={setShowRoads}
          />
        </div>
      </DeckGL>
    </div>
  );
}

export default DeckglMap;
