import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
// import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";
import Icon from "@material-ui/core/Icon";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { sidebarStyles } from "../ui/styles";
// import {
//     geographic_options,
//     geographic_options_counties,
//     geographic_options_critical_corridors,
//     geographic_options_other_fway_corridors,
//     geographic_options_other_art_corridors,
//     geographic_options_other_freight_corridors,
//     geographic_options_top100,
// } from "../common/constants";

import ContactUsDialog from "./ContactUs";
import WhatsNewDialog from "./WhatsNew";

import Select, {
  // components,
  createFilter,
} from "react-select";

import {
  // TXDOTdist_VARIABLES,
  // COUNTY_LIST,
  // MPO_LIST,
  // OTHER_GROUP,
  // REGION_LIST,
  ROAD_LIST,
  geographic_options_tx100,
  geographic_options_TXDOTdist,
  geographic_options_MPO,
  geographic_options_REGIONS,
  geographic_options_corridors,
  geographic_options_COUNTY,
  whatIsTCATFile,
  whatIsTCAT_tooltip,
  basicHelpFile,
  usersguide_tooltip,
  methodHelpFile,
  summaryReportFile,
  annTruckCongRepCardsLink,
  geographic_options_Networks,
  quarterlyTruckMobilityReportsLink,
} from "../common/constants";

import InfoIcon from "@material-ui/icons/Info";
import HelpIcon from "@material-ui/icons/Help";
import WebAssetIcon from "@material-ui/icons/WebAsset";
import ContactMailIcon from "@material-ui/icons/ContactMail";

const Sidebar = (props) => {
  const classes = sidebarStyles();

  //   const [geographicOptions, setGeographicOptions] = React.useState(
  //     geographic_options
  //   );
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function GeographicSelectionDialog(propsGeo) {
    return (
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Available layers:</DialogTitle>
        <DialogContent>
          {propsGeo.geographicOptions.map((item, index) => (
            <Link
              className={item.active ? "" : "link-disabled"}
              key={item.text}
              underline="none"
              color="inherit"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (item.active) {
                  props.setStatsLayer(item.text);
                  props.setGeoRoute(item.geoRoute);
                  props.setSegmentsTable(item.segmentsTable);
                  props.setSegmentSelection(item.segmentSelection);
                  props.setStatusGuide(item.statusGuide);
                }
              }}
            >
              <ListItem button onClick={handleClose}>
                <ListItemIcon>
                  <Icon>{item.icon}</Icon>
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            </Link>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const [openContactUs, setOpenContactUs] = React.useState(false);
  const handleClickOpenContactUs = () => {
    setOpenContactUs(true);
  };

  const [openWhatsNew, setOpenWhatsNew] = React.useState(false);
  const handleClickOpenWhatsNew = () => {
    setOpenWhatsNew(true);
  };

  const geographicGroups = [
    { name: "Texas 100", options: geographic_options_tx100 },
    { name: "TxDOT District", options: geographic_options_TXDOTdist },
    { name: "County", options: geographic_options_COUNTY },
    { name: "MPO", options: geographic_options_MPO },
    { name: "Region", options: geographic_options_REGIONS },
    { name: "Corridor", options: geographic_options_corridors },
    { name: "Freight Networks/NHS", options: geographic_options_Networks },
  ];

  const grouped_option = [
    {
      label: "TxDOT Districts",
      options: geographic_options_TXDOTdist.map((opt) => ({
        label: opt.text,
        value: opt.geoRoute,
        statusGuide: opt.statusGuide,
      })),
    },
    {
      label: "Counties",
      options: geographic_options_COUNTY.map((opt) => ({
        label: opt.text,
        value: opt.geoRoute,
        statusGuide: opt.statusGuide,
      })),
    },
    {
      label: "MPOs",
      options: geographic_options_MPO.map((opt) => ({
        label: opt.text,
        value: opt.geoRoute,
        statusGuide: opt.statusGuide,
      })),
    },
    {
      label: "Regions",
      options: geographic_options_REGIONS.map((opt) => ({
        label: opt.text,
        value: opt.geoRoute,
        statusGuide: opt.statusGuide,
      })),
    },
    {
      label: "Corridors",
      options: geographic_options_corridors.map((opt) => ({
        label: opt.text,
        value: opt.geoRoute,
        statusGuide: opt.statusGuide,
      })),
    },
    {
      label: "Roads",
      options: ROAD_LIST.map((opt) => ({
        label: opt.label,
        value: "findByRouteId?route_id=" + opt.value,
        statusGuide: ["Roads", opt.label],
      })),
    },
  ];

  var handleSelect = (e) => {
    // console.log("e-check", e);
    // setstatusTX100Layer(false);
    // setstatusStateLayer(false);
    // setstatusCountyLayer(false);
    // setstatusStateLayer(false);
    //props.setStatsLayer("statewide");
    // props.setRoute("/roads/" + e.value);
    if (!e) {
      props.setStatsLayer(props.statsLayerDesc);
      props.setGeoRoute(props.geoRoute);
      props.setSegmentsTable("roads");
      props.setSegmentSelection("all");
      props.setStatusGuide(props.statusGuide);
    } else {
      let groupName = e.label.split(" ").slice(-1)[0];
      groupName = groupName === e.label ? "Road" : groupName;
      //   let label =
      //     groupName === "Road" ? e.label : e.label.slice(0, -groupName.length);
      // setstatusGuide([groupName, label]);

      props.setStatsLayer(e.label);
      //props.setGeoRoute("/roads/" + e.value);
      if (groupName === "Road") {
        props.setGeoRoute("/roads/" + e.value);
      } else {
        props.setGeoRoute(e.value);
      }

      props.setSegmentsTable("roads");
      props.setSegmentSelection("all");
      props.setStatusGuide(e.statusGuide);
    }
  };

  const [geographicOptions, setGeographicOptions] = React.useState(
    geographic_options_tx100
  );
  const customStyles = {
    control: (base, state) => ({
      ...base,
      color: "white",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      // boxShadow: state.isFocused ? null : null,
      // "&:hover": {
      //   // Overwrittes the different states of border
      //   borderColor: state.isFocused ? "red" : "blue",
      // },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      background: "#1e1e1e",
      color: "white",
    }),
    menuList: (base, state) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      background: "#1e1e1e",
      color: "white",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? // ? "hsla(291, 64%, 42%, 0.5)"
          "#9b27b0"
        : isSelected
        ? // ? "hsla(291, 64%, 42%, 1)"
          "#3a8848"
        : undefined,
      zIndex: 1,
    }),
  };

  const drawer = (
    <div className="drawer-wrapper">
      <div className={classes.toolbar} />
      <Divider className={classes.divider} light />
      <Typography variant="h6" className={classes.sectionHeader}>
        <strong>General Information</strong>
      </Typography>
      <ListItem
        button
        // onClick={() => {
        //   setGeographicOptions(geographic_options);
        //   handleClickOpen();
        // }}
        component="a"
        href={process.env.PUBLIC_URL + whatIsTCATFile}
        target="_blank"
        rel="noopener noreferrer"
        title={whatIsTCAT_tooltip}
      >
        {/* <ListItemIcon>
          <Icon>{"location_city"}</Icon>
        </ListItemIcon> */}
        <ListItemIcon className={classes.text}>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary="What is TCAT?" className={classes.iconText} />
      </ListItem>
      <ListItem
        button
        onClick={() => {
          //   setGeographicOptions(geographic_options);
          handleClickOpenWhatsNew();
        }}
        title="What's New?"
      >
        {/* <ListItemIcon>
          <Icon>{"location_city"}</Icon>
        </ListItemIcon> */}
        <ListItemIcon className={classes.text}>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary="What's New?" className={classes.iconText} />
      </ListItem>
      <WhatsNewDialog
        openWhatsNew={openWhatsNew}
        setOpenWhatsNew={setOpenWhatsNew}
      />

      <ListItem
        button
        // onClick={() => {
        //   setGeographicOptions(geographic_options);
        //   handleClickOpen();
        // }}
        component="a"
        href={process.env.PUBLIC_URL + basicHelpFile}
        target="_blank"
        rel="noopener noreferrer"
        title={usersguide_tooltip}
      >
        {/* <ListItemIcon>
          <Icon>{"location_city"}</Icon>
        </ListItemIcon> */}
        <ListItemIcon className={classes.text}>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText
          primary="Quick Reference/Basic Help"
          className={classes.iconText}
        />
      </ListItem>
      <Divider className={classes.divider} light />
      <Typography variant="h6" className={classes.sectionHeader}>
        <strong>Congestion Layers</strong>
      </Typography>
      {/* <Typography variant="subtitle1" className={classes.text}>
        Current Layer:
        {props.statsLayerDesc}
      </Typography> */}
      {/* <Divider /> */}

      {geographicGroups.map((opt) => (
        <ListItem
          key={"sidebar-group-" + opt.name}
          button
          onClick={() => {
            setGeographicOptions(opt.options);
            handleClickOpen();
          }}
        >
          <ListItemIcon className={classes.text}>
            <Icon>{"location_city"}</Icon>
          </ListItemIcon>
          <ListItemText primary={opt.name} className={classes.iconText} />
        </ListItem>
      ))}

      <ListItem
        // button
        // onClick={() => {
        //   setGeographicOptions(geographic_options);
        //   handleClickOpen();
        // }}
        key={"sidebar-group-search"}
      >
        <ListItemIcon className={classes.text}>
          <Icon>{"search"}</Icon>
        </ListItemIcon>
        <ListItemText primary="Search" className={classes.iconText} />
      </ListItem>
      <Select
        options={grouped_option}
        isClearable={true}
        classNamePrefix="select"
        onChange={handleSelect}
        // components={{ Option: Option }}
        filterOption={createFilter({ ignoreAccents: false })}
        styles={customStyles}
        // styles={{
        //   control: (styles) => ({
        //     ...styles,
        //     backgroundColor: "white",
        //     color: "black",
        //   }),
        //   option: (styles, state) => ({
        //     ...styles,
        //   }),
        //   //   singleValue: (styles) => ({ ...style, color: "black" }),
        //   singleValue: (styles) => ({ color: "black" }),
        //   //   menu: (styles) => ({ ...style, color: "black" }),
        //   menu: (styles) => ({ color: "black" }),
        //   color: "black",
        //   cursor: "default",
        // }}
      />
      <Divider className={classes.divider} light />
      <Divider className={classes.divider} light />
      <Typography variant="h6" className={classes.sectionHeader}>
        <strong>Other Links</strong>
      </Typography>
      <ListItem
        button
        // onClick={() => {
        //   setGeographicOptions(geographic_options);
        //   handleClickOpen();
        // }}
        component="a"
        href={annTruckCongRepCardsLink}
        target="_blank"
        rel="noopener noreferrer"
        title="Annual Truck Congestion Report Cards"
      >
        {/* <ListItemIcon>
          <Icon>{"location_city"}</Icon>
        </ListItemIcon> */}
        <ListItemIcon className={classes.text}>
          <WebAssetIcon />
        </ListItemIcon>
        <ListItemText
          primary="Annual Truck Congestion Report Cards"
          className={classes.iconText}
        />
      </ListItem>
      <ListItem
        button
        // onClick={() => {
        //   setGeographicOptions(geographic_options);
        //   handleClickOpen();
        // }}
        component="a"
        href={quarterlyTruckMobilityReportsLink}
        target="_blank"
        rel="noopener noreferrer"
        title="Quarterly Truck Mobility Reports"
      >
        {/* <ListItemIcon>
          <Icon>{"location_city"}</Icon>
        </ListItemIcon> */}
        <ListItemIcon className={classes.text}>
          <WebAssetIcon />
        </ListItemIcon>
        <ListItemText
          primary="Quarterly Truck Mobility Reports"
          className={classes.iconText}
        />
      </ListItem>
      <ListItem
        button
        // onClick={() => {
        //   setGeographicOptions(geographic_options);
        //   handleClickOpen();
        // }}
        component="a"
        href={process.env.PUBLIC_URL + methodHelpFile}
        target="_blank"
        rel="noopener noreferrer"
        title="Analysis Procedures and Mobility Performance Measures 100 Most Congested Texas Road Sections"
      >
        {/* <ListItemIcon>
          <Icon>{"location_city"}</Icon>
        </ListItemIcon> */}
        <ListItemIcon className={classes.text}>
          <WebAssetIcon />
        </ListItemIcon>
        <ListItemText
          primary="Texas 100 Methodology"
          className={classes.iconText}
        />
      </ListItem>
      <ListItem
        button
        // onClick={() => {
        //   setGeographicOptions(geographic_options);
        //   handleClickOpen();
        // }}
        component="a"
        href={process.env.PUBLIC_URL + summaryReportFile}
        target="_blank"
        rel="noopener noreferrer"
        title="100 Most Congested Roadways in Texas"
      >
        {/* <ListItemIcon>
          <Icon>{"location_city"}</Icon>
        </ListItemIcon> */}
        <ListItemIcon className={classes.text}>
          <WebAssetIcon />
        </ListItemIcon>
        <ListItemText
          primary="Texas 100 Executive Summary"
          className={classes.iconText}
        />
      </ListItem>
      <ListItem
        button
        onClick={() => {
          //   setGeographicOptions(geographic_options);
          handleClickOpenContactUs();
        }}
        title="Contact Us"
      >
        {/* <ListItemIcon>
          <Icon>{"location_city"}</Icon>
        </ListItemIcon> */}

        <ListItemIcon className={classes.text}>
          <ContactMailIcon />
        </ListItemIcon>
        <ListItemText primary="Contact Us" className={classes.iconText} />
      </ListItem>
      <ContactUsDialog
        openContactUs={openContactUs}
        setOpenContactUs={setOpenContactUs}
      />

      <GeographicSelectionDialog geographicOptions={geographicOptions} />
    </div>
  );

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="permanent"
      open
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {drawer}
    </Drawer>
  );
};

Sidebar.propTypes = {
  style: PropTypes.object,
};

export default Sidebar;
