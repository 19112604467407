import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import LayersIcon from "@material-ui/icons/Layers";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
// import { QueryContext } from "./QueryContext";
import CloseIcon from "@material-ui/icons/Close";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";

import { baseLayers } from "../../common/constants";
import { NavItem } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";

// import FormControl from "@material-ui/core/FormControl";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Radio from "@material-ui/core/FormLabel";
// import RadioGroup from "@material-ui/core/FormLabel";

const theme = createMuiTheme({});

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formGroup: {
    paddingLeft: 15,
    textAlign: "left",
    fontSize: theme.typography.pxToRem(8),
  },
  formLabel: {
    padding: 0,
    margin: 0,
    fontSize: theme.typography.pxToRem(8),
  },
  input: {
    display: "none",
  },
  colselectColumn1: {
    paddingLeft: 5,
    textAlign: "left",
  },
  colselectColumn2: {
    paddingLeft: 15,
    textAlign: "left",
    fontSize: theme.typography.pxToRem(8),
  },
  customWidth: {
    "& div": {
      width: "21vw",
    },
    height: "70vh",
  },
  filterIcon: {
    background: theme.palette.background.paper,
    backgroundColor: "#ffffff",
  },
  selectedFeaturesLabel: {
    paddingLeft: 15,
    fontWeight: 500,
  },
}));

const LayerControls = (props) => {
  const { baseMap, setBaseMap, showRoads, setShowRoads } = props;

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    if (e.currentTarget.localName !== "ul") {
      const menu = document.getElementById("simple-menu").children[2];
      const menuBoundary = {
        left: menu.offsetLeft,
        top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
        right: menu.offsetLeft + menu.offsetHeight,
        bottom: menu.offsetTop + menu.offsetHeight,
      };
      if (
        e.clientX >= menuBoundary.left &&
        e.clientX <= menuBoundary.right &&
        e.clientY <= menuBoundary.bottom &&
        e.clientY >= menuBoundary.top
      ) {
        return;
      }
    }

    setAnchorEl(null);
  };

  theme.props = {
    MuiList: {
      onMouseLeave: (e) => {
        handleClose(e);
      },
    },
  };

  const handleChangeBasemap = (event) => {
    setBaseMap(event.target.value);
  };

  const handleChangeShowRoads = (event) => {
    setShowRoads(event.target.checked);
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClose}
      > */}
      <div>
        <IconButton
          color="primary"
          aria-label="filter"
          component="span"
          onMouseEnter={handleClick}
          onMouseLeave={handleClose}
        >
          <LayersIcon
            color="action"
            fontSize="large"
            className={classes.filterIcon}
          />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.customWidth}
        >
          <FormGroup className={classes.formGroup}>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.formLabel}>
                Basemaps
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="controlled-radio-buttons-group"
                value={baseMap}
                onChange={handleChangeBasemap}
                className={classes.formLabel}
              >
                {baseLayers.map((item, index) => (
                  <FormControlLabel
                    // value="mapbox://styles/mapbox/streets-v11"
                    value={"mapbox://styles/" + item.id}
                    control={<Radio size="small" />}
                    label={item.name}
                    key={`basemap-option-${index}`}
                    className={classes.formLabel}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </FormGroup>

          <FormGroup className={classes.formGroup}>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.formLabel}>
                Data
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showRoads}
                      onChange={handleChangeShowRoads}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={"Roads"}
                  key={`roads-layer-data`}
                  className={classes.formLabel}
                />
              </FormGroup>
            </FormControl>
          </FormGroup>
        </Menu>
      </div>
      {/* </ClickAwayListener> */}
    </ThemeProvider>
  );
};

LayerControls.propTypes = {
  style: PropTypes.object,
};

export default LayerControls;
