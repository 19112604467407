// TrkTop100ODSelLnk.js;
import React, { useState, useContext, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";

import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import DeckGL from "@deck.gl/react";
import { GeoJsonLayer, IconLayer } from "@deck.gl/layers";
import {
  StaticMap,
  MapContext,
  NavigationControl,
  Popup,
  ScaleControl,
  WebMercatorViewport,
} from "react-map-gl";
import { MAPBOX_ACCESSTOKEN, MAPBOX_ATTRIBUTION } from "../common/constants";

import { RhinoQueryContext } from "./RhinoQueryContext";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import MaximizeTwoToneIcon from "@mui/icons-material/MaximizeTwoTone";
import MinimizeTwoToneIcon from "@mui/icons-material/MinimizeTwoTone";
import ExpandTwoToneIcon from "@mui/icons-material/ExpandTwoTone";

import Grid from "@mui/material/Grid";
// fetchODByTrank;
import { fetchODByTrank } from "../data-loaders/DataLoaders";

import CircularProgress from "@mui/material/CircularProgress";

import { scaleThreshold } from "d3-scale";

import LayerControls from "./contols/LayerControlsOD";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import Divider from "@mui/material/Divider";

function PaperComponent(props) {
  return (
    <Draggable
    //   handle="#draggable-dialog-title"
    //   cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function TrkTop100ODSelLnkMap(props) {
  const [query, dispatch] = useContext(RhinoQueryContext);
  const [open, setOpen] = React.useState(
    query.TrkTop100ODSelLnkMap.odVisibility
  );
  const [isLoading, setIsLoading] = React.useState(false);
  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };
  const [maxValues, setMaxValues] = useState({
    origins: 0,
    destinations: 0,
    colorDomain: [1, 2, 3, 4, 5],
  });

  useMemo(() => {
    if (query.TrkTop100ODSelLnkMap.odVisibility) {
      setIsLoading(true);

      let year = 2023;
      let trank = query.TrkTop100ODSelLnkMap.trank;
      fetchODByTrank(year, trank)
        .then((res) => {
          // console.log("succesfull stuff to be done here", res.data);
          // setTrkTopOdData(res.data);

          // let maxValueOrigins = getTopGeojsonN(res.data, "origins", 1);
          // let minValue = getBottomGeojsonN(res.data, "origins", 1);
          let sumValue = res.data
            .map((bill) => bill.properties.origins)
            .reduce((acc, bill) => bill + acc);
          // console.log(res);
          // console.log("maxValue-check", maxValue, minValue, sumValue);

          let cloneResData = res.data.slice(0);
          cloneResData.map(
            (obj) =>
              (obj.properties.origins_dist =
                (obj.properties.origins / sumValue) * 100)
            // (obj.properties.origins_dist = obj.properties.origins)
          );
          //   let maxValueDist = getTopGeojsonN(cloneResData, "origins_dist", 1);
          //   let minValueDist = getBottomGeojsonN(cloneResData, "origins_dist", 1);
          //   let sumValueDist = cloneResData
          //     .map((bill) => bill.properties.origins_dist)
          //     .reduce((acc, bill) => bill + acc);
          //   console.log(
          //     "cloneResData-check",
          //     cloneResData,
          //     maxValueDist,
          //     minValueDist,
          //     sumValueDist
          //   );

          // let maxValueDestinations = getTopGeojsonN(
          //   res.data,
          //   "destinations",
          //   1
          // );
          // minValue = getBottomGeojsonN(res.data, "destinations", 1);
          sumValue = res.data
            .map((bill) => bill.properties.destinations)
            .reduce((acc, bill) => bill + acc);
          // console.log(res);
          //   console.log("maxValue-check", maxValue, minValue, sumValue);
          cloneResData.map(
            (obj) =>
              (obj.properties.destinations_dist =
                (obj.properties.destinations / sumValue) * 100)
            // (obj.properties.origins_dist = obj.properties.origins)
          );

          let maxValueOrigins = getTopGeojsonN(cloneResData, "origins_dist", 1);
          let maxValueDestinations = getTopGeojsonN(
            cloneResData,
            "destinations_dist",
            1
          );
          // console.log("maxValue-check", maxValueOrigins, maxValueDestinations);
          let maxValue = Math.max(maxValueOrigins, maxValueDestinations);
          setMaxValues({
            origins: maxValueOrigins,
            destinations: maxValueDestinations,
            colorDomain: [
              maxValue * 0.2,
              maxValue * 0.4,
              maxValue * 0.6,
              maxValue * 0.8,
              maxValue,
            ],
            // colorDomain: [1, 2, 3, 4, 5],
          });

          setTrkTopOdData(cloneResData);

          setIsLoading(false);
        })
        .catch((err) => console.error(err)); // promise

      setOpen(query.TrkTop100ODSelLnkMap.odVisibility);
    }
  }, [query.TrkTop100ODSelLnkMap.trank]);

  const handleClose = () => {
    setOpen(false);
    dispatch({
      type: "setTrkTop100ODSelLnkMap",
      selection: {
        ...query.TrkTop100ODSelLnkMap,
        odVisibility: false,
      },
    });
    updateSelectedRows([]);
  };

  const viewport = {
    // height: "500px",
    //height: "65vh",
    // height: "65%",
    // width: "100%",
    //initViewport
    width: "100%",
    height: "100%",
    // height: "500px",
  };
  const [homeMapBounds, setHomeMapBounds] = useState({
    longitude: -99.458333,
    latitude: 31.243333,
    zoom: 4.85,
  });
  const [baseMap, setBaseMap] = useState("mapbox://styles/mapbox/streets-v11");

  const bull = (
    <Box
      component="span"
      sx={{
        display: "inline-block",
        mx: "2px",
        transform: "scale(0.8)",
      }}
    >
      •
    </Box>
  );

  const [trkTopOdData, setTrkTopOdData] = useState(null);

  // const colorScaleFunction = scaleThreshold()
  //   .domain([1, 2, 3, 4, 5])
  //   .range([
  //     [70, 1, 155, 50],
  //     [0, 126, 254, 150],
  //     [0, 187, 0, 175],
  //     [254, 246, 1, 200],
  //     [221, 0, 0, 225],
  //   ]);
  function colorScaleFunction(d, colorDomain) {
    const colorScaleFunctionValue = scaleThreshold()
      // .domain([1, 2, 3, 4, 5])
      .domain(colorDomain)
      .range([
        [70, 1, 155, 50],
        [0, 126, 254, 150],
        [0, 187, 0, 175],
        [254, 246, 1, 200],
        [221, 0, 0, 225],
      ]);

    return colorScaleFunctionValue(d);
  }
  // console.log(
  //   "color-check",
  //   colorScaleFunction(
  //     Math.max(maxValues.origins, maxValues.destinations) * 0.9,
  //     maxValues.colorDomain
  //   ),
  // colorScaleFunction(
  //   Math.max(maxValues.origins, maxValues.destinations) * 0.7,
  //   maxValues.colorDomain
  // )
  // );
  function getTopGeojsonN(arr, prop, n) {
    // clone before sorting, to preserve the original array
    var clone = arr.slice(0);

    let selectedRow = clone
      .sort((a, b) => b.properties[prop] - a.properties[prop])
      .slice(0, n || 1);

    return selectedRow[0].properties[prop];
    // return selectedRow[0];
  }

  function getBottomGeojsonN(arr, prop, n) {
    // clone before sorting, to preserve the original array
    var clone = arr.slice(0);

    let selectedRow = clone
      .sort((a, b) => a.properties[prop] - b.properties[prop])
      .slice(0, n || 1);

    return selectedRow[0].properties[prop];
  }

  //   const [mapSize, setMapSize] = useState({ height: "60vh", width: "35vw" });
  const [mapSize, setMapSize] = useState({
    height: "90vh",
    width: "53vw",
  });

  const handleSizeChangeMax = () => {
    // setMapSize({ height: "85vh", width: "70vw" });
    setMapSize({
      height: "95vh",
      width: "95vw",
    });
    setControlledPosition({
      x: 41,
      y: 122,
    });
  };
  const handleSizeChangeMin = () => {
    // setMapSize({ height: "60vh", width: "35vw" });
    setMapSize({
      height: "90vh",
      width: "50vw",
    });
    setControlledPosition({
      x: 41,
      y: 122,
    });
  };

  const NAV_CONTROL_STYLE = {
    // position: "absolute",
    // top: 5,
    // left: 5,
    position: "absolute",
    // position: "relative",
    top: "1%",
    left: "10px",
    width: 30,
  };
  const ScaleControl_STYLE = {
    // position: "absolute",
    // top: 350,
    // left: 5,
    position: "absolute",
    // position: "relative",
    top: "87%",
    left: "10px",
  };
  const layers_CONTROL_STYLE = {
    // position: "absolute",
    // top: 90,
    // left: 5,
    position: "absolute",
    // position: "relative",
    top: "17.5%",
    // left: "0.5%",
    width: "100%",
  };
  const legend_CONTROL_STYLE = {
    // position: "absolute",
    // top: 90,
    // left: 5,
    position: "absolute",
    // position: "relative",
    top: "80%",
    left: "25%",
    minWidth: "40%",
    background: "white",
  };
  const commonStyles = {
    bgcolor: "background.paper",
    m: 0,
    // borderColor: "text.primary",
    width: "2.5rem",
    height: "2.5rem",
  };

  const [odCategory, setOdCategory] = useState("Origins");

  function deckGlTooltip(layer, object) {
    let toolTipStyle = {
      backgroundColor: "#e1e7ed",
      color: "#000000",
      fontSize: "1.0em",
    };

    return {
      html: `<div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Origns</h5>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>SAM Zone:</strong> ${
        object.properties.samtaz
      }</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Percent of Origins:</strong> ${object.properties.origins_dist.toFixed(
        2
      )}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Percent of Destinations:</strong> ${object.properties.destinations_dist.toFixed(
        2
      )}</p>
    </div>`,
      style: toolTipStyle,
    };
  }

  const [show3dMap, setShow3dMap] = useState(true);

  const isRoadSelected = (selectedRows, feature) => {
    return selectedRows.includes(feature.top100id);
  };

  const updateSelectedRows = (srows) => {
    dispatch({
      type: "setHighlightQuery",
      query: isRoadSelected.bind(null, srows),
    });
  };

  const [showODlayer, setShowODlayer] = useState(true);

  const [controlledPosition, setControlledPosition] = useState({
    x: -400,
    y: 200,
  });

  const onControlledDrag = (e, position) => {
    const { x, y } = position;
    // console.log("position-check", position);
    // this.setState({ controlledPosition: { x, y } });
    setControlledPosition({
      x,
      y,
    });
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{
        popper: className,
      }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      zIndex: 1000000,
    },
  }));

  function rgbToHex(rgb) {
    let r = rgb[0];
    let g = rgb[1];
    let b = rgb[2];
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  return (
    <div>
      {query.TrkTop100ODSelLnkMap.odVisibility && (
        <Draggable
          handle="strong"
          bounds="body"
          defaultPosition={{
            x: 25,
            y: 50,
          }}
          position={controlledPosition}
          onDrag={onControlledDrag}
        >
          {/* <div className="box no-cursor"> */}
          <Card
            sx={{
              //   height: "60vh",
              //   width: "35vw",
              height: mapSize.height,
              width: mapSize.width,
              position: "absolute",
              bottom: "100px",
              right: "100px",
              zIndex: 5000,
            }}
            className="box no-cursor"
          >
            <CardActions className="cursor">
              {/* <Box cursor="move" display="flex" alignItems="center">
                <Box flexGrow={1}>
                  <Typography>
                    <strong>
                      <div>Drag here</div>
                    </strong>
                  </Typography>
                </Box>
                <Box>
                  <IconButton onClick={handleClose} fontSize="small">
                    <CloseIcon sx={{ fontSize: 15, mb: 1, mt: 0 }} />
                  </IconButton>
                </Box>
              </Box> */}
              <Grid container justify="center">
                <Grid item xs={11}>
                  <Typography>
                    <strong>
                      <div>{query.TrkTop100ODSelLnkMap.title}</div>
                    </strong>
                  </Typography>
                  <Typography>
                    Based on 2019 INRIX data, vehicle weight class 3 (heavy
                    trucks)
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={1}
                  align="right"
                  justify="center"
                  sx={{
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {mapSize.height === "90vh" && (
                    <IconButton onClick={handleSizeChangeMax} fontSize="small">
                      <LightTooltip title="Add">
                        <ExpandTwoToneIcon
                          sx={{
                            fontSize: 15,
                            mb: 1,
                            mt: 0,
                          }}
                        />
                      </LightTooltip>
                    </IconButton>
                  )}

                  {mapSize.height === "95vh" && (
                    <IconButton onClick={handleSizeChangeMin} fontSize="small">
                      <MinimizeTwoToneIcon
                        sx={{
                          fontSize: 15,
                          mb: 1,
                          mt: 0,
                        }}
                      />
                    </IconButton>
                  )}

                  <IconButton onClick={handleClose} fontSize="small">
                    <CloseIcon
                      sx={{
                        fontSize: 15,
                        mb: 1,
                        mt: 0,
                        mr: 0,
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </CardActions>

            <div
              style={{
                position: "relative",
                height: "100%",
                width: "100%",
              }}
              onContextMenu={(e) => e.preventDefault()}
            >
              <DeckGL
                {...viewport}
                initialViewState={{
                  longitude: homeMapBounds.longitude,
                  latitude: homeMapBounds.latitude,
                  zoom: homeMapBounds.zoom,
                  pitch: 50,
                  bearing: 0,
                  transitionDuration: 2000,
                }}
                controller={true}
                ContextProvider={MapContext.Provider}
                //   getTooltip={
                //     ({ layer, object }) => object && deckGlTooltip(layer, object)
                //   }
                //   onViewStateChange={({ viewState }) => {
                //     // console.log("viewState-change", viewState);
                //     // setZoomLevel(viewState.zoom);

                //     let zoomScaleTemp = 1;
                //     if (viewState.zoom > 4.85)
                //       zoomScaleTemp = 4.85 / (viewState.zoom * viewState.zoom);
                //     setZoomLevel({
                //       zoomLevel: viewState.zoom,
                //       zoomScale: zoomScaleTemp,
                //       // pitch: viewState.pitch,
                //     });

                //     // buildGeoJsonLayer();
                //   }}
                getTooltip={({ layer, object }) =>
                  object && deckGlTooltip(layer, object)
                }
              >
                <StaticMap
                  reuseMaps
                  // mapStyle={"mapbox://styles/mapbox/streets-v11"}
                  mapStyle={baseMap}
                  mapboxApiAccessToken={MAPBOX_ACCESSTOKEN}
                  preventStyleDiffing={true}
                />
                {isLoading && (
                  <CircularProgress
                    style={{
                      marginLeft: "50%",
                      marginTop: "15%",
                    }}
                  />
                )}

                <GeoJsonLayer
                  id="trkTopOdData"
                  data={trkTopOdData}
                  visible={showODlayer}
                  filled={true}
                  //   opacity={0.5}
                  pickable={true}
                  pickingRadius={5}
                  stroked={true}
                  extruded={show3dMap}
                  //   pointType={"circle"}
                  //   lineWidthScale={2}
                  lineWidthMinPixels={0.5}
                  getPointRadius={0.5}
                  getLineWidth={1}
                  //   getElevation={500}
                  getElevation={(d) => {
                    if (odCategory === "Origins")
                      return d.properties.origins_dist * 25000;
                    if (odCategory === "Destinations")
                      return d.properties.destinations_dist * 25000;
                  }}
                  // onClick={(d, event) => {
                  //   setActivePopup(true);
                  //   setSelectedPoint(d);
                  // }}
                  // onHover={(d, event) => {
                  //   if (d.object !== undefined && statusGuide[0] === "TX100")
                  //     setInfo(tx100Info(d.object.properties));
                  //   if (d.object !== undefined && statusGuide[0] !== "TX100")
                  //     setInfo(rhinoInfo(d.object.properties));
                  // }}
                  autoHighlight={true}
                  highlightColor={[160, 160, 180, 180]}
                  getLineColor={[46, 49, 49, 180]}
                  //   getFillColor={"#90ee90"
                  //     .match(/[0-9a-f]{2}/g)
                  //     .map((x) => parseInt(x, 16))}
                  getFillColor={(d) => {
                    if (odCategory === "Origins")
                      return colorScaleFunction(
                        d.properties.origins_dist,
                        maxValues.colorDomain
                      );

                    if (odCategory === "Destinations")
                      return colorScaleFunction(
                        d.properties.destinations_dist,
                        maxValues.colorDomain
                      );
                  }}
                  //   colorFormat={"RGBA"}
                  fp64={true}
                  gpuAggregation={true}
                  updateTriggers={{
                    getElevation: [odCategory],
                    getFillColor: [odCategory],
                  }}
                />

                <NavigationControl style={NAV_CONTROL_STYLE} />
                <ScaleControl style={ScaleControl_STYLE} />
                <div style={layers_CONTROL_STYLE}>
                  <LayerControls
                    baseMap={baseMap}
                    setBaseMap={setBaseMap}
                    odCategory={odCategory}
                    setOdCategory={setOdCategory}
                    show3dMap={show3dMap}
                    setShow3dMap={setShow3dMap}
                    showODlayer={showODlayer}
                    setShowODlayer={setShowODlayer}
                  />
                </div>

                {/* <div style={legend_CONTROL_STYLE}> */}
                <Box
                  sx={{
                    width: 425,
                    // height: 300,
                    // backgroundColor: "primary.dark",
                    // "&:hover": {
                    //   backgroundColor: "primary.main",
                    //   opacity: [0.9, 0.8, 0.7],
                    // },
                    position: "absolute",
                    // position: "relative",
                    // top: "80%",
                    // bottom: "10%",
                    bottom: "70px",
                    left: "25%",
                    // minWidth: "40%",
                    background: "white",
                    // width: "50%",
                  }}
                >
                  <Typography component="div" sx={{ ml: 0.5, mr: 2, pr: 2 }}>
                    <Box fontWeight="fontWeightBold">Legend:</Box>
                  </Typography>
                  <Grid container spacing={0} sx={{ ml: 1 }}>
                    <Grid item xs={1}>
                      <Typography>0.00</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ ml: 0.25, mr: 0.25 }}
                    >
                      <Divider
                        orientation="horizonal"
                        style={{
                          height: "5px",
                          width: "100%",
                          background: rgbToHex(
                            colorScaleFunction(
                              Math.max(
                                maxValues.origins,
                                maxValues.destinations
                              ) * 0.1,
                              maxValues.colorDomain
                            )
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>
                        {(
                          Math.max(maxValues.origins, maxValues.destinations) *
                          0.2
                        ).toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ ml: 0.25, mr: 0.25 }}
                    >
                      <Divider
                        orientation="horizonal"
                        style={{
                          height: "5px",
                          width: "100%",
                          background: rgbToHex(
                            colorScaleFunction(
                              Math.max(
                                maxValues.origins,
                                maxValues.destinations
                              ) * 0.3,
                              maxValues.colorDomain
                            )
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>
                        {(
                          Math.max(maxValues.origins, maxValues.destinations) *
                          0.4
                        ).toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      j
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ ml: 0.25, mr: 0.25 }}
                    >
                      <Divider
                        orientation="horizonal"
                        style={{
                          height: "5px",
                          width: "100%",
                          background: rgbToHex(
                            colorScaleFunction(
                              Math.max(
                                maxValues.origins,
                                maxValues.destinations
                              ) * 0.5,
                              maxValues.colorDomain
                            )
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>
                        {(
                          Math.max(maxValues.origins, maxValues.destinations) *
                          0.6
                        ).toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      j
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ ml: 0.25, mr: 0.25 }}
                    >
                      <Divider
                        orientation="horizonal"
                        style={{
                          height: "5px",
                          width: "100%",
                          background: rgbToHex(
                            colorScaleFunction(
                              Math.max(
                                maxValues.origins,
                                maxValues.destinations
                              ) * 0.7,
                              maxValues.colorDomain
                            )
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>
                        {(
                          Math.max(maxValues.origins, maxValues.destinations) *
                          0.8
                        ).toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ ml: 0.25, mr: 0.25 }}
                    >
                      <Divider
                        orientation="horizonal"
                        style={{
                          height: "5px",
                          width: "100%",
                          background: rgbToHex(
                            colorScaleFunction(
                              Math.max(
                                maxValues.origins,
                                maxValues.destinations
                              ) * 0.9,
                              maxValues.colorDomain
                            )
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>
                        {Math.max(
                          maxValues.origins,
                          maxValues.destinations
                        ).toFixed(2)}
                        +
                      </Typography>
                    </Grid>
                    {/* </Grid> */}
                  </Grid>
                </Box>
                {/* </div> */}
              </DeckGL>
            </div>
          </Card>
          {/* </div> */}
        </Draggable>
      )}
    </div>
  );
}
