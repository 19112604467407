import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import LayersIcon from "@material-ui/icons/Layers";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
// import { QueryContext } from "./QueryContext";
import CloseIcon from "@material-ui/icons/Close";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";

import { baseLayers } from "../../common/constants";
import { NavItem } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";

import Paper from "@mui/material/Paper";

// import FormControl from "@material-ui/core/FormControl";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Radio from "@material-ui/core/FormLabel";
// import RadioGroup from "@material-ui/core/FormLabel";

import MenuList from "@mui/material/MenuList";
// import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
// import Typography from "@mui/material/Typography";
import ContentCut from "@mui/icons-material/ContentCut";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ContentPaste from "@mui/icons-material/ContentPaste";
import Cloud from "@mui/icons-material/Cloud";
import Divider from "@mui/material/Divider";

const theme = createMuiTheme({});

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
  formGroup: {
    paddingLeft: 15,
    textAlign: "left",
    fontSize: theme.typography.pxToRem(6),
  },
  formLabel: {
    padding: 0,
    margin: 0,
    fontSize: theme.typography.pxToRem(6),
  },
  input: {
    display: "none",
  },
  colselectColumn1: {
    paddingLeft: 5,
    textAlign: "left",
  },
  colselectColumn2: {
    paddingLeft: 15,
    textAlign: "left",
    fontSize: theme.typography.pxToRem(8),
  },
  customWidth: {
    "& div": {
      width: "21vw",
    },
    height: "70vh",
  },
  filterIcon: {
    background: theme.palette.background.paper,
    backgroundColor: "#ffffff",
  },
  selectedFeaturesLabel: {
    paddingLeft: 15,
    fontWeight: 500,
  },
}));

const LayerControls = (props) => {
  const {
    baseMap,
    setBaseMap,
    odCategory,
    setOdCategory,
    show3dMap,
    setShow3dMap,
    showODlayer,
    setShowODlayer,
  } = props;

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    if (e.currentTarget.localName !== "ul") {
      const menu = document.getElementById("simple-menu").children[2];
      const menuBoundary = {
        left: menu.offsetLeft,
        top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
        right: menu.offsetLeft + menu.offsetHeight,
        bottom: menu.offsetTop + menu.offsetHeight,
      };
      if (
        e.clientX >= menuBoundary.left &&
        e.clientX <= menuBoundary.right &&
        e.clientY <= menuBoundary.bottom &&
        e.clientY >= menuBoundary.top
      ) {
        return;
      }
    }

    setAnchorEl(null);
  };

  theme.props = {
    MuiList: {
      onMouseLeave: (e) => {
        handleClose(e);
      },
    },
  };

  const handleChangeBasemap = (event) => {
    setBaseMap(event.target.value);
  };

  const handleChangeODcategory = (event) => {
    setOdCategory(event.target.value);
  };

  const handleChangeShow3dMap = (event) => {
    setShow3dMap(!show3dMap);
  };

  const handleChangeShowODlayer = (event) => {
    setShowODlayer(!showODlayer);
  };

  return (
    <Paper sx={{ width: "9vw", maxWidth: "9vw" }}>
      <MenuList>
        <FormGroup className={classes.formGroup}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.formLabel}>
              Basemaps
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group"
              value={baseMap}
              onChange={handleChangeBasemap}
              className={classes.formLabel}
            >
              {baseLayers.map((item, index) => (
                <FormControlLabel
                  // value="mapbox://styles/mapbox/streets-v11"
                  value={"mapbox://styles/" + item.id}
                  control={<Radio size="small" />}
                  label={item.name}
                  key={`basemap-option-${index}`}
                  className={classes.formLabel}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </FormGroup>
        <Divider />
        <FormGroup className={classes.formGroup}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.formLabel}>
              Data
            </FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showODlayer}
                  onChange={handleChangeShowODlayer}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={"Show O-D Layer"}
              key={`od-show-od-layer`}
              className={classes.formLabel}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={show3dMap}
                  onChange={handleChangeShow3dMap}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={"Show 3-D Map"}
              key={`od-show-3d-map`}
              className={classes.formLabel}
            />

            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group"
              value={odCategory}
              onChange={handleChangeODcategory}
              className={classes.formLabel}
            >
              <FormControlLabel
                // value="mapbox://styles/mapbox/streets-v11"
                value={"Origins"}
                control={<Radio size="small" />}
                label={"Origins"}
                key={"od-option-Origins"}
                className={classes.formLabel}
              />
              <FormControlLabel
                // value="mapbox://styles/mapbox/streets-v11"
                value={"Destinations"}
                control={<Radio size="small" />}
                label={"Destinations"}
                key={"od-option-Destinations"}
                className={classes.formLabel}
              />
            </RadioGroup>
          </FormControl>
        </FormGroup>
        {/* 
        <MenuItem>
          <ListItemIcon>
            <Cloud fontSize="small" />
          </ListItemIcon>
          <ListItemText>Web Clipboard</ListItemText>
        </MenuItem> */}
      </MenuList>
    </Paper>
  );
};

LayerControls.propTypes = {
  style: PropTypes.object,
};

export default LayerControls;
